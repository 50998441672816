import React,{Component} from 'react';
import {Container , Row , Col} from 'reactstrap';
import {connect} from 'react-redux';
import Aux from '../global/AuxComp';
import { RecruiterModal } from '../global/Login/recruiter';
import Login from '../global/Login';
import Header from '../global/Header';
import { Loader } from '../global/Loader';
import { Errorbar } from '../global/errorbar';
import { Successbar } from '../global/successbar';
import { Confirmbar } from '../global/confirmbar';
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      recruiterModal: true
    }
  }

  toggleLogin = e => {
    let { showLogin } = this.state
    this.setState({ showLogin: !showLogin })
  }

  toggleRecruiterModal = async() => {
    let { recruiterModal } = this.state
    this.setState({recruiterModal : !recruiterModal});
  }

  render(){
    let {user , role , loader} = this.props,
    {showLogin , recruiterModal}=this.state;
    return(
      <Container fluid={true}>
      <Errorbar />
      <Successbar />
      <Confirmbar />
      <Loader show={loader} />
       {!loader && <div style={{marginTop : "5%" }}>
                 {user && user.recruiter_id && role  && role.role_id ? <Header user={user} role={role} /> : null}
                   <Row>
                     <Col align="center">
                       <div className="mb-5">
                         <img src="/images/reculta_black.png" className="r-login__logo" alt="Reculta" />
                         <h1 className="r-heading-2">Recruiter Portal</h1>
                       </div>
                     </Col>
                   </Row>
                   <Row>
                     <Col align="center">
                       {user && !user.recruiter_id ? <RecruiterModal toggleRecruiterModal={this.toggleRecruiterModal} recruiterModal={recruiterModal} />  : null}
                       {!user ? <Login isLogin={showLogin} toggleLoginModal={this.toggleLogin} toggleRecruiterModal={this.toggleRecruiterModal} /> : null}
                     </Col>
                   </Row>
                 </div>}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

export default Home = connect(mapStateToProps)(Home) ;
