import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { InputField } from '../Forms';
import Button from '../Button';
import store from '../../../store';
import { setError, setUser, setRole } from '../../../actions/action-creators';
import { getErrorMessage, getQueryParams, checkPass } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import { CONSTANTS } from '../../../constants';
import Aux from '../AuxComp';
import { Loader } from '../Loader';

export class UserLogin extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    return {
      username : "",
      forgotPasswordValue : null,
      forgotPasswordMessage : null,
      showNewPasswordBox : null,
      setNewPassword : "",
      confirmNewPassword : "",
      passwordToken : null,
      wrongPasswordMessage : null,
      success_msg : null,
      loading : false
    }
  }
  componentDidMount(){
    if(window.location.href.includes("?")){
      let getParams = getQueryParams(window.location.href.split("?")[1]);
      if(getParams.token && getParams.page)
      {
        this.setState({
          username : getParams.email,
          showNewPasswordBox : 1,
          passwordToken : getParams.token
        })
      }
    }
  }
  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    })
  }

  handleForgotPassword = async (e)=>{
    if(e) e.preventDefault();
    let {username} = this.state;
    this.setState({loading : true})
    this.setState({password:""});
    try{
      let params = {
        email : username,
        user_type : "RECRUITER"
      }
      let forgotPasswordMessage = await Auth.User.forgotPassword(params);
      this.setState({forgotPasswordMessage : forgotPasswordMessage.data.message,username:""})
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
    this.setState({loading : false})
  }
  handleRetievePassword = async(e)=>{
    if(e) e.preventDefault();
    let{setNewPassword, confirmNewPassword , passwordToken , username} = this.state;
    if(checkPass(setNewPassword) && checkPass(confirmNewPassword))
    {
      if((setNewPassword === confirmNewPassword) && setNewPassword!= null && confirmNewPassword!=null){
        this.setState({loading : true})
        let params = {
          "email": username,
          "user_type" : "RECRUITER",
          "token": passwordToken,
          "new_password": setNewPassword,
          "confirm_password": confirmNewPassword
        }
        try{
          await Auth.User.retrievePassword(params);
          await this.setState({...this.getInitialState(), success_msg : 'Password changed successfully'});
        }
        catch(e){
          console.log(e);
          store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        }
        this.setState({loading : false})
      }else{
        await this.setState({wrongPasswordMessage : "Password dosen't match"});
      }
    }
    else{
      await this.setState({wrongPasswordMessage : "Password is weak ! Should be of 8 characters and must include alphabets, number and special characters"})
    }
  }

  onLogin = async (e) => {
    e.preventDefault();
    let { email, password } = e.target;
    this.setState({loading : true})
    try {
      await Auth.User.login({ username: email.value, password: password.value, ...CONSTANTS.USER_TYPE })
      let resp = await Auth.User.getUser();
      let userInfo = resp.data.data
      store.dispatch(setUser({ ...userInfo }))
      if (userInfo.roles)
        store.dispatch(setRole(userInfo.roles[0]))
      if (!userInfo.recruiter_id)
        this.props.toggleRecruiterModal()
      this.props.toggleLoginModal()
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    this.setState({loading : false})
  }

  render() {
    let { updateView } = this.props
    let { username, setNewPassword, confirmNewPassword } = this.state
    return (
    <Aux>
      <Loader show={this.state.loading} />
    {
    this.state.forgotPasswordValue!==1? 
    ( this.state.showNewPasswordBox!==1? 
        <form onSubmit={this.onLogin}>
          {this.state.forgotPasswordMessage && <span className="r-text--green-1">{this.state.forgotPasswordMessage}</span>}
          {this.state.success_msg && <span className="r-text--green-1">{'*'}{this.state.success_msg}</span>}
          <Row className="mb-3">
            <Col xs={12}>
              <InputField className="w-100" type="email" name="email" placeholder="Enter email" required={true} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <InputField className="w-100" type="password" name="password" placeholder="Enter password" required={true} />
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="text-left">
              <Button className="r-btn--no-radius" color="blue-3">Login</Button>
              <Button type="button" onClick={updateView}>Sign Up</Button>
            </Col>
            <Col xs={6} className="text-right">
              <Button onClick={()=>this.setState({forgotPasswordValue:1})} className="r-subheading-3 mt-2" color="link">Forgot Password?</Button>
            </Col>
          </Row>
        </form> 
        :
        <form noValidate onSubmit={this.handleRetievePassword}>
          <div className="mt-4">
            <InputField className="w-100" value={setNewPassword} name="setNewPassword" type="password" onChange={this.handleChange} placeholder="New Password" />
          </div>
          <div className="mt-4">
            <InputField className="w-100" value={confirmNewPassword} name="confirmNewPassword" type="password" onChange={this.handleChange} placeholder={"Confirm Password"} />
          </div>
          <div className="mt-3 r-text--grey">
            {this.state.wrongPasswordMessage && <span className="r-text--red-1">{this.state.wrongPasswordMessage}</span>}
          </div>
          <div className="d-flex mt-5">
            <Button className="r-btn--no-radius" color="blue-3" type="submit"> Confirm </Button>
            <Button type="button" className="ml-4 r-btn--no-radius" onClick={()=>this.setState({showNewPasswordBox: null})} >Cancel</Button>
          </div>
        </form>
      )
      :
      <form noValidate onSubmit={this.handleForgotPassword}>
        <div className="mt-4">
          <InputField name="username" value={username} onChange={this.handleChange} className="w-100" type="text" placeholder="myname@harvard.edu" />
        </div>
        <div className="mt-4">
          {this.state.forgotPasswordMessage && <span className="r-text--green-1">{this.state.forgotPasswordMessage}</span>}
        </div>
        <div className="d-flex mt-5">
          <Button className="r-btn--no-radius" color="blue-3" type="submit">Forgot Password</Button>
          <Button type="button" className="ml-4 r-btn--no-radius" onClick={()=>this.setState({forgotPasswordValue: null,forgotPasswordMessage:null})} >Log In</Button>
        </div>
      </form>
    }
    </Aux>

    )
  }
}
