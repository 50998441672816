import React,{Component} from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Auth } from '../../../actions/Auth';
import Search from '../search';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import SelectedColleges from './selected-colleges';
import Link from 'react-router-dom/Link';


class Branding extends Component {

    constructor(props){
        super(props)
        this.state = {
            localStorageData : [],
            collegeData : [],
            isCollegeSelected:[]
        }
    }


    componentDidMount(){
        this.init()
    }

    init=()=>{
        this.getColleges()
        this.getLocalCollegeData()
    }

    getColleges= async()=>{
        try {
            let apiResp =  await  Auth.Recruiter.getColleges()
            this.setState({collegeData:apiResp.data})
        } catch (e) {
            console.log(e)
        }
    }

    getLocalCollegeData=()=>{
        let localStorageName  = 'recruiterPrefrence';
        let localStorageCollegeData = JSON.parse(localStorage.getItem(localStorageName) || '[]');
        this.setState({isCollegeSelected:localStorageCollegeData})
    }

    render() {
        let {isCollegeSelected, collegeData} =  this.state
        let selectedColleges =  collegeData.filter(college=> isCollegeSelected.includes(college.campus_id))
    return (

        <div className="branding">
            <div className="container">
                <div className="colleges">
                    <div className="top-navigation border-bottom mt-3">
                        <Row >
                            <Col xs={6}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Search</a></li>
                                    <li className="breadcrumb-item " aria-current="page">List of College</li>
                                </ol>
                            </nav>
                            </Col>
                            <Col xs={6}>
                                <ul className="nav justify-content-end">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#">Search</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" href="#">Brand</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link disabled" href="#">Hire</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link " href="#">|</a>
                                    </li>
                                    <li className="nav-item">
                                    <Link to={`/branding`}>
                                        <button className="r-btn">Start Branding</button>
                                    </Link>

                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="search-panel my-5">
                    <h1 className="r-subheading-1 my-5 r-text--blue-3">Search</h1>
                    <Search/>
                </div>
            </div>

            <div className="container">
                {
                    selectedColleges.length?
                    <SelectedColleges selectedColleges={selectedColleges}/>
                    :
                    null
                }
            </div>
        </div>

    )
  }

}

const mapStateToProps = state => ({
    ...state
  })
  const mapDispatchToProps = dispatch => ({
  })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Branding));
