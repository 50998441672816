import React,{PureComponent} from 'react'
import Aux from './AuxComp';
import moment from 'moment';
export class Timer extends PureComponent {
  constructor(props) {
      super(props);
      this.state = {
        deadLineCounter: moment.duration(moment().diff(moment(), "milliseconds"))
      }
    }

    componentWillMount() {
      this.interval = setInterval(this.getDeadline, 1000)
    }

    componentWillUnmount() {
      clearInterval(this.interval)
    }

    getDeadline = () => {
      let deadLineCounter = moment.duration(moment(this.props.deadline).diff(moment(), "milliseconds"))
      this.setState({ deadLineCounter })
    }

  render() {
    let {deadLineCounter} = this.state;
    return (
      <Aux>
        {`${deadLineCounter.asDays() < 0 ? 0 : Math.floor(deadLineCounter.asDays())}d : ${deadLineCounter.hours() < 0 ? 0 : deadLineCounter.hours()}h : ${deadLineCounter.minutes() < 0 ? 0 : deadLineCounter.minutes()}m : ${deadLineCounter.seconds() < 0 ? 0 : deadLineCounter.seconds()}s`}
      </Aux>
    )
  }
}
