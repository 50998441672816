import { Auth } from "./Auth";

export const getErrorMessage = e => {
  let message = null;
  if (e.response && e.response.data) {
    message = e.response.data.error || e.response.data.message
  }
  return message
}

export const getFullName = ({ first_name, middle_name, last_name }) => {
  let fullName = [];
  if (first_name) fullName.push(first_name)
  if (middle_name) fullName.push(middle_name)
  if (last_name) fullName.push(last_name)
  return fullName.join(" ")
}

export const uploadFile = async (file, container, file_id) => {
  try {
    let fileData = await Auth.User.getS3UploadURL({ container, file_name: file.name });
    let file_url = Object.keys(fileData.data)[0]
    if (!file_url) throw new Error("Unable to upload file. Please try again later.")
    await Auth.User.uploadFileToS3(fileData.data[file_url], file)
    let fileMangerData = {
      file_url,
      file_name: file.name
    }
    if (file_id && parseInt(file_id) > 0) fileMangerData.file_id = file_id
    let fileInfo = await Auth.User.addFileToManager(fileMangerData)
    return fileInfo.data[0]
  } catch (e) {
    console.log(e)
  }
}

export const replaceFile = async (file, container, existingFile) => {
  let file_id = null;
  if (existingFile) {
    file_id = existingFile.file_id
    await Auth.User.deleteFile(existingFile.id)
  }
  let newFile = await uploadFile(file, container, file_id);
  return newFile;
}

export const getFiles = async (fileIds) => {
  let filesList = [], files = {};
  let ids = fileIds.filter(id => id != 0)
  if (ids.length === 0)
    return files
  try {
    let filesResp = await Auth.User.getFilesList(ids),
      filesList = filesResp.data,
      urlsResp = {}
    if (filesList.length)
      urlsResp = await Auth.User.getFileUrlList(filesList);
    filesList.forEach(file => {
      file.url = urlsResp.data[file.file_url]
    })
    filesList.forEach(file => {
      if (files[file.file_id])
        files[file.file_id].push(file)
      else
        files[file.file_id] = [file]
    })
  } catch (e) {
    console.log(e)
  }
  return files
}

export const getCampusDetail = async (campusList) => {
  if (campusList.length === 0)
    return []
  let campusIds = campusList.reduce((list, campus) => {
    list[campus.campus_id] = campus
    return list
  }, {})
  let campusResp = await Auth.Recruiter.getColleges({ campus_id: Object.keys(campusIds).toString() })
  let campusDetails = campusResp.data.data.map(campus => {
    campus = { ...campus, ...campusIds[campus.campus_id] }
    return campus
  })
  return campusDetails
}

export const getQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export const checkPass = (pw) => {
  let regx = /^(?=(?:[^a-z]*[a-z]){1})(?=(?:[^A-Z]*[A-Z]){1})(?=(?:\D*\d){1})(?=(?:[^!@#$%^&*)(]*[!@#$%^&*)(]){1}).{8,}$/;
  return regx.test(pw);
}

export const filterSmartScoringStudents = async(filterRoundData , fields , whereObject , filterWithRange , categoryRangeFilter)=>{
    if(filterRoundData.length>0){
        let studentIdArray = filterRoundData.map(data=>{
          return data.student_id;
        })
        let getSmartScoringStudentsParams = {
          fields: fields,
          where: whereObject,
          limit : studentIdArray.length,
          order_by: [{
               field: 'confidence',
               order: 'desc'
           }]
        }
        if(filterWithRange){
          getSmartScoringStudentsParams.range = filterWithRange;
        }
        if(categoryRangeFilter){
          getSmartScoringStudentsParams.rangeFilter = categoryRangeFilter
        }
        getSmartScoringStudentsParams.where.student_id = studentIdArray;
        let getSmartScoringStudentsResp =  await Auth.SmartScoring.getSmartScoring(getSmartScoringStudentsParams);
        return getSmartScoringStudentsResp.data.data
    }
    return []
  }
