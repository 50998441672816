import React from 'react';

const Button = props => {
  let btnProps = Object.assign({}, props),
    classes = props.className ? props.className.split(" ") : [],
    color = props.color;
  delete btnProps.color
  delete btnProps.square
  delete btnProps.rounded
  delete btnProps.className
  classes.push("r-btn")
  if (color)
    classes.push(`r-btn--${color}`)
  if (props.square)
    classes.push("r-btn--no-radius")
  if (props.rounded)
    classes.push("r-btn--rounded")
  return (
    <button {...btnProps} className={classes.join(" ")}>{btnProps.children}</button>
  )
}

export default Button;
