import React, { Component } from 'react';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { CONSTANTS } from '../../../constants';

export const Select = props => {
  let _props = {...props}
  delete _props.inputref;
  return (
    <div className="r-dropdown">
      <select ref={props.inputref} className="r-dropdown__select" {..._props}>
        {props.children}
      </select>
      <i className="fi flaticon-arrow"></i>
    </div>
  )
}

export class SelectList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  toggleDropdown = () => {
    this.setState({
      show: !this.state.show
    })
  }
  componentDidMount() {
    document.addEventListener("click", this.closeDropdown)
  }
  closeDropdown = e => {
    if (e.target.className !== "r-dropdown__select")
      this.setState({
        show: false
      })
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.closeDropdown)
  }
  render() {
    let { show } = this.state;
    return (
      <div className={"r-dropdown " + this.props.className}  onClick={this.toggleDropdown}>
        <div className="r-dropdown__select">{this.props.selected}</div>
        <div className={`r-dropdown__select__list ${show ? "r-dropdown__select__list--show" : ""}`} style={this.props.style}>
          <ul className="m-0 list-unstyled">
            {this.props.children}
          </ul>
        </div>
        <i className="fi flaticon-arrow align-self-center"></i>
      </div>
    )
  }
}

export const MonthSelect = (props) => (
  <Select {...props}>
    <option value="">Month</option>
    {
      CONSTANTS.MONTHS.map((month, i) => <option key={i} value={i}>{month}</option>)
    }
  </Select>
)

export const MultiSelectList = (props) => (
  <UncontrolledDropdown className="r-multiselect">
    <DropdownToggle className={props.className} caret disabled={props.disabled?true:false}>
      {props.title}
    </DropdownToggle>
    <DropdownMenu>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
)
export const YearSelect = (props) => (
  <Select {...props}>
    <option value="">Year</option>
    {
      CONSTANTS.YEARS().map(year =>  year>=(props.min || 0) ? <option key={year} value={year}>{year}</option>:null)
    }
  </Select>
)
