import { ACTIONS } from "../constants/actions";

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SET_ROLE:
      if (action.payload === null) return {};
      return ({ ...state, ...action.payload })
    default:
      return state
  }
}