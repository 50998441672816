import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Spinner from 'reactstrap/lib/Spinner';
import Col from 'reactstrap/lib/Col';
import { InputField } from '../Forms';
import Button from '../Button';
import store from '../../../store';
import { setSuccess, setLoading, setError } from '../../../actions/action-creators';
import { Auth } from '../../../actions/Auth';
import moment from 'moment';
import { getErrorMessage } from '../../../actions';
import { CONSTANTS } from '../../../constants';

export class UserSignup extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    status: 0,
    invalidFields: [],
    isLoadingSendOtp : false,
    otpTimer: moment.duration(0, "seconds")
  }

  requiredFields = ["email", "first_name", "last_name"]

  onSignup = async e => {
    e.preventDefault()
    let { token } = e.target,
      { email, first_name, last_name } = this.state;
      store.dispatch(setLoading(true))
    try {
      await Auth.User.verifyEmailToken({ token: token.value, email })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      store.dispatch(setLoading(false))
      return
    }
    try {
      await Auth.User.signUp({email, first_name, last_name, ...CONSTANTS.USER_TYPE})
      store.dispatch(setSuccess({ showBar: true, message: "Account created successfully. Please check your mail for login credentials" }))
      this.setState({status: 0})
      this.props.updateView("login")
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      this.setState({status: 0})
    }
    store.dispatch(setLoading(false))
  }

  handleStateChange = (state, value) => {
    this.setState({ [state]: value }, () => this.updateValidator([state]))
  }

  updateValidator = (fields) => {
    let { invalidFields } = this.state;
    fields.forEach(field => {
      let fIndex = invalidFields.indexOf(field)
      if (this.state[field].trim() === "") {
        if (fIndex < 0)
          invalidFields.push(field)
      } else {
        if (fIndex > -1)
          invalidFields.splice(fIndex, 1)
      }
    })
    this.setState({ invalidFields })
  }

  countDownTimer = () => {
    let { otpTimer } = this.state
    otpTimer.subtract(1, "second")
    if (otpTimer.asSeconds() === 0) {
      clearInterval(this.timer)
      this.setState({ status: 0 })
      store.dispatch(setError({ showBar: true, message: "Verification code expired" }))
    }
    this.setState({ otpTimer })
  }

  sendOTP = async (e) => {
    e.preventDefault();
    this.updateValidator(this.requiredFields)
    let { email, invalidFields } = this.state;
    if (invalidFields.length > 0) return
    this.setState({isLoadingSendOtp : true})
    try {
      await Auth.User.generateEmailToken({ email })
      if (this.timer) clearInterval(this.timer)
      await this.setState({ status: 1, otpTimer: moment.duration(15, "minutes") })
      this.timer = setInterval(this.countDownTimer, 1000)
      store.dispatch(setSuccess({ showBar: true, message: "Verification code has been sent to your email" }))
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    this.setState({isLoadingSendOtp : false})
  }

  render() {
    let { updateView } = this.props,
      { status, first_name, last_name, email, invalidFields, otpTimer } = this.state
    if (status === 0)
      return (
        <form onSubmit={this.sendOTP}>
          <div className="login-box">
            <Row className="mb-5" form>
              <Col>
                <div className="text-center">
                  <p>Please fill your details</p>
                </div>
              </Col>
            </Row>
            <Row className="mb-3" form>
              <Col xs={6}>
                <div className="text-middle">
                  <InputField error={invalidFields.indexOf("first_name") > -1} value={first_name} onChange={({ target }) => this.handleStateChange(target.name, target.value)} className="w-100" type="text" name="first_name" placeholder="First Name" />
                  <i className="r-text--grey-4 fi r-input_field_icon flaticon-user"></i>
                </div>
              </Col>
              <Col xs={6}>
                <InputField error={invalidFields.indexOf("last_name") > -1} value={last_name} onChange={({ target }) => this.handleStateChange(target.name, target.value)} className="w-100" type="text" name="last_name" placeholder="Last Name" />
                <i className="r-text--grey-4 fi r-input_field_icon flaticon-user"></i>
              </Col>
            </Row>
            <Row className="mb-3" form>
              <Col xs={12}>
                <InputField error={invalidFields.indexOf("email") > -1} value={email} onChange={({ target }) => this.handleStateChange(target.name, target.value)} className="w-100" type="text" name="email" placeholder="Company Email" />
                <i className="r-text--grey-4 fi r-input_field_icon flaticon-mail"></i>
              </Col>
            </Row>
            <Row className="mb-3" form>
              { this.state.isLoadingSendOtp ? <Col className="mt-4"><div class="center-xy"><Spinner /></div></Col>:
                <Col xs={12}>
                <Button className="r-btn--no-radius" color="blue-3">Next</Button>
                <Button type="button" onClick={updateView}>Login</Button>
              </Col>
              }
            </Row>
          </div>
        </form>
      )
  else
    return (
      <form onSubmit={this.onSignup}>
        <Row className="mb-5" form>
          <Col>
            <div className="text-center">
              <h1 className="r-text--blue-3">Verify Email</h1>
              <p>Type in the verification code you recieved on email</p>
            </div>
          </Col>
        </Row>
        <Row className="mb-3" form>
          <Col xs={12} className="text-center">
            <span><span className="r-text--sm">Enter code before</span> {moment.utc(otpTimer.asMilliseconds()).format("mm:ss")}</span>
          </Col>
        </Row>
        <Row className="mb-3" form>
          <Col xs={12} className="text-center">
            <InputField className="w-100" type="text" name="token" placeholder="Enter Verification Code" />
            <i className="r-text--grey-4 fi r-input_field_icon flaticon-mail"></i>
            <Button type="button" onClick={this.sendOTP} className="r-subheading-4 text-underline">Resend verification code</Button>
          </Col>
        </Row>
        <Row className="mb-3" form>
          <Col xs={12} className="text-center">
            <Button className="r-btn--no-radius" color="blue-3">Verify</Button>
            <Button type="button" onClick={() => { clearInterval(this.timer); this.setState({ status: 0 }) }}>Back</Button>
          </Col>
        </Row>
      </form>
    )
  }
}
