import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import { getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import Button from '../../global/Button';

class Evaluate extends Component {

  state = {
    applications: []
  }

  componentDidMount() {
    setTimeout(()=>this.getApplications(), 0)
  }

  getApplications = async () => {
    store.dispatch(setLoading(true))
    try {
      let applicationResp = await Auth.Evaluate.getJobs()
      this.setState({ applications: applicationResp.data.data })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { applications } = this.state;
    return (
      <Container>
        <Row>
          <Col>
            <div className="top-navigation border-bottom mt-3">
              <Row>
                <Col xs={6}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-0">
                      <li className="breadcrumb-item">
                        <h1 className="r-heading-3">My Applications</h1>
                      </li>
                    </ol>
                  </nav>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {applications.map((job) =>
              <div key={job.campus_job_id} className="shadow-sm bg-light p-4 my-3">
                <Row className="align-items-center justify-content-between">
                  <Col xs="auto">
                    <h1 className="r-subheading-3 m-0">
                      {job.name} - {job.role}
                    </h1>
                  </Col>
                  <Col xs="auto">
                    <Button onClick={()=>this.props.history.push({pathname:`/evaluate/campus/${job.campus_id}/application/${job.application_id}/job/${job.campus_job_id}/round/0` , state :{evaluator : true}})} color="blue-3" square>Evaluate</Button>
                  </Col>
                </Row>
              </div>)
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Evaluate
