import React, { Component } from 'react'
import Search from './search';

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="search-box">
          <ul className="search search-horizontal my-5">
            <li className="search-item">
              <div className="search-badge">
                <span className="fi flaticon-search"></span>
              </div>
              <div className="search-heading">Search</div>
            </li>
            <li className="search-item">
              <div className="brand-logo">
                <img src="/images/reculta-white-logo.png" />
              </div>
              <div className="search-badge">
                <span className="fi flaticon-search"></span>
              </div>
              <div className="search-heading">Brand</div>
            </li>
            <li className="search-item">
              <div className="search-badge ">
                <span className="fi flaticon-user"></span>
              </div>
              <div className="search-heading">Hire</div>
            </li>
          </ul>
        </div>
        <div className="" style={{ position: "relative" }}>
          <div className="search-panel my-5">
            <Search />
          </div>
        </div>
      </div>

    )
  }
}