import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { RTag } from '../../../global/Button/r-tag';
import { CONSTANTS } from '../../../../constants';

export default class CollegeList extends Component {

  render() {
    let { collegeData, isCollegeSelected, searchTags, toggleSelect} = this.props;
    return (
      <div className="my-5">
        <Row >
          <Col xs={6} className="border-right">
            <Row>
              <Col>
                <div className="filter-panel my-5">
                  <ul className="nav">
                    <li className="nav-item mr-3">
                      <h3>List of colleges</h3>
                    </li>
                    <li className="nav-item mr-3">
                      <button className="font-weight-normal px-2 py-0 r-btn r-btn--brandB" color="brandB"><i className="m-2 r-text--sm fi flaticon-plus-1"></i>  Add College</button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            <Row>
              {
                collegeData.map(college => {
                  return (
                    <Col xs={4} className="my-2" key={college.campus_id}>
                      <div className={`card shadow-sm college-card ${isCollegeSelected.includes(college.campus_id) ? 'shortlisted' : null} text-center p-2`}>
                        {
                          isCollegeSelected.includes(college.campus_id) ?
                            <div className="ribbon ribbon-top-right"><span></span></div>
                            :
                            <div className="">
                              <div className="card-overlay"></div>
                              <div className="shortlist"
                                onClick={() => this.props.toggleSelect(college.campus_id)}>
                                <i className="fi flaticon-plus-1"></i>
                              </div>
                            </div>
                        }
                        <img src={college.logo?(process.env.REACT_APP_S3_URL+"college_logo/"+college.logo): "/images/campus-dummy.png"} className="college-logo" />
                        <h3 className="mt-3 r-heading-6">{college.campus_name}</h3>
                        <div className="r-stars">
                          <span className="fi flaticon-star mx-1 active"></span>
                          <span className="fi flaticon-star mx-1 active"></span>
                          <span className="fi flaticon-star mx-1"></span>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col>
                <div className="filter-panel my-5">
                  <ul className="nav">
                    <li className="nav-item mr-3">
                      <h3>{searchTags.profile_name || ""}</h3>
                    </li>
                  </ul>
                </div>
                <div className="filter-panel my-5">
                  <ul className="nav">
                    {searchTags.job_type ? <li className="nav-item mr-3">
                      <RTag>{CONSTANTS.SEARCH.JOBTYPE[searchTags.job_type] || searchTags.job_type}</RTag>
                    </li> : null}
                    {(searchTags.ctc_min || searchTags.ctc_max) ? <li className="nav-item mr-3">
                      <RTag>{searchTags.ctc_min || "Any"}-{searchTags.ctc_max || "Any"}</RTag>
                    </li> : null}
                    {searchTags.location?<li className="nav-item mr-3">
                      <RTag>{searchTags.location}</RTag>
                    </li>: null}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              {
                isCollegeSelected.length ?
                  collegeData.map(college => {
                    return (
                      isCollegeSelected.includes(college.campus_id) ?
                        <Col xs={4} className="my-2" key={college.campus_id}>
                          <div className="card shadow-sm college-card text-center p-2">
                            <div className="shortlist remove"
                              onClick={() => this.props.toggleSelect(college.campus_id)}>
                              <i className="fi flaticon-bin"></i>
                            </div>
                            <img src={college.logo?(process.env.REACT_APP_S3_URL+"college_logo/"+college.logo): "/images/campus-dummy.png"} className="college-logo" />
                            <h3 className="mt-3 r-heading-6">{college.campus_name}</h3>
                            <div className="r-stars">
                              <span className="fi flaticon-star mx-1 active"></span>
                              <span className="fi flaticon-star mx-1 active"></span>
                              <span className="fi flaticon-star mx-1"></span>
                            </div>
                          </div>
                        </Col>
                        :
                        null
                    )
                  })
                  :
                  null
              }
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}