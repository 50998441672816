import { ACTIONS } from "../constants/actions";

export default (state = [], action) => {
  switch (action.type) {
    case ACTIONS.SET_APPLICANTS:
      if (action.payload === null) return [];
      return ([...action.payload])
    default:
      return state
  }
}
