import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import CollegeList from './listColleges';
import store from '../../../../store';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Button from '../../../global/Button';
import { setLoading, setError } from '../../../../actions/action-creators';
import { Auth } from '../../../../actions/Auth';
import { getErrorMessage } from '../../../../actions';

class College extends Component {
  constructor(props) {
    super(props)
    var searchParams = new URLSearchParams(window.location.search);
    let searchTags = {}
    searchParams.forEach(function (value, key) {
      searchTags[key] = value
    });
    this.state = {
      searchTags,
      isCollegeSelected: [],
      collegeData: []
    }
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getColleges()
  }

  getColleges = async () => {
    let { searchTags } = this.state;
    store.dispatch(setLoading(true))
    try {
      let data = {
        "fields": ["campus_id", "campus_name"],
        // "rangeFilter": {
        //   "role_info": [
        //     {
        //       "terms": {
        //         "field": "role_name",
        //         "value": [searchTags.profile_name]
        //       }
        //     }
        //   ]
        // }
      }
      if (searchTags.location) {
        data.where = {
          "campus_city": [searchTags.location || ""]
        }
      }
      if (searchTags.job_type) {
        data.rangeFilter.process_info = [
          {
            "match": {
              "field": "process_type",
              "value": searchTags.job_type || ""
            }
          }
        ]
      }
      let apiResp = await Auth.Recruiter.getColleges({data})
      this.setState({ collegeData: apiResp.data.data })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  toggleSelect = (collegeId) => {
    let { isCollegeSelected } = this.state
    let index = isCollegeSelected.indexOf(collegeId)
    if (index > -1) {
      isCollegeSelected.splice(index, 1)
    } else {
      isCollegeSelected.push(collegeId)
    }
    this.setState({ isCollegeSelected })
  }

  handleBrandingClick = (e) => {
    e.preventDefault()
    let reduxState = store.getState()
    if (!reduxState.user) {
      let loginEl = document.getElementById("login-signup")
      if (loginEl) loginEl.click()
      return false
    }
    if (reduxState.user && !reduxState.user.recruiter_id) {
      let companyEl = document.getElementById("create-recruiter")
      if (companyEl) companyEl.click()
      return false
    }
    this.addProject()
  }

  addProject = async () => {
    let { searchTags, isCollegeSelected } = this.state
    if (isCollegeSelected.length === 0) {
      store.dispatch(setError({ showBar: true, message: "Please add atleast 1 institute to start branding" }))
      return
    }
    store.dispatch(setLoading(true))
    try {
      let projectData = Object.keys(searchTags).reduce((prev, curr) => {
        if (searchTags[curr]) prev[curr] = searchTags[curr]
        return prev
      }, {}),
        projectResp = await Auth.Project.createProject(projectData),
        project_id = projectResp.data.data[0].project_id
      await Auth.Project.addCampus({ project_id, campus_id: isCollegeSelected });
      this.props.history.push("/college")
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { searchTags, collegeData, isCollegeSelected } = this.state
    return (
      <div className="">
        <div className="container">
          <div className="colleges">
            <div className="top-navigation border-bottom mt-3">
              <Row >
                <Col xs={6}>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="#">Search</a></li>
                      <li className="breadcrumb-item " aria-current="page">List of College</li>
                    </ol>
                  </nav>
                </Col>
                <Col xs={6}>
                  <ul className="nav justify-content-end align-items-center">
                    {/* <li className="nav-item">
                      <Button className="nav-link active">Search</Button>
                    </li>
                    <li className="nav-item">
                      <Button className="nav-link disabled">Brand</Button>
                    </li>
                    <li className="nav-item">
                      <Button className="nav-link disabled">Hire</Button>
                    </li>
                    <li className="nav-item">
                      <span href="#">|</span>
                    </li> */}
                    <li className="nav-item">
                      <Button className="nav-link" onClick={this.handleBrandingClick}>Start Project</Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div className="list-college">
              <CollegeList toggleSelect={this.toggleSelect} searchTags={searchTags} collegeData={collegeData} isCollegeSelected={isCollegeSelected} />
            </div>
          </div>
        </div>
      </div>
    )
  }

}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(College));
