import React, { Component } from 'react';
import Button from './Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import ModalBody from 'reactstrap/lib/ModalBody';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import { setConfirm } from '../../actions/action-creators';
class SuccessComponent extends Component {


	onClose = () => {
		this.props.setConfirm({ callback: null })
	}
	executeCallback = async() => {
		await this.props.confirm.callback();
		this.onClose()
	}
	render() {
		let { confirm } = this.props
		return (
			<Modal isOpen={!!confirm.callback} unmountOnExit={true}  className="modal-dialog-centered" toggle={this.onClose}>
				<ModalBody className="">
					<p className="d-flex py-4 r-subheading-3 m-0 justify-content-center">{confirm.message || "Are you sure?"}</p>
				</ModalBody>
					<Row noGutters>
						<Col className="r-text--light">
							<Button onClick={this.onClose} className="w-100 py-3" style={{backgroundColor: "#fecfc4", color: "#fa3709"}} square={true}><i className="fi flaticon-close mr-2" ></i>No</Button>
						</Col>
						<Col className="r-text--light">
							<Button onClick={this.executeCallback} className="w-100 py-3" color="blue" square={true}>&#10004; Yes</Button>
						</Col>
					</Row>
			</Modal>
		)
	}
}
const mapStateToProps = state => ({
	...state
})
const mapDispatchToProps = dispatch => ({
	setConfirm: (confirm) => dispatch(setConfirm(confirm))
})
export const Confirmbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(SuccessComponent));
