import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from '../../global/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Auth } from '../../../actions/Auth';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import store from '../../../store';
import { setSuccess, setError } from '../../../actions/action-creators';

 class QuickNav extends Component {

  constructor(props){
    super(props);
    this.state= this.initialState();
  }

  initialState = ()=>{
    return(
      {
        isVisible : false
      }
    )
  }

  downloadCv = async()=>{
    this.setState({isVisible : false})
    let {scoringApplicants} = this.props;
    let studentIdArray=[];
    if(Object.keys(this.props.selectedStudents).length){
      Object.keys(this.props.selectedStudents).forEach(item=>{
        if(this.props.selectedStudents[item]==true){
          studentIdArray.push(item);
        }
      })
    }
    if(scoringApplicants.length && this.props.selectedStudents && Object.keys(this.props.selectedStudents).length==0 && studentIdArray.length==0){
      scoringApplicants.forEach(item=>{
        studentIdArray.push(item.student_id);
      })
    }
    try{
      let downloadAnswersResp = await Auth.Download.cv({
        application_id : this.props.match.params.application_id ,
        student_id: studentIdArray ,
        role_name : this.props.applicationDetail.role,
        campus_id : this.props.match.params.campus_id});
        store.dispatch(setSuccess({showBar: true, message: "Student CV download is in progress. We will send you a link on your email once it is complete."}))
    }
    catch(e){
      console.log(e);
    }
  }


  downloadAnswers = async()=>{
    this.setState({isVisible : false})
    let {scoringApplicants} = this.props;
    let studentIdArray=[];
    if(Object.keys(this.props.selectedStudents).length){
      Object.keys(this.props.selectedStudents).forEach(item=>{
        if(this.props.selectedStudents[item]==true){
          studentIdArray.push(item);
        }
      })
    }
    if(scoringApplicants.length && this.props.selectedStudents && Object.keys(this.props.selectedStudents).length==0 && studentIdArray.length==0){
      scoringApplicants.forEach(item=>{
        studentIdArray.push(item.student_id);
      })
    }
    try{
      let downloadAnswersResp = await Auth.Download.answers({
        application_id : this.props.match.params.application_id ,
        student_id: studentIdArray });
        const url = window.URL.createObjectURL(new Blob([downloadAnswersResp.data.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'answers.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      console.log(e);
    }
  }

  downloadExcel = async()=>{
    this.setState({isVisible : false});
    let {scoringApplicants} = this.props;
    let studentIdArray=[];
    if(Object.keys(this.props.selectedStudents).length){
      Object.keys(this.props.selectedStudents).forEach(item=>{
        if(this.props.selectedStudents[item]==true){
          studentIdArray.push(item);
        }
      })
    }
    if(scoringApplicants.length && this.props.selectedStudents && Object.keys(this.props.selectedStudents).length==0 && studentIdArray.length==0){
      scoringApplicants.forEach(item=>{
        studentIdArray.push(item.student_id);
      })
    }
    try{
      let downloadExcelResp = await Auth.Download.studentData({
        campus_id : this.props.match.params.campus_id ,
        student_id: studentIdArray });
        const url = window.URL.createObjectURL(new Blob([downloadExcelResp.data.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = 'students_data.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    catch(e){
      console.log(e);
    }
  }

  render() {
    let {applicationDetail} = this.props,
    {isVisible} = this.state;
    return (
      <div className="quick-nav border-bottom">
        <div className="links-top my-4">
          <Row>
            <Col xs={6}>
              <div className="">
                {applicationDetail && <h1 className="r-subheading-1">{applicationDetail.name} - {applicationDetail.role}</h1>}
              </div>
            </Col>
            <Col xs={6}>
              <div className="">
                <ul className="nav justify-content-end download">
                  <li className="nav-item">
                    <Button color="dark" className="r-text--sm" onClick={()=>this.setState({isVisible : true})}>Download<i className="ml-2 fi flaticon-file"/></Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="links-bottom">
          <Row>
            {/*<Col xs={6}>
              <div className="">
                <input type="text" className="w-100 r-inputfield pr-5" placeholder="Type here" />
                <span className="search-icon">
                  <i className="fi flaticon-search"></i>
                </span>
              </div>
            </Col>*/}
            <Col xs={6}>
              <Modal isOpen={isVisible} className="modal-dialog-centered" zIndex="999999" size="m" toggle={()=>this.setState({isVisible : false})}>
                <ModalBody>
                  <Row>
                    <Col xs="5">
                      <Button onClick={()=>this.downloadCv()} color="dark" className="r-subheading-3 ">Send CV's to email<i className="ml-2 fi flaticon-file"/></Button>
                    </Col>
                    <Col xs="3">
                      <Button onClick={()=>this.downloadExcel()} color="dark" className="r-subheading-3 ">Excel<i className="ml-2 fi flaticon-file"/></Button>
                    </Col>
                    <Col xs="4">
                      <Button onClick={()=>this.downloadAnswers()} color="dark" className="r-subheading-3">Answers<i className="ml-2 fi flaticon-file"/></Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
export default QuickNav = withRouter(connect(mapStateToProps)(QuickNav));
