import { ACTIONS } from "../constants/actions";
import { Auth } from "./Auth";

export const setUser = (user) => dispatch => {
  dispatch({
    type: ACTIONS.SET_USER,
    payload: user
  })
}

export const setLoading = (show) => dispatch => {
  dispatch({
    type: ACTIONS.SET_LOADER,
    payload: show
  })
}

export const setRole = (role) => async(dispatch) => {
  try {
    // await Auth.User.updateProfile({ current_role_id: role.user_role_id })
    if(role.role_id === 2){
      var permissionListResp = await Auth.General.getAllPermission();
      var permissionResp = await Auth.Recruiter.getPermission({ user_role_id: role.user_role_id })
      role.permission = permissionListResp.data.data.filter(p => !!permissionResp.data.data.filter( o => o.permission_id === p.permission_id).length);
    }
  } catch (e) { console.log(e) }
  dispatch({
    type: ACTIONS.SET_ROLE,
    payload: role
  })
}

export const setError = (error) => dispatch => {
  dispatch({
    type: ACTIONS.SET_ERROR,
    payload: error
  })
}

export const setSuccess = (success) => dispatch => {
  dispatch({
    type: ACTIONS.SET_SUCCESS,
    payload: success
  })
}
export const setConfirm = (confirm) => dispatch => {
  dispatch({
    type: ACTIONS.SET_CONFIRM,
    payload: confirm
  })
}

export const setData = (data) => dispatch => {
  dispatch({
    type: "DB",
    payload: data
  })
}

export const setApplicants = (data) => dispatch => {
  dispatch({
    type: ACTIONS.SET_APPLICANTS,
    payload: data
  })
}

export const setScoringApplicants = (data)=>dispatch=>{
  dispatch({
    type : ACTIONS.SET_SCORING_APPLICANTS,
    payload : data
  })
}
