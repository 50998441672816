import React, { Component } from "react";
import { Auth } from "../../../actions/Auth";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import withRouter from "react-router-dom/withRouter";
import Button from "../../global/Button";
import { Message } from "../../../constants/messages";
import store from "../../../store";
import { setLoading, setConfirm, setError, setSuccess } from "../../../actions/action-creators";
import { getErrorMessage } from "../../../actions";
import { Select } from '../../global/Forms/Select';
import {ENDPOINT, ENDPOINTS} from '../../../constants/endpoints';


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProcessId : null,
      processList: [],
      reportList: [],

    };
  }

  componentDidMount() {
    this.init() ;

  }

  init = async () => {
    store.dispatch(setLoading(true))
    try {
      let processResp = await Auth.Process.getProcess()
      this.setState({
        processList: processResp.data.data
      })
      store.dispatch(setLoading(false))
      if (processResp.data.data[0]){
        this.setState({selectedProcessId : processResp.data.data[0].recruiter_process_id})
        await this.fetchReport(processResp.data.data[0].recruiter_process_id)
      }
    } catch (e) {
      store.dispatch(setLoading(false))
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

onChangeDropdown = (value) => {
    this.setState({selectedProcessId : value}) ;
    this.fetchReport(value)
}

getProcessCampus = async (process) => {
  store.dispatch(setLoading(true))
  try {
    let campusResp = await Auth.Process.getCampusProcess({ recruiter_process_id: process.recruiter_process_id })
    let campusList = campusResp.data.data
    this.setState({ campusList, selectedProcess: process })
  } catch (e) {
    console.log(e)
    store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
  }
  store.dispatch(setLoading(false))
}

fetchReport = async (recruiter_process_id) => {
  store.dispatch(setLoading(true))
  try {
    let Resp = await Auth.report.viewReport({recruiter_process_id})
    let reportList = Resp.data.data
    this.setState({reportList})
  } catch (e) {
    console.log(e)
    store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
  }
  store.dispatch(setLoading(false))
}


   render() {
    let {processList , reportList} = this.state;
    return (<div className="recruitment">

                <div className="container">
                    <div className="top-navigation pb-3 mt-5">
                      <Row>
                        <Col xs={2}>
                           <Select required onChange={(e) => this.onChangeDropdown(e.target.value)}>
                                {/* <option value="">Select</option> */}
                                {processList.map((p , index) =>
                                  <option value={p.recruiter_process_id} key={index}>{p.process_name}</option>
                                )}          
                            </Select>
                        </Col>

                        <Col xs={10} className="text-right">
                          <a href={ENDPOINTS.REPORT.VIEW_REPORT+"?recruiter_process_id="+this.state.selectedProcessId+"&download=true"} target="_blank"><Button className="r-text--sm r-btn r-btn--blue r-btn--no-radius">Download</Button></a>
                        </Col>
                      </Row>

                      <table className="table text-center border table-sm w-100 mt-3">
                        <tr className="r-bg--blue-2">
                          <th className="p-2">#</th>
                          <th className="p-2">Campus</th>
                          <th className="p-2">Role</th> 
                          <th className="p-2">Eligible</th>
                          <th className="p-2">Applied</th>
                          <th className="p-2">Round 1</th>
                          <th className="p-2">Round 2</th>
                          <th className="p-2">Round 3</th>
                          <th className="p-2">Round 4</th>
                        </tr>
                       {reportList.map((obj, i) =>  
                       <tr>
                          <td>{i+1}</td>
                          <td>{obj["Campus Name"]}</td>
                          <td>{obj["Role Name"]}</td>
                          <td>{obj.Eligible}</td>
                          <td>{obj.Applied}</td>
                          <td>{obj["Round 1"] || "-"}</td>
                          <td>{obj["Round 2"] || "-"}</td>
                          <td>{obj["Round 3"] || "-"}</td>
                          <td>{obj["Round 4"] || "-"}</td>
                        </tr>
                       )}
                      </table>

                    </div>
                </div>
            </div>)
  }
}

export default withRouter((Reports));
