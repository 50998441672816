import React from 'react';
import { Row,Col,Container,UncontrolledCollapse,CardBody, Card,Collapse,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import Button from '../../global/Button';
import { RTE } from '../../global/Forms/RTE';
import { CONSTANTS } from '../../../constants';
import { InputField } from '../../global/Forms';
import { RichTextViewer } from '../../global/Forms/RichTextViewer';
import moment from 'moment';
import { Auth } from '../../.././actions/Auth';
import {  getErrorMessage, uploadFile, getFiles } from '../../../actions';
import { Select } from '../../global/Forms/Select';
import store from '../../../store';
import { setError, setLoading ,setUser, setSuccess } from '../../../actions/action-creators';



class ProfilePage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      campusList : [],
      campus_id : null,
      addPostDetails : {},
      postList : []
    };
     
  }

 async componentDidMount(){
    this.getCollegeList();
    this.getPost();
  }

  getCollegeList = async () => {
    store.dispatch(setLoading(true))
    try {
      let recruiterCampus = await Auth.General.getAllCampus()
      let campusList = recruiterCampus.data.data
       this.setState({
         campusList
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  
  getPost = async () => {
    console.log(this.props)
    store.dispatch(setLoading(true))
    try {
      var postBody = {
        creater_type: "RECRUITER",
        origin_id : this.props.user.recruiter_id,
      }
      let postResp = await Auth.post.viewPost(postBody)
      let postList = postResp.data;
      let fileIds = [];
      postList.forEach(p => {
        fileIds.push(p.file_id)
      });
      let files = await getFiles(fileIds)
       this.setState({
         postList,
         files
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  onChangeAddPost = (key , value) => {
    let {addPostDetails} = this.state ;  
    addPostDetails[key] = value ;
    this.setState({addPostDetails}) ;
}

  postCreate = async(e)=>{
    e.preventDefault();
    store.dispatch(setLoading(true));
    let {addPostDetails} = this.state;
      let file_id = null;
       if(addPostDetails.file){
            let newFile = await uploadFile(addPostDetails.file,this.props.user.id + "/post" );
            file_id = newFile.file_id;
        }
        try {
          var postBody = {
            file_id,
            title : addPostDetails.title ,
            description : addPostDetails.description,
            campus_id : addPostDetails.campus_id,
            origin_id : this.props.user.recruiter_id,
            creater_type : "RECRUITER"
          }
          await Auth.post.sendPost(postBody);
          this.getPost()
          this.setState({addPostDetails : {}})
          store.dispatch(setSuccess({ showBar: true, message: "Posted Successfully!" }))
        } catch (e) {
          console.log(e);
          store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        }
      store.dispatch(setLoading(false));

}


  render() {
    let {campusList , addPostDetails, postList, files} = this.state;
    campusList = campusList.filter(campus => campus.connection_status === 1);
   
   
    return (
            <Container>
                    <Row className="justify-content-center">
                    <Col xs={12} className="">
                        <div className="border" style={{borderRadius : "15px"}}>
                                <Col xs="auto">
                                    <Row className="align-items-center py-3">
                                      <Col xs={7}>
                                        <div className="post__header align-items-center" >
                                          <span className="r-heading-4">Create Post</span>
                                        </div>
                                      </Col>
                                      </Row>
                                </Col>
                                    <form onSubmit={this.postCreate}>
                                      {addPostDetails.file?<Row  className="justify-content-center align-items-center "><img style={{width: "250px"}} src={URL.createObjectURL(addPostDetails.file)} alt=""/></Row>:null}
                                      <Col xs="12" className=" my-3">
                                          <InputField value={addPostDetails.title || ""} onChange={(e) => this.onChangeAddPost("title", e.target.value)} className="r-nav__search titleinput" placeholder="Title here..." name="title" type="text" required />
                                        </Col>
                                      <Col xs="12" className=" my-3">
                                          <RTE onChange={(v) => this.onChangeAddPost("description",v)} value={addPostDetails.description || ""} className=" "  name="content"  rows="3" placeholder="Description here..." required />
                                      </Col>
                                      <Col xs={{ size: '12', offset: 0 }}>
                                          <Row className=" align-items-center my-4">
                                              <Col> 
                                                <label htmlFor="file-input-image" className="ml-4"  >
                                                  <Row className="border align-items-center font-weight-normal py-1  px-3  ">
                                                      <span style={{fontSize:"14px"}} className="r-text--grey-4 my-1">Add an</span>
                                                      <div className=" mx-2"/>
                                                      <i className=" r-text--xl fi flaticon-art r-text--grey-4" style={{fontSize:"16px"}}/>
                                                  </Row>
                                                  <input  onChange={(e) => this.onChangeAddPost("file", e.target.files[0])} id="file-input-image" type="file" style={{display:"none"}}/>
                                                </label>
                                              </Col>
                                              <Col xs={4}>  
                                                
                                                <Select value={addPostDetails.campus_id || ""} required onChange={(e) => this.onChangeAddPost("campus_id", e.target.value)}>
                                                    <option value="">Select Campus</option>
                                                    {campusList.map((campus , index) =>
                                                      <option value={campus.campus_id} key={index}>{campus.name}</option>
                                                    )}          
                                                </Select>
                                               
                                              </Col>  
                                              <Col xs="auto">
                                                  <Button type="submit" className="font-weight-normal  px-5 py-2 r-btn--no-radius r-btn r-btn--dark" color="dark">Post</Button>
                                              </Col>         

                                          </Row>
                                    </Col>
                  
                                </form>

                                {/* <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={() => this.setState({ addStudentModal: false })}>
                                    <ModalBody>
                                      <h2 className="r-subheading-1 text-center">Select Students to import them to placement drive</h2>
                                      <p className="r-heading-4"><span className="r-text--green-1">{selectedCount} students </span> selected</p>
                                      <FilterWithProcess filterStudents={this.filterStudents} />
                                      <div className="text-center mt-4">
                                        <Button type="button" color="dark" onClick={this.postCreate} className="r-btn--no-radius">Post</Button>
                                      </div>
                                    </ModalBody>
                                  </Modal> */}
                            </div>
                    </Col>
              </Row>
              {postList.map((post, i) => 
                <div key={i} className="post my-4">
                  <Row noGutters className="align-items-center border-bottom py-2 px-3 " style={{ borderTopLeftRadius : 8 , borderTopRightRadius : 8}}>
                      <Col xs={8}>
                          <h3 className="r-subheading-2 mb-0" style={{fontWeight : 500}}>{post.title}</h3>
              <em style={{fontSize : 12 }}>{"Posted to"} - {post.campus_data.name}</em>

                      </Col>
                      <Col xs={4} className="text-right" style={{fontSize : 12}}>
                        <em style={{color : "#cccccc"}}>{moment(post.created_on).format("hh:mma, Do MMMM YYYY")}</em>
                      </Col>
                  </Row>
                  {(files[post.file_id] && files[post.file_id][0]) && <Row className="p-2">
                    <Col xs={12}>
                      <img src={files[post.file_id][0].url} className="post__banner" alt=""/>
                    </Col>
                  </Row>}
                    <Row>
                      <Col xs={12}><div className="p-4"><RichTextViewer>{post.description}</RichTextViewer></div> </Col>
                    </Row>
                    <Row noGutters className="align-items-center p-2 border-top">
                            <Col xs={6}>
              <Button className="r-btn--no-radius align-items-center" color="light"><i className="m-2 fi flaticon-like"></i>{post.no_of_like || 0}</Button>
                            </Col>
                            {/* <Col className="text-right">
                              { data.origin_type === "APPLICATION PUBLISH" ? <Button onClick={() => {this.props.redirect(this.props.role_type==="PT" ? ("/pt/processes/"+data.origin_id+"/detail") :("/student/recruitments/apply?process_id="+data.origin_id))}} style={{fontSize : 10, background : "#868686cc"}} color="brandB">{this.props.role_type==="PT" ? "View Drive" : "Apply Now"}</Button> : null}
                              { data.origin_type === "EVENT" ? <Button onClick={() => {this.props.redirect("/calendar")}} color="brandB" style={{fontSize : 10, background: "#3174adb8"}}>View Event</Button> : null}
                            </Col> */}
                            <Col xs={6} className="text-right">
                              <p>{post.status == "1" ? "Published":"Not Published"}</p>
                            </Col>
                    </Row>
                  </div>
              )}
            </Container>
    );
  }
}

const mapStateToProps = state => ({
  user : state.user,
  role : state.role
})

export default connect(mapStateToProps, null)(ProfilePage)
