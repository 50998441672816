import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import Button from './Button';
var monthlist = ["Jan", "Feb", "Mar", "Apr ", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var day = new Date().getDate();
var month = new Date().getMonth();

const likes = [
  {
    name: 'Likes',
    data: [
      { category: monthlist[month] + "," + day, value: 0 },
      { category: monthlist[month] + "," + (day + 1), value: 5 },
      { category: monthlist[month] + "," + (day + 2), value: 7 },
      { category: monthlist[month] + "," + (day + 3), value: 2 },
      { category: monthlist[month] + "," + (day + 4), value: 8 },
      { category: monthlist[month] + "," + (day + 5), value: 4 },
      { category: monthlist[month] + "," + (day + 6), value: 4 },
    ],
  },
];

const views = [
  {
    name: 'Views',
    data: [
      { category: monthlist[month] + "," + day, value: 0 },
      { category: monthlist[month] + "," + (day + 1), value: 10 },
      { category: monthlist[month] + "," + (day + 2), value: 5 },
      { category: monthlist[month] + "," + (day + 3), value: 5 },
      { category: monthlist[month] + "," + (day + 4), value: 24 },
      { category: monthlist[month] + "," + (day + 5), value: 13 },
      { category: monthlist[month] + "," + (day + 6), value: 18 },
    ],
  },
];
export class FeedPost extends Component {
  state = {
    typeList: "Like"
  }
  
  onChange = e => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  
  render() {
    let { typeList } = this.state
    let { data, files, isCardView } = this.props;
    let { post_date, file_id, post_content, post_title, post_tag, post_banner_id, post_id, no_of_like, is_liked } = data;
    let classNames = ["m-2 r-text--lg fi flaticon-like", (is_liked === 1) ? "r-text--brandA" : "r-text--brandC"].join(" ");
    return (
      <div className={this.props.postType}>
        <Collapse isOpen={isCardView !== post_id}>
          <Row className="align-items-center justify-content-between p-4">
            <Col xs="auto">
              {moment(post_date).format("hh:mma, Do MMMM YYYY")}
            </Col>
            <Col xs="auto">
              <div className="post__header text-right">
                <button type="button" className="r-btn" onClick={() => this.props.handleCardView(post_id)}>
                  <i className="fi fi-abs flaticon-eye"></i>
                </button>
              </div>
            </Col>
          </Row>
          {(files[post_banner_id] && files[post_banner_id][0]) && <Row>
            <Col xs={12}>
              <img src={files[post_banner_id][0].url} className="post__banner" alt="" />
            </Col>
          </Row>}
          <Row>
            <Col xs={12}>
              <div className="post__body">
                <span className="r-subheading-2 r-text--grey-5">{post_title}</span><span className="r-text--sm"> {post_tag ? ` | #${post_tag}` : ""} </span>
                <p className="my-0">{post_content}</p>
                {(files[file_id]) && <Row noGutters>
                  {files[file_id].map((doc, i) => <Col className="pr-3" key={i} xs="auto">
                    <div className="r-tag r-tag--type-1 r-tag--no-delete">
                      <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.file_name || doc.file_url || "Filename"}</a>
                    </div>
                  </Col>)}
                </Row>}
                {/* <Button className="r-btn--no-radius" color="dark">Know more!</Button> */}
                <Row className="align-items-center">
                  <Button className="r-btn--no-radius align-items-center" color="light">
                    <i className={classNames} ></i>{no_of_like}
                  </Button>
                </Row>
              </div>
            </Col>
          </Row>
        </Collapse>
        <Collapse isOpen={isCardView === post_id}>
          <Container fluid={true} className="r-bg--blue-3">
            <Row className="justify-content-between align-items-center m-0">
              <span className="r-subheading-2 r-text--light">Post Statistics</span>
              <Button onClick={(e) => this.props.handleCardView("")}> <i className="d-inline-block fi flaticon-hidden r-text--light" /> </Button>
            </Row>
            <Row className="justify-content-around align-items-center my-3">
              <Row className="post px-2" style={{ backgroundColor: "#799fea" }}>
                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-eye" style={{ fontSize: "40px" }} />
                <Col xs="auto">
                  <Row><span className="r-subheading-2 r-text--light">{data.no_of_seen} / 120</span></Row>
                  <Row> <span className="r-subheading-2 r-text--light">Total Views</span></Row>
                </Col>
              </Row>
              <Row className="post px-2" style={{ backgroundColor: "#799fea" }}>
                <i className="mr-3 r-text--sm r-text--skyblue fi flaticon-like" style={{ fontSize: "40px" }} />
                <Col xs="auto">
                  <Row><span className="r-subheading-2 r-text--light">{data.no_of_like} / 120</span></Row>
                  <Row> <span className="r-subheading-2 r-text--light">Total Likes</span></Row>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col xs="auto" className=" my-3">
                <select value={typeList} name="typeList" className="d-inline-block r-text--light my-3" style={{ backgroundColor: "rgb(0, 102, 204)" }} onChange={this.onChange}>
                  <option className="d-inline-block r-text--dark" value="Like">Like</option>
                  <option className="d-inline-block r-text--dark" value="View">View</option>
                </select>
                {(typeList === "Like") ? <LineChart width={500} height={300}   >
                  <CartesianGrid vertical={false} verticalFill={['#799fea']} />
                  <XAxis dataKey="category" stroke="#FFF" />
                  <YAxis dataKey="value" stroke="#fff" />
                  {likes.map(s => (
                    <Line stroke="#fff" dataKey="value" data={s.data} name={s.name} key={s.name} dot={false} />
                  ))}
                </LineChart> :
                  <LineChart width={500} height={300}   >
                    <CartesianGrid vertical={false} verticalFill={['#799fea']} />
                    <XAxis dataKey="category" stroke="#FFF" />
                    <YAxis dataKey="value" stroke="#fff" />
                    {views.map(s => (
                      <Line stroke="#fff" dataKey="value" data={s.data} name={s.name} key={s.name} dot={false} />
                    ))}
                  </LineChart>}
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>
    )
  }
}