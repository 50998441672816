import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import Redirect from 'react-router-dom/Redirect';
import Application from '../views/application';
import Home from '../views/home';
import SearchShow from '../views/search/show';
import { Admin } from './admin';
import { Evaluator } from './Evaluator';
import { Recruiter } from './recruiter';
import profilePage from '../views/profile-page';

const ROUTE_CONSTANT = {
    1: Admin,
    2: Recruiter,
    3: Evaluator
}
export const Routes = (props) => {
    let RoleRoute = ROUTE_CONSTANT[props.role.role_id] || null;
    return (
        <Switch>
            {/* {RoleRoute === null && <Route exact path="/" component={Home} />} */}
            <Route exact path="/application/:token/round/:round_id" component={Application} />
            <Route exact path="/campus/:campus_id/application/:application_id/job/:campus_job_id/round/:round_id" component={Application} />
            <Route exact path="/search" component={Home} />
            <Route exact path="/search/show" component={SearchShow} />
            {RoleRoute !== null ? <RoleRoute /> :  <Route render={() => <Redirect to="/" />} />}
        </Switch>
    )
}
