import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Header from './global/Header';
import Switch from 'react-router-dom/Switch';
import { Routes } from './routes';
import { setUser, setLoading, setRole } from '../actions/action-creators';
import { Auth } from '../actions/Auth';
import Aux from './global/AuxComp';
import { Loader } from './global/Loader';
import { Errorbar } from './global/errorbar';
import { Successbar } from './global/successbar';
import { Confirmbar } from './global/confirmbar';
import Home from './views/home';
import Footer from './global/Footer';
import { Route, Redirect } from 'react-router-dom';
import profilePage from './views/profile-page';

/**
 * @author Akshay Arora
 * @description Root component of the application
 * @class App
 * @extends {Component}
 */
class App extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      sessionCheck: true,
      userStatus : false
    }
  }

  componentDidMount() {
    this.getSessionUser()
  }

  getSessionUser = async () => {
    this.props.setLoading(true)
    this.setState({ sessionCheck: true })

    try {
      let response = await Auth.User.getUser();
      let userInfo = response.data.data;
      await this.props.setRole(userInfo.roles[0])
      await this.props.setUser({ ...userInfo });
      if(userInfo){
        this.setState({userStatus : true});
      }
      if(!userInfo || !userInfo.roles[0]){
        this.setState({userStatus : false});
      }
    } catch (e) {
      console.log(e)
    }
    this.props.setLoading(false)
    this.setState({ sessionCheck: false })
  }


  render = () => {
    let { user, loader, role } = this.props,
      { sessionCheck } = this.state;
    return (
      <Aux>
        {!user && !sessionCheck ?  <Switch>
            <Route exact path="/profile/:recruiter_id" component={profilePage} />
            <Route exact path="/" component={Home} />
            <Route render={() => <Redirect to="/" />} />
        </Switch> : <Aux>
        <Header user={user} role={role} />
        {!sessionCheck && <Routes user={user} role={role} />}
        {role.role_id == 1 && <Footer />}
        <Loader show={loader} />
        <Errorbar />
        <Successbar />
        <Confirmbar />
        </Aux>}
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoading: (show) => dispatch(setLoading(show)),
  setRole: (role) => dispatch(setRole(role))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
