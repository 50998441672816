import { ACTIONS } from "../constants/actions";
import { data } from "../constants/data";

export default (state = data, action) => {
  switch (action.type) {
    case "DB":
      return { ...state, ...action.payload }
    default:
      return state
  }
}