export const CONSTANTS = {
  USER_TYPE: {
    user_type: "RECRUITER"
  },
  DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  ROLE_TYPE: {
    1: "Admin",
    2: "Recruiter",
    3: "Evaluator",
    4: "Interviewer"
  },
  COLLEGENAV: [{
    id: "home",
    text: "Home"
  },{
    id: "about",
    text: "About"
  }],
  NAV: {
    1: [{
        "name": "Jobs",
        "link": "/jobs"
      }, {
      "name": "Campus Drives",
      "link": "/recruitment"
    }, {
      "name": "Institutes",
      "link": "/college"
    }, {
      "name": "Feed",
      "link": "/profile"
    },{
      "name": "Reports",
      "link": "/reports"
    }],
    2: [{
      "name": "Jobs",
      "link": "/jobs",
      "permission_type" : "JOB_TAB"
    }, {
      "name": "Campus Drives",
      "link": "/recruitment",
      "permission_type" : "PROCESS_TAB"
    },{
      "name": "Institutes",
      "link": "/college",
      "permission_type" : "INSTITUTE_TAB"
    }],
    3: [{
      "name": "Applications",
      "link": "/evalutate/applications"
    }],
    4: []
  },
  SEARCH: {
    JOBTYPE: {
      "finals": "Full Time",
      "internship": "Part Time"
    }
  },
  ELIGIBILITY_CRITERIA: {
    "X_SCORE": {
      value: "Xth Score",
      operatorGroup: 1
    },
    "XII_SCORE": {
      value: "XIIth Score",
      operatorGroup: 1
    },
    "GRAD_SCORE": {
      value: "Graduation Score",
      operatorGroup: 1
    },
    "PG_SCORE": {
      value: "Post Graduation Score",
      operatorGroup: 1
    },
    "AGE": {
      value: "Age",
      operatorGroup: 1
    },
    "WORK_EX_MONTHS": {
      value: "Work Experience(Months)",
      operatorGroup: 1
    },
    "WORK_EX_DOMAIN": {
      value: "Work Experience - Domain",
      operatorGroup: 2
    },
    "WORK_EX_SECTOR": {
      value: "Work Experience - Sector",
      operatorGroup: 2,
      listFunction: "getEligibilitySector"
    }
  },
  ROUND_TYPES: {
    "TALK": "Pre-placement Talk",
    "RESUME_SHORTLISTING": "Resume Shortlisting",
    "WRITTEN": "Written Test",
    "GD": "Group Discussion",
    "PI": "Functional Interview",
    "HR_PI": "HR Interview",
    "Psychometric": "Psychometric Test",
    "ONLINE_TEST": "Online Test",
  },
  ELIGIBILITY_CRITERIA_TYPES: {
    "X_SCORE": {
      label: "Xth Score",
      operators: [">"]
    },
    "XII_SCORE": {
      label: "XIIth Score",
      operators: [">"]
    },
    "GRAD_SCORE": {
      label: "Graduation Score",
      operators: [">"]
    },
    "PG_SCORE": {
      label: "Post Graduation Score",
      operators: [">"]
    },
    "GENDER": {
      label : "Gender",
      operators : ["OR"],
      listId : "gender_type",
      name: "gender_name",
      additional: true
    },
    "AGE": {
      label: "Age",
      operators: [">", "<"],
      additional: true
    },
    "CATEGORY": {
      label : "Category",
      operators : ["OR"],
      listId : "type",
      name: "name",
      additional: true
    },
    "CURR_BACKLOG": {
      label : "Current Backlog",
      operators : ["<","=="],
      placeholder : "Enter the number of backlogs here",
      type : "number",
      additional: true
    },
    "WORK_EX_MONTHS": {
      label: "Work Experience(Months)",
      operators: [">", "<"],
      additional: true
    },
    "VISION_ISSUES": {
      label : "Vision Related Issue",
      operators : ["OR"],
      listId : "type",
      name: "name",
      additional: true
    },
    // "WORK_EX_SECTOR": {
    //   label: "Work Experience - Sector",
    //   operators: ["OR"],
    //   listId: "sector_id",
    //   name: "sector_name"
    // }
  },
  ELIGIBILITY_OPERATOR: {
    ">": ">=",
    "<": "<=",
    "==": "Equals",
    "range": "Between",
    "OR": "OR",
    "AND": "AND"
  },
  ANSWER_TYPES: {
    "text": "Text",
    "number": "Number",
    "file": "File upload"
  },
  PROCESS_TYPES: {
    "FINALS": "Final Placements",
    "INTERNSHIP": "Internships"
  },
  JOB_STATUS: {
    0: {
      "label": "Not Open"
    },
    1: {
      "label": "Opened"
    },
    2: {
      "label": "Opened"
    }
  },
  ACADEMIC_DETAIL : {
    Xth : "X",
    XIth : "X",
    XIIth : "XII",
    Graduation : "GRADUATION"
  },
  ELIGIBILITY_STATUS: {
    NOT_ELIGIBLE: 0,
    ELIGIBLE: 1
  },
  STUDENT_APPLICATION_STATUS: {
    APPLIED: 2
  }
}
