import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-dates/esm/components/SingleDatePicker';
import RangePicker from 'react-dates/esm/components/DateRangePicker';
import 'react-dates/initialize';

const returnYears = () => {
  let years = []
  for (let i = moment().year() - 50; i <= moment().year(); i++) {
    years.push(<option key={i} value={i}>{i}</option>);
  }
  return years;
}

export class SingleDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <DatePicker
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={1}
        date={this.props.value ? moment(this.props.value) : null}
        onDateChange={this.props.onDateChange}
        focused={this.state.focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
        id={this.props.id}
        noBorder={true}
        block={true}
        readOnly={true}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <select
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}
              >
                {moment.months().map((label, value) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
            <div>
              <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {returnYears()}
              </select>
            </div>
          </div>}
        {...this.props.pickerProps} />
    )
  }
}

export class DateRangePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  }
  render() {
    return (
      <RangePicker
        isOutsideRange={() => false}        
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        startDate={this.props.startDate ? moment(this.props.startDate) : null}
        startDateId={this.props.startDateId}
        endDate={this.props.endDate ? moment(this.props.endDate) : null}
        endDateId={this.props.endDateId}
        onDatesChange={this.props.onDatesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        noBorder={true}
        readOnly={true}
        block={true}
        {...this.props.pickerProps}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <select
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}
              >
                {moment.months().map((label, value) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
            <div>
              <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {returnYears()}
              </select>
            </div>
          </div>}
      />
    )
  }
}