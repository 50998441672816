import React,{Component} from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Auth } from '../../../actions/Auth';


export default class CreatePost extends Component {

  constructor(props){
    super(props)
  }

  createPost= async(e)=>{
    e.preventDefault();
    let {selectedColleges} =  this.props
    let collegeInfo = [];
    selectedColleges.forEach(college => {
      let targetColleges = {};
      targetColleges["university_id"] = college.university_id;
      targetColleges["college_id"] = college.college_id;
      targetColleges["campus_id"] = college.campus_id;
      targetColleges["campus_name"] = college.campus_name;
      collegeInfo.push(targetColleges)
    });

    let {post_title, post_content } = e.target
    let postParams = {
      post_title:post_title.value,
      post_content:post_content.value,
      target_audience:collegeInfo
    }

    try {
      let apiRespCreatePost =  await Auth.Recruiter.createPost(postParams)
    } catch (e) {
        console.log(e)
    }


  }


  render() {

    return (


          <div className="create-post">
            <Row>
                <Col>
                  <Card>
                      <CardBody>
                        <form onSubmit={this.createPost}>
                          <Row>
                            <Col xs={12} className="pb-3">
                              <input type="text" className="w-100 r-inputfield" name="post_title" placeholder="Enter post titile here"/>
                            </Col>
                            <Col xs={12}>
                              <textarea className="w-100" name="post_content" rows="7"/>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <button type="submit" color="dark" className="r-btn--no-radius r-text--sm r-btn r-btn--dark">Save</button>
                              <button className="r-btn">Cancel</button>
                            </Col>
                            <Col xs={6} className="text-right">
                              <button color="dark" className="r-btn--no-radius r-text--sm r-btn r-btn--dark">Upload file</button>
                            </Col>
                          </Row>
                        </form>
                      </CardBody>
                  </Card>
                </Col>
            </Row>
          </div>

    )
  }
}
