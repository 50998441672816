import React, { Component } from 'react';
import Button from './Button';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import Fade from 'reactstrap/lib/Fade';
import { setSuccess } from '../../actions/action-creators';
class SuccessComponent extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.success.showBar === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 2500)
		}
	}

	onClose = () => {
		this.props.setSuccess({ showBar: false })
	}

	render() {
		let { success } = this.props
		return (
			<Fade in={!!success.showBar} unmountOnExit={true} className="success-bar">
				<p className="d-flex py-4 r-subheading-3 m-0 justify-content-center"><i className="icon-success"></i>{success.message || "Successful"}</p>
				<Button onClick={this.onClose} className="close-bn"><i className="fi flaticon-close" ></i></Button>
			</Fade>
		)
	}
}
const mapStateToProps = state => ({
	...state
})
const mapDispatchToProps = dispatch => ({
	setSuccess: (error) => dispatch(setSuccess(error))
})
export const Successbar = withRouter(connect(mapStateToProps, mapDispatchToProps)(SuccessComponent));
