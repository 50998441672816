import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Card, CardHeader, CardBody } from 'reactstrap';
import ProgressBarCircle from '../../global/progressbar-circle';
import Button from '../../global/Button'


export default class PostStatus extends Component {

  constructor(props) {
    super(props)
  }


  render() { 
    let { collegeList } = this.props
    return (


      <div className="post-status">
        <Row>
          <Col>
            <Card style={{ height: 285, overflowY: "scroll" }}>
              <CardBody>
                <ul className="nav flex-column">
                  {collegeList.map((college, index) =>
                    <Row noGutters key={index} className="py-1 border-bottom align-items-center">
                      <Col xs={1}>
                        <input type="checkbox" />
                      </Col>
                      <Col xs={8}>{college.campus_name}</Col>
                      <Col xs={2} className="text-center d-flex">
                        <ProgressBarCircle value={50}/>
                      </Col>
                      <Col xs={1}><Button><i className="fi flaticon-bin"></i></Button></Col>
                    </Row>
                  )
                  }
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    )
  }
}
