import React,{Component} from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Auth } from '../../../actions/Auth';

export default class SelectedColleges extends Component {

    constructor(props){
        super(props)
    }
    

    render() {   

        let {selectedColleges} =  this.props

    return (

            <div className="selected-colleges">
                <div className="search-seprator">
                   <h2><span>OR</span></h2>
                </div>
                <div className="text-center">
                    <h1 className="r-subheading-1 my-3 r-text--blue-3">Continue with exiting colleges</h1>
                </div>
                <div className="colleges">
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col>                      
                                    <div className="filter-panel my-5">
                                        <ul className="nav">
                                            <li className="nav-item mr-3">
                                                <h3>Marketing Intern</h3>
                                            </li>            
                                        </ul>
                                    </div>
            
                                    <div className="filter-panel my-5">
                                        <ul className="nav">
                                        <li className="nav-item mr-3">
                                            <div className="r-tag r-tag--type-1">
                                                Full Time
                                            </div>    
                                        </li>
                                        <li className="nav-item mr-3">
                                            <div className="r-tag r-tag--type-1">
                                                50000-80000
                                            </div>    
                                        </li>
                                        <li className="nav-item mr-3">
                                            <div className="r-tag r-tag--type-1">
                                                Noida
                                            </div>    
                                        </li>                            
                                        </ul>
                                    </div>                
                                </Col>
                            </Row>  
                        </Col>
                    </Row>
                    
                    <Row>
                    {   
                        selectedColleges.map(college=>{
                            
                            return(

                                <Col  className="my-2" key={college.campus_id}>
                                    <div className="card shadow-sm college-card text-center p-2">
                                        <img src="/images/iim.png" className="college-logo" />
                                        <h3 className="r-heading-6">{college.campus_name}</h3>
                                        <p className="text-muted">Dummy text</p>
                                        <div className="r-stars">
                                            <span className="fi flaticon-star mx-1 active"></span>
                                            <span className="fi flaticon-star mx-1 active"></span>
                                            <span className="fi flaticon-star mx-1"></span>
                                        </div>
                                    </div>
                                </Col>
                            )

                        })
                    }
                    </Row>
                </div>
            </div>    
    
    )
  }

}
