import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import Redirect from 'react-router-dom/Redirect';
import Evaluate from '../views/evaluate';
import Application from '../views/application';

export const Evaluator = (props) => {
  return (
    <Switch>
      <Route path="/evalutate/applications" exact component={Evaluate} />
      <Route path="/evalutate/applications/:campus_job_id/round/:round_id" exact component={Application} />
      <Route path="/evaluate/campus/:campus_id/application/:application_id/job/:campus_job_id/round/:round_id" exact component={Application} />
      <Route render={()=><Redirect to="/evalutate/applications" />} />
    </Switch>
  )
}
