import React,{Component} from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Button from '../../global/Button';
import Aux from '../../global/AuxComp';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
// import ModalFooter from 'reactstrap/lib/ModalFooter';
import { getErrorMessage , getFullName } from '../../../actions';
import { setError, setLoading , setSuccess } from '../../../actions/action-creators';
import store from '../../../store';
import { Auth } from '../../../actions/Auth';
import Table from 'reactstrap/lib/Table';

class EvaluatorAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return(
      {
        allottedApplicants : [],
        allocateStudentsModal : false,
        viewAllocatedStudentsModal : false,
        selectButton : 1,
        studentList : [],
        applicationId : null,
        evaluatorId : null,
        evaluatorUserId : null,
        applicationData : []
      }
    )
  }

  assignStudentsForEvaluator = async(e, student)=>{
    let {evaluatorId , applicationData} = this.state ;
    store.dispatch(setLoading(true));
          try{
        if(e.target.checked==true){
          let studentParams = {
            student_id : student.student_id,
            application_id : student.application_id,
            user_id : evaluatorId,
            role_id : 3
          }
          let assignedApplicantsResp = await Auth.Evaluation.assignApplicantsToEvaluator(studentParams);
          // applicationData.forEach(application=>{
          //   if(application.application_id == student.application_id){
          //     application.evaluationDetails = assignedApplicantsResp.data.data[0] || '';
          //   }
          // })
      }
      else{
        let retainApplicantsResp = await Auth.Evaluation.removeApplicantsFromEvaluator({evaluation_id : student.evaluationDetails?student.evaluationDetails.evaluation_id : null})
        // applicationData.forEach(application=>{
        //   if(application.application_id == student.application_id){
        //     application.evaluationDetails = '';
        //   }
        // })
      }
      await this.getEvaluatorApplicants();
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
    this.setState({applicationData})
    store.dispatch(setLoading(false))
  }

  checkListDuplicates = (list , student_id)=>{
    let flag = 1;
    list.forEach(item=>{
      if(student_id == item){
        flag = 0;
      }
    })
    return flag;
  }

  getEvaluatorApplicants = async()=>{
    let {applicationData} = this.props;
    if(applicationData.length>0){
      try{
        let assignedApplicantsResp = await Auth.Evaluation.fetchEvaluatorApplicants({application_id : applicationData[0].application_id});
        let data = applicationData.map(application=>{
          application.evaluationDetails = assignedApplicantsResp.data.data.filter(evaluation=>{
            if(evaluation.student_id == application.student_id){
              return evaluation;
            }
          })[0] || '';
        })
      }
      catch(e){
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
      }
    }
    this.setState({applicationData});
  }

  componentDidMount(){
    this.getEvaluatorApplicants();
  }

  render(){
    let {applicationData ,  allottedApplicants , allocateStudentsModal , selectButton  , viewAllocatedStudentsModal , evaluatorUserId} = this.state,
    {  evaluatorDetails} = this.props;
    return(
      <Aux>
        <Col className={"text-center p-3"}>
          <Row>
            <Col align="start">
              <button onClick={()=>this.setState({selectButton : 1})} className={"evaluator__dashboard-button pl-3 pr-3 r-subheading-3 r-text--grey-4 "+(selectButton==1?"active":null)}>Overview</button>
              {/*<button onClick={()=>this.setState({selectButton : 2})} className={"evaluator__dashboard-button pl-3 pr-3 r-subheading-3 r-text--grey-4 "+(selectButton==2?"active":null)}> Shortlisting</button>*/}
            </Col>
          </Row>
          {selectButton==1?
            <Aux>
              <Row className="border-bottom border-secondary m-3 py-3">
                <Col><h1 className="r-subheading-3 d-inline-block">Evaluator</h1><Button className="r-text--sm" color="link-blue ml-2"><u>Add Evaluator</u></Button></Col>
                <Col><h1 className="r-subheading-3">Alloted Applicants</h1></Col>
                {/*<Col><h1 className="r-subheading-3">Shortlisted</h1></Col>*/}
              </Row>
              {evaluatorDetails.map(evaluator=>{
                if(Object.keys(evaluator.accessDetails).length>0){
                  return(
                    <Row className="border-bottom m-3 py-3" key={evaluator.user_id}>
                      <Col>{getFullName(evaluator.userDetails)}</Col>
                      <Col>
                        <Button color="link-blue" onClick={()=>this.setState({viewAllocatedStudentsModal : true , evaluatorUserId : evaluator.user_id })}>{applicationData.filter(student=>{
                          if(student.evaluationDetails && student.evaluationDetails.user_id == evaluator.user_id){
                            return student
                          }
                        }).length+" " || "0 "}</Button>
                        <Button color="link-blue" onClick={()=>this.setState({allocateStudentsModal : true , evaluatorId : evaluator.user_id})}> (Allocate)</Button>
                      </Col>
                      {/*<Col><Button color="link-blue">{evaluator.Shortlisted}</Button></Col>*/}
                    </Row>
                  )
                }
              })
            }
          </Aux> : null}
        </Col>
        <Modal isOpen={allocateStudentsModal} zIndex="99999"  toggle={()=>this.setState({allocateStudentsModal : false})} >
          <ModalHeader toggle={()=>this.setState({allocateStudentsModal : false})}>
            <span className="r-subheading-3">Students List</span>
          </ModalHeader>
          <ModalBody className="text-center">
            <Table className="border">
              <thead className="r-bg--blue-2">
                <tr>
                  <th>Select</th>
                  <th>Roll No.</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
              {applicationData.map(student=>{
                return(
                  <tr key={student.student.student_id}>
                    <td>
                      <input type="checkbox" onChange={(e)=>this.assignStudentsForEvaluator(e,student)} name={student}    checked={student.evaluationDetails? true : false} />
                    </td>
                    <td>{student.student.roll_no}</td>
                    <td>{getFullName(student.student)}</td>
                    <td>{student.student.email}</td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
            {/*<Row>
              <Col align="end">
                <Button color="dark" onClick={()=>this.handleAllocateStudent()}>Save</Button>
              </Col>
            </Row>*/}
          </ModalBody>
        </Modal>

        <Modal isOpen={viewAllocatedStudentsModal} zIndex="99999"  toggle={()=>this.setState({viewAllocatedStudentsModal : false})} >
          <ModalHeader toggle={()=>this.setState({viewAllocatedStudentsModal : false})}>
            <span className="r-subheading-3">Students List</span>
          </ModalHeader>
          <ModalBody className="text-center">
            <Table className="border">
              <thead className="r-bg--blue-2">
                <tr>
                  <th>Roll No.</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
              {applicationData.map(student=>{
                console.log("student ================" , student);
                if(student.evaluationDetails && student.evaluationDetails.user_id == evaluatorUserId){
                  return(
                    <tr key={student.student.student_id}>
                      <td>{student.student.roll_no}</td>
                      <td>{getFullName(student.student)}</td>
                      <td>{student.student.email}</td>
                    </tr>
                  )
                }
              })}
              {applicationData.length==0 ?
                    <tr key={1}>
                      <td colSpan={3} key={1}>
                        <span className="r-text--red-1">*No students found</span>
                      </td>
                    </tr> : null}
              </tbody>
            </Table>
            {/*<Row>
              <Col align="end">
                <Button color="dark" onClick={()=>this.handleAllocateStudent()}>Save</Button>
              </Col>
            </Row>*/}
          </ModalBody>
        </Modal>
      </Aux>
    )
  }
}
export default EvaluatorAllocation;
