import React, { Component, createRef } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Aux from '../../global/AuxComp';
import { CONSTANTS } from '../../../constants';
import { Switch } from '../../global/Forms/ToggleSwitch';
import store from '../../../store';
import { setSuccess, setError, setLoading } from '../../../actions/action-creators';
import { Auth } from '../../../actions/Auth';
import { getErrorMessage } from '../../../actions';
import { Timer } from '../../global/timer';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalBody from 'reactstrap/lib/ModalBody';
import Button from '../../global/Button';

export default class Institute extends Component {

  state = {
    isOpenInviteApplication : false,
    studentFilter: false
  }

  toggleApplicationInvite = async (status, e) => {
    if(e) e.stopPropagation();
    let { jobData } = this.props;
    if(jobData.job_status) return;
    if(status){
      store.dispatch(setLoading(true))
      try{
        // var resp = await Auth.General.getAdjecentCampus({ campus_id: jobData.campus_id })
        // var campusList = resp.data.data;
        // campusList = await Promise.all(campusList.map(async campus => {
        //   let processResp = await Auth.Process.getCampusAllProcess({campus_id: campus.campus_id});
        //   campus.processList = processResp.data.data.filter(process => process.archive_status === 0);
        //   return campus;
        // }))
        // this.setState({isOpenInviteApplication : { campusList }})
        let processResp = await Auth.Process.getCampusAllProcess({campus_id: jobData.campus_id});
        let processList = processResp.data.data.filter(process => process.archive_status === 0);
        this.setState({isOpenInviteApplication : { processList }})
      }
      catch(e){
        console.log(e)
        store.dispatch(setLoading(false))
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      }
      store.dispatch(setLoading(false))
    }
    else{
      this.setState({isOpenInviteApplication : false})
    }
  }

  onSelectInviteProcess = (campus_id, process_id) => {
    let { isOpenInviteApplication } = this.state;
    isOpenInviteApplication.selectedProcess = isOpenInviteApplication.selectedProcess || {};
    isOpenInviteApplication.selectedProcess[campus_id] = isOpenInviteApplication.selectedProcess[campus_id] || [];
    let processIndex = isOpenInviteApplication.selectedProcess[campus_id].indexOf(process_id);
    if(processIndex === -1) {
      isOpenInviteApplication.selectedProcess[campus_id].push(process_id)
    }
    else{
      isOpenInviteApplication.selectedProcess[campus_id].splice(processIndex, 1);
    }
    this.fetchBatchDepartmentByProcess(isOpenInviteApplication.selectedProcess[campus_id]);
    this.setState({ isOpenInviteApplication });
  }

  fetchBatchDepartmentByProcess = async (process_id) => {
    if(!process_id.length) return this.setState({studentFilter : false})
    store.dispatch(setLoading(true))
    try {
      let resp = await Auth.Process.getCampusProcessStudents({process_id});
      var data = resp.data.reduce((prev, curr) => {
        if(prev.batchList.findIndex(o => o.batch_id === curr.batch_id) === -1) prev.batchList.push({batch_id: curr.batch_id, batch_name: curr.batch_name});
        if(prev.departmentList.findIndex(o => o.degree_department_id === curr.degree_department_id) === -1) prev.departmentList.push({batch_id: curr.batch_id, degree_name: curr.campus_degree_name, campus_degree_id: curr.campus_degree_id, degree_department_id: curr.degree_department_id, department_name: curr.department_name});
        return prev;
      }, {batchList: [], departmentList: [] });
      this.setState({studentFilter: data});
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }


  sendApplicationInvite = async(e) => {
    e.stopPropagation();
    let { isOpenInviteApplication } = this.state;
    let { campus_job_id } = this.props.jobData;
    store.dispatch(setLoading(true))
    try {
      await this.setStudentFilterEligibility();
      let resp = await Auth.Process.addJobInviteReference({campus_job_id, selected_campus_process : isOpenInviteApplication.selectedProcess || {}});
      // var campusJobId = Object.keys(resp.data.data.campusJobProcess);
      // await Promise.all(campusJobId.map(async id => {
      //   await Auth.Process.updateCampusJob({ campus_job_id : id, job_status: 1, other_campus_interest: isOpenInviteApplication.other_campus_interest ? '1' : '0'  })
      // }))
      await Auth.Process.updateCampusJob({ campus_job_id, job_status: 1, other_campus_interest: isOpenInviteApplication.other_campus_interest ? '1' : '0'  })

      this.setState({isOpenInviteApplication : false})
      await this.props.getCampusJobs();
      store.dispatch(setLoading(false))
      store.dispatch(setSuccess({ showBar: true, message: "Application invited successfully" }))
    } catch (e) {
      console.log(e)
      store.dispatch(setLoading(false))
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  // showStudentFilter = async () => {
  //   let { studentFilter } = this.state;
  //   store.dispatch(setLoading(true))
  //   if(studentFilter) studentFilter = false;
  //   else{
  //     studentFilter = {};
  //     try{
  //       var departmentResp = await Auth.General.getCampusDegreeDepartment({campus_id: this.props.jobData.campus_id});
  //       studentFilter.departmentList = departmentResp.data.reduce((prev, curr) => {
  //         curr.departments = curr.departments.map(dep => {
  //           dep.campus_degree_id = curr.campus_degree_id;
  //           dep.degree_name = curr.degree_name;
  //           return dep;
  //         })
  //         prev = prev.concat(curr.departments);
  //         return prev;
  //       }, []);
  //       studentFilter.selectedDepartments = studentFilter.departmentList.map(dep => dep.degree_department_id);
  //       if(departmentResp.data.length){
  //         var batchResp = await Auth.General.getCampusBatches({campus_degree_id: departmentResp.data.map(o => o.campus_degree_id)});
  //         studentFilter.batchList = batchResp.data;
  //         studentFilter.selectedBatch = batchResp.data.map(batch => batch.batch_id)
  //       }

  //     }
  //     catch(e){
  //       store.dispatch(setLoading(false))
  //       store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
  //     }
  //   }
  //   store.dispatch(setLoading(false))
  //   this.setState({studentFilter})
  // }

  onSelectFilters = (id, key) => {
    let { studentFilter } = this.state;
    studentFilter[key] = studentFilter[key] || [];
    var index = studentFilter[key].indexOf(id);
    if(index === -1) studentFilter[key].push(id);
    else studentFilter[key].splice(index, 1);
    if(key === "selectedBatch"){
      var selectDepartmentId = studentFilter.departmentList.filter(o => studentFilter[key].indexOf(o.batch_id) !== -1).map(o => o.degree_department_id);
      
        studentFilter.selectedDepartments = selectDepartmentId;
      
    }
    this.setState({studentFilter});
  }

  setStudentFilterEligibility = async () => {
    let { studentFilter } = this.state;
    if(!studentFilter) return;
    let { batchList = [], selectedBatch = [], departmentList = [], selectedDepartments = [] } = studentFilter;
    if(batchList.length === selectedBatch.length && departmentList.length === selectedDepartments.length) return;
    var value = [];
    var value_type = [];
    departmentList = departmentList.filter(department => selectedDepartments.indexOf(department.degree_department_id) !== -1)
    batchList.filter(batch => selectedBatch.indexOf(batch.batch_id) !== -1).map(batch => {
      departmentList.filter(department => department.batch_id == batch.batch_id).map(department => {
        value.push(batch.batch_id+"-"+department.degree_department_id);
        value_type.push(batch.batch_name+" - "+department.department_name)
      })
      // departmentList.filter(department => department.campus_degree_id == batch.campus_degree_id).map(department => {
      //   value.push(batch.batch_id+"-"+department.degree_department_id);
      //   value_type.push(batch.batch_name+" - "+department.department_name)
      // })
    });
    var body = {
      job_template_id : this.props.jobData.job_template_id,
      campus_id: this.props.jobData.campus_id,
      operator: "OR",
      value : value.join(","),
      value_type: value_type.join(", "),
      type: "DEPARTMENT"
    }
    await Auth.JobTemplate.createEligibility(body)
  }

  render() {
    let { isOpenInviteApplication, studentFilter } = this.state;
    let { jobData } = this.props,
      status = CONSTANTS.JOB_STATUS[jobData.job_status] || {}
    return (
      <Row >
        <Col onClick={this.props.onClick} className="selectable" xs={{ offset: 7, size: 5 }}>
          <div className="mb-3">
            <Row className="no-gutters r-text--light r-bg--blue-3 ">
              <Col xs={6}>
                <div className="p-2 h-100 border-right">
                  <Row noGutters>
                    <Col xs={9}>
                      <span className="r-text--sm d-block text-truncate">{jobData.role}</span>
                    </Col>
                    {jobData.receive_status===1 && <Col xs="auto">
                      <span className="r-circle mr-2 r-circle--filled">{jobData.applicant_counts.eligible}</span>
                      <span className="r-circle">{jobData.applicant_counts.applied}</span>
                    </Col>}
                  </Row>
                </div>
              </Col>
              <Col xs={6}>
                <div className="p-2">
                  <Row noGutters>
                    <Col xs={6}>
                      <Row noGutters className="align-items-center">
                        <Col xs="auto">
                          <Switch handleToggle={(e) => this.toggleApplicationInvite(true, e)} isOn={jobData.job_status > 0}></Switch>
                        </Col>
                        <Col>
                          <span className="pl-2 r-text--sm">{status.label || ""}</span>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col xs={6} className="text-right">
                      <span className="text-truncate r-text--sm">
                        <Timer deadline={jobData.deadline}/>
                      </span>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          {!!isOpenInviteApplication &&
          <Modal size="md" centered={true} isOpen={true}  toggle={() => this.toggleApplicationInvite(false)}>
            <ModalHeader toggle={() => this.toggleApplicationInvite(false)}>Application Invite</ModalHeader>
            <ModalBody>
              <div className="mb-4">Select the drives from Active Placement Drives of Campus in which you prefer to invite the application</div>
              {/* {
                isOpenInviteApplication.campusList && isOpenInviteApplication.campusList.map(campus => 
                  <>
                    <h5 className="border-bottom my-2">{campus.name}</h5>
                    {campus.processList.length ? 
                    <ul className="list-unstyled px-4 py-2">
                    {
                      campus.processList.map(process =>
                        <li>
                          <input type="checkbox" onChange={() => this.onSelectInviteProcess(campus.campus_id, process.process_id)} checked={isOpenInviteApplication.selectedProcess && isOpenInviteApplication.selectedProcess[campus.campus_id] && isOpenInviteApplication.selectedProcess[campus.campus_id].indexOf(process.process_id) !== -1} />
                          {' '}
                          {process.process_name}
                        </li>
                      )
                    }
                    </ul>
                    :
                    <em className="text-secondary r-text--sm">No Active Placement Drive in this Campus</em>
                    }
                  </>
                )
              } */}
              
                <Row>
                {isOpenInviteApplication.processList && isOpenInviteApplication.processList.map((process, i) => 
                    <Col key={i} xs={6}>
                      <input type="checkbox" onChange={() => this.onSelectInviteProcess(jobData.campus_id, process.process_id)} checked={isOpenInviteApplication.selectedProcess && isOpenInviteApplication.selectedProcess[jobData.campus_id] && isOpenInviteApplication.selectedProcess[jobData.campus_id].indexOf(process.process_id) !== -1} />
                      {' '}
                      {process.process_name}
                    </Col>
                  )
                }
                </Row>

                
                {/* <Button className="mt-3" onClick={this.showStudentFilter} color="blue">Student Filter</Button> */}

                {studentFilter && <Row className="my-3 py-3">
                  <Col xs={4} className="border-right">
                    <h5>Batches</h5>
                    <Row>
                      {
                        studentFilter.batchList && studentFilter.batchList.map((batch, i) => 
                          <Col key={i} xs={12}>
                            <input type="checkbox" onChange={() => this.onSelectFilters(batch.batch_id, "selectedBatch")} checked={studentFilter.selectedBatch && studentFilter.selectedBatch.indexOf(batch.batch_id) !== -1} />
                            {' '}
                            {batch.batch_name}
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                  <Col xs={8}>
                    <h5>Departments</h5>
                    <Row>
                      {
                        studentFilter.departmentList && studentFilter.departmentList.map((department, i) => 
                          <Col key={i} xs={12}>
                            <input type="checkbox" onChange={() => this.onSelectFilters(department.degree_department_id, "selectedDepartments")} checked={studentFilter.selectedDepartments && studentFilter.selectedDepartments.indexOf(department.degree_department_id) !== -1} />
                            {' '}
                            {department.degree_name} - {department.department_name}
                          </Col>
                        )
                      }
                    </Row>
                  </Col>
                </Row>}

                <p className="mt-4"><input type="checkbox" onChange={() => {isOpenInviteApplication.other_campus_interest = !isOpenInviteApplication.other_campus_interest; this.setState({isOpenInviteApplication})}} checked={isOpenInviteApplication.other_campus_interest} /> Do you want to open this opportunity to other campuses too</p>

            </ModalBody>
            <ModalFooter>
                <Button color="blue-3" onClick={this.sendApplicationInvite}>Invite</Button>
            </ModalFooter>
          </Modal>
          }
        </Col>
      </Row>
    )
  }
}
