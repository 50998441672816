import React from 'react';
export const Loader = (props) => {
	return (
			<div className={"r-loader" + (props.show?"":" invisible")}>
				<div className="position-relative">
					<img className="r-loader__cap" height="auto" src="/images/loader-cap.png" alt=""/>
					<img className="r-loader__reculta" height="auto" src="/images/loader-reculta.png" alt=""/>
					{/* <p className="d-block blink-loader r-text--grey">Wait until we cook something for you!</p> */}
				</div>
			</div>
	)
}

