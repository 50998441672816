import React, { Component } from "react";
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Table from 'reactstrap/lib/Table';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import Button from '../../global/Button';
import { Switch } from '../../global/Forms/ToggleSwitch';
import { InputField } from '../../global/Forms';
import { CONSTANTS } from '../../../constants';
import { Select, MultiSelectList } from '../../global/Forms/Select';
import { Auth } from "../../../actions/Auth";
import Aux from "../../global/AuxComp";
import { SingleDatePicker } from "../../global/Forms/DatePicker";
import TimePicker from 'rc-time-picker';
import moment from "moment";
import { Confirmation } from '../../global/confirmation';
import store from "../../../store";
import { setLoading, setError, setSuccess } from "../../../actions/action-creators";
import { getErrorMessage, getFiles, uploadFile } from "../../../actions";
import { Message } from "../../../constants/messages";

export class JobModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }
  
  init_eligibility_criteria = {
    job_template_id: this.props.job.job_template_id,
    type: Object.keys(CONSTANTS.ELIGIBILITY_CRITERIA)[0],
    operator: "==",
    value: "",
  }
  init_application_question = {
    job_template_id: this.props.job.job_template_id,
    question_heading: "",
    question_content: "",
    answer_type: "text",
    is_mandatory: 0,
    is_config: '0',
    answer_type_data: {}
  }
  getInitialState = () => {
    return {
      eligibility_criteria: { ...this.init_eligibility_criteria },
      round_info: { round_type: "GD", is_hotlisting: '0' },
      application_question: { ...this.init_application_question },
      ctc_type: this.props.job.ctc_type,
      addedCriteria: [],
      addedrounds: [],
      deletedRounds: [],
      deletedCriteria: [],
      addedQuestions: [],
      deletedQuestions: [],
      deadline: this.props.job.deadline ? moment(this.props.job.deadline) : moment(),
      docJD: null,
      docPPT: null,
      docOther: null,
      files: {
        jd: null,
        ppt: null,
        other: null
      },
      deleteDocs: [],
      eligibilityOptionsList: {},
      isSure: false,
      confimationParam: null,
      applicationEligibility: {},
      application_eligibility : [],
      jobRounds : [],
      applicationQuestion : [],
      isShowAdditionalEligibility : false
    }
  }

  getEligibilitySector = async () => {
    var sector = [],
      { eligibilityOptionsList } = this.state
    try {
      sector = await Auth.General.getSectors();
      sector = sector.data
    }
    catch (e) {
      sector = []
    }
    eligibilityOptionsList["WORK_EX_SECTOR"] = sector;

    eligibilityOptionsList["GENDER"] = [
      {gender_type: "Male", gender_name : "Male"},
      {gender_type: "Female", gender_name : "Female"}
    ];
    eligibilityOptionsList["CATEGORY"] = [
      {type: "SC", name : "SC"},
      {type: "ST", name : "ST"},
      {type: "OBC", name : "OBC"},
      {type: "General", name : "General"}
    ];
    eligibilityOptionsList["VISION_ISSUES"] = [
      {type: "0", name : "No"},
      {type: "1", name : "Yes"}
    ];

    this.setState({ eligibilityOptionsList })
  }

  handleFileChange = (e) => {
    let { files } = this.state
    files[e.target.name] = e.target.files[0];
    this.setState({ files })
  }

  removeSelectedFile = (prop) => {
    let { files } = this.state;
    files[prop] = null;
    this.setState({ files })
  }

  deleteExistingFile = (id, prop) => {
    let { deleteDocs } = this.state;
    deleteDocs.push(id);
    this.setState({ deleteDocs, [prop]: null })
  }

  getApplicationDocs = async () => {
    let { applicationDocs } = this.state,
      { docJD, docPPT, docOther } = this.state,
      fileIds = [], files = [];
    if (applicationDocs) {
      applicationDocs.forEach(doc => {
        if (!isNaN(parseInt(doc.file_id))) fileIds.push(doc.file_id)
        if (doc.doc_type === "JD") docJD = doc
        else if (doc.doc_type === "PPT") docPPT = doc
        else if (doc.doc_type === "Others") docOther = doc
      })
      if (fileIds.length)
        files = await getFiles(fileIds)
      if (docJD)
        docJD = Object.assign({}, docJD, { file_detail: files[docJD.file_id] ? files[docJD.file_id][0] : {} })
      if (docPPT)
        docPPT = Object.assign({}, docPPT, { file_detail: files[docPPT.file_id] ? files[docPPT.file_id][0] : {} })
      if (docOther && files[docOther.file_id])
        docOther = Object.assign({}, docOther, { file_detail: files[docOther.file_id] ? files[docOther.file_id][0] : {} })
      this.setState({ docJD, docPPT, docOther })
    }
  }

  init = async () => {
    store.dispatch(setLoading(true))
    await this.setState(this.getInitialState())
    if(typeof this.props.job.job_template_id === 'number'){
      var opts = {
        job_template_id : this.props.job.job_template_id
      }
      var jobExtraData = await Promise.all([Auth.JobTemplate.fetchDocs(opts), Auth.JobTemplate.fetchRounds(opts), Auth.JobTemplate.fetchEligibility(opts), Auth.JobTemplate.fetchQuestion(opts)])      
      var applicationDocs = jobExtraData[0].data.data;
      var application_eligibility =  jobExtraData[2].data.data;
      var applicationEligibility = jobExtraData[2].data.data.reduce((prev, current) => {        
        prev[current.type] = {...current};
        return prev
      }, {});
      var jobRounds = jobExtraData[1].data.data;
      var applicationQuestion = jobExtraData[3].data.data;
      this.setState({applicationDocs, applicationEligibility,application_eligibility, jobRounds, applicationQuestion})
      this.getApplicationDocs()
    }
    this.getEligibilitySector();
    store.dispatch(setLoading(false))
  }

  handleStateChange = (e) => {
    let state = this.state[e.target.dataset.state];
    if (state) {
      state[e.target.name] = e.target.value;
      if (e.target.name === "value[]") {
        var options = [...e.target.options]
        var selectedValue = options.reduce((prev, data) => { if (data.selected) return [...prev, data.value]; else return prev }, [])
        selectedValue = selectedValue.join(",")
        state.value = selectedValue;
      }
      this.setState({
        [state]: state
      })
    }
  }

  toggleEligibility = (data) => {
    let { applicationEligibility, application_eligibility } = this.state
    if (applicationEligibility[data.type]) {
      delete applicationEligibility[data.type]
    } else {
      let prev = application_eligibility.filter(ec => ec.type === data.type)
      applicationEligibility[data.type] = Object.assign({}, {
        type: data.type,
        operator: data.operator || data.operators[0],
        value: ""
      }, prev[0])
    }
    this.setState({ applicationEligibility })
  }

  editEligibility = (e, type) => {
    let { applicationEligibility, eligibilityOptionsList } = this.state,
      ecData = applicationEligibility[type],
      { name, value } = e.target,
      options = eligibilityOptionsList[type];
    if (ecData) {
      if (ecData.operator === "OR") {
        let values = ecData.value !== "" ? ecData.value.split(",") : [],
        index = values.indexOf(value);
        if (index > -1){
          values.splice(index, 1)
        }
        else{
          values.push(value)
        }
        let valueTypes = options.filter(opt=>values.indexOf(opt[CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].listId].toString())>-1).map(opt=>opt[CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].name])
        ecData["value_type"] = valueTypes.join(",")
        ecData[name] = values.join(",")
      }
      else
        ecData[name] = value
    }
    this.setState({ applicationEligibility })
  }

  handleApplicationSubmit = async (e) => {
    let { role, vacancy, location, description, ctc_max, ctc_min, ctc_type, ctc_currency, stipend_value, instruction, designation } = e.target,
      { job_template_id} = this.props.job,
      { addedrounds, deletedRounds, deletedCriteria, deadline, deleteDocs, addedQuestions, deletedQuestions, applicationEligibility, application_eligibility, files } = this.state,
      uploadedDocs = [], jobResp = {};

    deletedCriteria = application_eligibility.filter(ec => !applicationEligibility[ec.type]).map(ec => ec.job_eligibility_id)
    let formData = {
      role : role.value,
      vacancy: vacancy.value,
      location: location.value,
      ctc_min: ctc_min.value || '0',
      ctc_max: ctc_type.value === "strict" ? ctc_min.value || '0' : ctc_max.value || '0',
      ctc_type: ctc_type.value,
      ctc_currency: ctc_currency.value,
      stipend_value: stipend_value ? (stipend_value.value || 0) : 0,
      description: description.value,
      instruction: instruction.value,
      deadline: deadline.utc(false).format(CONSTANTS.DATE_FORMAT),
      designation: designation.value
    }

    store.dispatch(setLoading(true))
    var isEdit = typeof job_template_id === "number";
    try {
      if (!isEdit) {
        jobResp = await Auth.JobTemplate.create(formData)
        job_template_id = jobResp.data.data[0].job_template_id
      }
      else {
        jobResp = await Auth.JobTemplate.update({...formData, job_template_id})
      }
      if (typeof job_template_id === "number") {
        let addedList = await Promise.all(Object.keys(applicationEligibility).map(async (ec) => {
          applicationEligibility[ec].job_template_id = job_template_id
          let resp = await Auth.JobTemplate.createEligibility(applicationEligibility[ec])
          return resp.data.data[0];
        }))
        await Promise.all(addedrounds.map(async (rounds) => {
          rounds.job_template_id = job_template_id;
          rounds.hotlisting_deadline = rounds.hotlisting_deadline ? moment(rounds.hotlisting_deadline).utc(false).format("YYYY-MM-DD") : undefined;
          let resp = await Auth.JobTemplate.createRounds(rounds);
          return resp.data.data[0];
        }))
        if (deletedRounds.length > 0)
          await Auth.JobTemplate.inactiveRounds({ job_round_id: deletedRounds })
        if (deletedCriteria.length > 0)
          await Auth.JobTemplate.inactiveEligibility({ job_eligibility_id: deletedCriteria, job_template_id })
        await Promise.all(addedQuestions.map(async (q) => {
          q.job_template_id = job_template_id
          let resp = await Auth.JobTemplate.createQuestion(q)
          return resp.data.data[0];
        }))
        if (deletedQuestions.length > 0)
          await Auth.JobTemplate.inactiveQuestion({ job_question_id: deletedQuestions })
        if (deleteDocs.length > 0)
          await Auth.JobTemplate.inactiveDocs(deleteDocs);
        if (files.jd) {
          let fileJD = await uploadFile(files.jd, `application/${job_template_id}/docs`)
          let respJD = await Auth.JobTemplate.createDocs({ job_template_id, doc_type: "JD", file_id: fileJD.file_id })
          uploadedDocs.push(respJD.data.data[0])
        }
        if (files.ppt) {
          let filePPT = await uploadFile(files.ppt, `application/${job_template_id}/docs`)
          let respPPT = await Auth.JobTemplate.createDocs({ job_template_id, doc_type: "PPT", file_id: filePPT.file_id })
          uploadedDocs.push(respPPT.data.data[0])
        }
        if (files.other) {
          let fileOther = await uploadFile(files.other, `application/${job_template_id}/docs`)
          let respOther = await Auth.JobTemplate.createDocs({ job_template_id, doc_type: "Others", file_id: fileOther.file_id })
          uploadedDocs.push(respOther.data.data[0])
        }
        if(this.props.handleDataChange){
          this.props.handleDataChange({ ...jobResp.data.data[0] }, isEdit)
        }
        store.dispatch(setSuccess({ showBar: true, message: isEdit ? Message.SUCCESS.JOB.JOB_EDIT : Message.SUCCESS.JOB.JOB_CREATE}))
        this.props.toggleModal();
      }
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  addEC = () => {
    let { addedCriteria, eligibility_criteria } = this.state;

    if (eligibility_criteria.value !== undefined && eligibility_criteria.value !== null && eligibility_criteria.value !== '') {
      if (eligibility_criteria.operator === 'range') {
        eligibility_criteria.value1 = eligibility_criteria.value;
        eligibility_criteria.value = eligibility_criteria.value1 + "," + eligibility_criteria.value2;
      }
      addedCriteria.push({ ...eligibility_criteria })
      eligibility_criteria = { ...this.init_eligibility_criteria }
      this.setState({
        addedCriteria, eligibility_criteria
      })
    }
  }
  addRound = () => {
    let { addedrounds, round_info } = this.state;
    if(!round_info.round_name)
      return false;
    addedrounds.push({ ...round_info })
    this.setState({
      addedrounds, round_info: { round_name : "", round_type: "GD", is_hotlisting: '0' }
    })
  }

  deleteRound = (id, i) => {
    let { addedrounds, deletedRounds, jobRounds } = this.state;
    if (!id) {
      addedrounds.splice(i - (jobRounds.length + deletedRounds.length), 1)
      this.setState({ addedrounds })
    }
    else {
      deletedRounds.push(id)
      this.setState({ deletedRounds })
    }

  }
  deleteEC = (id, i) => {
    let { addedCriteria, deletedCriteria, application_eligibility } = this.state
    if (!id) {
      addedCriteria.splice(i - (application_eligibility.length + deletedCriteria.length), 1)
      this.setState({ addedCriteria })
    } else {
      deletedCriteria.push(id)
      this.setState({ deletedCriteria })
    }
  }

  addQuestion = () => {
    let { addedQuestions, application_question } = this.state;
    if (application_question.question_heading.trim() !== "") {
      application_question.answer_type_data = JSON.stringify(application_question.answer_type_data)
      addedQuestions.push({ ...application_question })
      this.setState({
        addedQuestions, application_question: { ...this.init_application_question }
      })
    }
  }

  deleteQuestion = (id, i) => {
    let { addedQuestions, deletedQuestions } = this.state,
      { application_question } = this.props.job;
    if (!id) {
      addedQuestions.splice(i - (application_question.length + deletedQuestions.length), 1)
      this.setState({ addedQuestions })
    } else {
      deletedQuestions.push(id)
      this.setState({ deletedQuestions })
    }
  }

  toggleConfirmation = () => {
    this.setState({ isSure: false })
  }

  render() {
    let { job, isView } = this.props,
      { files, eligibilityOptionsList, application_question,jobRounds, applicationQuestion, ctc_type, addedCriteria, docJD, docPPT, docOther, deletedCriteria, deadline, addedQuestions, deletedQuestions, isSure, confimationParam, round_info, addedrounds, deletedRounds, applicationEligibility } = this.state;
      
    return (
      <Aux>
        <Modal onOpened={this.init} zIndex="99999" size="md" isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
          <ModalBody>
            <form onSubmit={(e) => { e.preventDefault(); this.handleApplicationSubmit(e); }}>
              <Row>
                <Col xs={7}>
                  {/* <Row noGutters className="mb-3 mt-3">
                    <Col xs={12}>
                      <h3 className="r-heading-4"><span className="r-text--red-1">*</span>Application Deadline</h3>
                      <Row>
                        <Col xs={7}>
                          <SingleDatePicker
                            value={deadline}
                            onDateChange={(date) => this.setState({ deadline: date })}
                            id={"deadline_" + job.job_template_id} />
                        </Col>
                        <Col xs={5}>
                          <TimePicker
                            onChange={(date) => this.setState({ deadline: date })}
                            value={deadline}
                            format="h:mm a"
                            use12Hours
                            showSecond={false}
                            inputReadOnly />
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                </Col>
                <Col xs={5}>
                  <div className="px-4">
                    <h3 className="r-heading-4">Upload Documents</h3>
                    {docJD ? <div className="r-tag r-tag--type-1">
                      <a href={docJD.file_detail.url} target="_blank" rel="noopener noreferrer">Job Description</a> { !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.deleteExistingFile(docJD.job_docs_id, "docJD")}></Button> : null }
                    </div> : (files.jd ? <div className="text-truncate r-tag r-tag--type-1">
                      {files.jd.name}{ !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("jd")}></Button> : null }
                    </div> : ( !isView ? <Aux>
                        <label className="r-btn r-btn--blue text-center d-block" htmlFor={`application_${job.job_template_id}_jd`}><i className="mr-1 r-text--sm fi flaticon-plus-1" />Job Description</label>
                        <input name="jd" onChange={this.handleFileChange} id={`application_${job.job_template_id}_jd`} type="file" className="d-none" />
                      </Aux> : <br></br>))}

                    {docPPT ? <div className="r-tag r-tag--type-1">
                      <a href={docPPT.file_detail.url} target="_blank" rel="noopener noreferrer">PPT file</a>{ !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1"></Button> : null }
                    </div> : (files.ppt ? <div className="text-truncate r-tag r-tag--type-1">
                      {files.ppt.name}{ !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("ppt")}></Button> : null }
                    </div> : ( !isView ? <Aux>
                        <label htmlFor={`application_${job.job_template_id}_ppt`} className="r-btn r-btn--blue text-center d-block"><i className="mr-1 r-text--sm fi flaticon-plus-1" />PPT File</label>
                        <input name="ppt" onChange={this.handleFileChange} id={`application_${job.job_template_id}_ppt`} type="file" className="d-none" />
                      </Aux>: <br></br>))}

                    {docOther ? <div className="r-tag r-tag--type-1">
                      <a href={docOther.file_detail.url} target="_blank">Other Document</a> { !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1"></Button> : null }
                    </div> : (files.other ? <div className="text-truncate r-tag r-tag--type-1">
                        {files.other.name}{ !isView ? <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => this.removeSelectedFile("other")}></Button> : null }
                    </div> : ( !isView ? <Aux>
                        <label className="r-btn r-btn--blue text-center d-block" htmlFor={`application_${job.job_template_id}_other`}><i className="mr-1 r-text--sm fi flaticon-plus-1" />Other Document</label>
                        <input name="other" onChange={this.handleFileChange} id={`application_${job.job_template_id}_other`} type="file" className="d-none" />
                      </Aux>:<br></br>))}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={6}>
                  <h3 className="r-heading-4 mb-4">Job Description</h3>

                  <Row noGutters>
                    <Col xs={12}>

                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7"><span className="r-text--red-1">*</span>Role Name</label>
                          <InputField required className="w-100" name="role" type="text" defaultValue={job.role || ""} />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7">Designation</label>
                          <InputField className="w-100" name="designation" type="text" defaultValue={job.designation || ""} />
                        </Col>
                        <Col >
                          <label className="r-heading-7">Vacancy</label>
                            <InputField className="w-100" name="vacancy" type="text" defaultValue={job.vacancy || ""} />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col >
                          <label className="r-heading-7">Location</label>
                          <InputField className="w-100" name="location" type="text" defaultValue={job.location} />

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <h3 className="r-heading-4  mb-4">Offer Details</h3>
                  <Row className="mb-3">
                    <Col xs={6}>
                      <label className="r-heading-7">CTC Input Type</label>
                      <Select name="ctc_type" onChange={({ target }) => this.setState({ ctc_type: target.value })} defaultValue={job.ctc_type}>
                        <option value="strict">Strict</option>
                        <option value="range">Range</option>
                      </Select>
                    </Col>
                    <Col xs={6}>
                      <label className="r-heading-7">CTC Currency</label>
                      <Select name="ctc_currency" defaultValue={job.ctc_currency}>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                        <option value="AED">AED</option>
                        <option value="SAR">SAR</option>
                        <option value="BHD">BHD</option>
                        <option value="QAR">QAR</option>
                        <option value="OMR">OMR</option>
                        <option value="KWD">KWD</option>
                        <option value="SGD">SGD</option>
                        <option value="MYR">MYR</option>
                        <option value="HKD">HKD</option>
                      </Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={6}>
                      <label className="r-heading-7">CTC Amount{ctc_type === "range" && " Min"}</label>
                      <InputField className="w-100" name="ctc_min" type="text" defaultValue={job.ctc_min} />
                    </Col>
                    {ctc_type === "range" && <Col xs={6}>
                      <label className="r-heading-7">CTC Amount Max</label>
                      <InputField className="w-100" name="ctc_max" type="text" defaultValue={job.ctc_max} />
                    </Col>}
                    <Col xs={6}>
                      <label className="r-heading-7">Stipend Amount</label>
                      <InputField className="w-100" name="stipend_value" type="text" defaultValue={job.stipend_value} />
                    </Col>
                  </Row>

                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <label className="r-heading-7">Description</label>
                  <textarea className="r-inputfield w-100" rows={4} name="description" defaultValue={job.description} />
                </Col>

                <Col>
                  <label className="r-heading-7">Application Instructions</label>
                  <textarea className="r-inputfield w-100" rows={4} name="instruction" defaultValue={job.instruction} />
                </Col>
              </Row>


              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Application Rounds</h3>
              <Table className="processes__table__1">
                <tbody>
                  {
                    jobRounds.concat(addedrounds).filter(e => deletedRounds.indexOf(e.job_round_id) < 0).map((rounds, i) => {
                      return (
                        <tr key={i}>
                          <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                          <td>{rounds.round_name}</td>
                          <td>{CONSTANTS.ROUND_TYPES[rounds.round_type]}</td>
                          { !isView ? <td><Button type="button" className="p-0" onClick={() => this.deleteRound(rounds.job_round_id,i)}><i className="fi flaticon-bin" /></Button></td> : null }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              { !isView ? <Button type="button" id={`addRound_${job.job_template_id}`} className="r-text--sm" color="brandB">+ Add Round</Button> : null }
              { !isView ? <UncontrolledCollapse toggler={`#addRound_${job.job_template_id}`}>
                <Row className="mt-3 r-text--sm align-items-center">
                  <Col xs={4}>
                    <InputField className="w-100" name="round_name" data-state="round_info" value={round_info.round_name} onChange={this.handleStateChange} placeholder="Round Name" />
                  </Col>
                  <Col xs={4}>
                    <Select name="round_type" data-state="round_info" value={round_info.round_type} onChange={this.handleStateChange}>
                      {Object.keys(CONSTANTS.ROUND_TYPES).map(round => {
                        return <option key={round} value={round}>{CONSTANTS.ROUND_TYPES[round]}</option>
                      })}
                    </Select>
                  </Col>
                  <Col xs={2}>
                    <Button onClick={this.addRound} type="button" color="blue" className="r-text--blue-2">Add</Button>
                  </Col> 
                </Row>
              </UncontrolledCollapse>
              : null }

              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Eligibility Criteria</h3>

              <Table className="processes__table__1 r-text--sm">
                <tbody>
                  {
                    Object.keys(CONSTANTS.ELIGIBILITY_CRITERIA_TYPES).filter(type => this.state.isShowAdditionalEligibility ? true : !CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type].additional).map(type => {
                      let ecData = { ...CONSTANTS.ELIGIBILITY_CRITERIA_TYPES[type] },
                        criteria = applicationEligibility[type] || null;
                      return (<tr key={type} className={criteria ? "" : "disabled-row"}>
                        <td><Switch isOn={criteria !== null} handleToggle={() => this.toggleEligibility({ ...ecData, type, ...criteria })} /></td>
                        <td>{ecData.label}</td>
                        <td>{ecData.operators.length > 1 ?
                          <Select disabled={criteria ? false : true} name="operator" value={criteria ? criteria.operator : ecData.operators[0]} onChange={(e) => this.editEligibility(e, type)}>
                            {ecData.operators.map(op =>
                              <option key={op} value={op}>{CONSTANTS.ELIGIBILITY_OPERATOR[op]}</option>
                            )}
                          </Select> : <span>{CONSTANTS.ELIGIBILITY_OPERATOR[ecData.operators[0]] === "OR" ? "" : CONSTANTS.ELIGIBILITY_OPERATOR[ecData.operators[0]]}</span>
                        }</td>
                        <td style={{ width: "50%" }}>{ecData.operators[0] === "OR" ? <MultiSelectList className="w-100" disabled={criteria ? false : true} title={criteria ? (criteria.value_type || criteria.value || "Select") : "Select"}>
                          <ul className="list-unstyled m-0">
                          {eligibilityOptionsList[type] && eligibilityOptionsList[type].map((item, i) => {
                            let id = item[ecData.listId]
                            return (
                              <li key={i}>
                                <input name="value" onChange={(e) => this.editEligibility(e, type)} checked={criteria ? criteria.value.split(",").indexOf(id.toString()) > -1 : false} id={`${type}-${i}`} type="checkbox" value={id} />
                                <label htmlFor={`${type}-${i}`}>{item[ecData.name]}</label>
                              </li>
                            )
                          })}
                          </ul>
                        </MultiSelectList> : <InputField disabled={criteria ? false : true} name="value" className="w-100" type="number" value={criteria ? criteria.value : ""} onChange={(e) => this.editEligibility(e, type)} />}</td>
                      </tr>)
                    })
                  }
                </tbody>
              </Table>
              <div className="w-100 text-center"><Button type="button" className="r-text--sm" color="link" onClick={() => this.setState({isShowAdditionalEligibility : !this.state.isShowAdditionalEligibility})}>Show {this.state.isShowAdditionalEligibility ? "Less" : "More"}</Button></div>

              <div className="border-top my-3"></div>
              <h3 className="r-heading-4">Application Requirements</h3>
              <Table className="processes__table__1">
                <tbody>
                  {
                    applicationQuestion.concat(addedQuestions).filter(q => deletedQuestions.indexOf(q.job_question_id) < 0).map((q, i) => {
                      let answer_type_data = JSON.parse(q.answer_type_data);
                      return (
                        <tr key={i}>
                          <td className="r-subheading-4">{(i + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</td>
                          <td>
                            <div className="r-heading-7">{q.question_heading}</div>
                            <span>{q.question_content}</span>
                          </td>
                          <td>{CONSTANTS.ANSWER_TYPES[q.answer_type]}{q.answer_type === 'file' && Object.keys(answer_type_data).length ? "(" + (CONSTANTS.QUESTION_FILE_TYPES[answer_type_data.file_type] || '') + (answer_type_data.file_size ? ", " + answer_type_data.file_size + " kb" : "") + ")" : null}</td>
                          <td>{parseInt(q.is_mandatory) ? "Mandatory" : "Optional"}</td>
                          { !isView ? <td><Button type="button" className="p-0" onClick={() => this.deleteQuestion(q.job_question_id, i)}><i className="fi flaticon-bin" /></Button></td> : null }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              { !isView ? <Button type="button" id={`addQuestion_${job.job_template_id}`} className="r-text--sm mb-3" color="brandB">+ Add Question</Button> : null }
              { !isView ?
              <UncontrolledCollapse toggler={`#addQuestion_${job.job_template_id}`}>
                
                <Row>
                <Col xs={12}>
                    <span className="r-subheading-4">Enter question<span className="r-text--brandF">*</span></span>
                    <InputField type="text" placeholder="Type some question here" className="w-100" value={application_question.question_heading} name="question_heading" data-state="application_question" onChange={this.handleStateChange} />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <span className="r-subheading-4">Enter answer hint</span>
                    <InputField type="text" placeholder="Type answer hint here" className="w-100" value={application_question.question_content} name="question_content" data-state="application_question" onChange={this.handleStateChange} />
                  </Col>
                  <Col xs={2}>
                    <span className="r-subheading-4">Answer type</span>
                    <Select type="text" placeholder="Type answer hint here" value={application_question.answer_type} name="answer_type" data-state="application_question" onChange={this.handleStateChange}>
                      {
                        Object.keys(CONSTANTS.ANSWER_TYPES).map(ans => <option key={ans} value={ans}>{CONSTANTS.ANSWER_TYPES[ans]}</option>)
                      }
                    </Select>
                  </Col>
                  <Col xs={2}>
                    <span className="r-subheading-4">Mandatory?</span>
                    <Select name="is_mandatory" data-state="application_question" value={application_question.is_mandatory} onChange={this.handleStateChange}>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </Select>
                  </Col>
                  <Col xs="auto" className="pt-4">
                    <Button onClick={this.addQuestion} color="blue" type="button" className="r-text--blue-3">Add</Button>
                  </Col>
                </Row>

              </UncontrolledCollapse>
              :null }
              <div className="text-right mt-5">
                {
                  !this.props.isView ?
                  <Button type="submit" onClick={() => { this.setState({ savenext: 0 }) }} color="dark" className="ml-3 r-text--sm r-btn--no-radius">Save</Button>
                  :null
                }
              </div>
            </form>
            <Confirmation isOpen={isSure} toggleConfirmation={this.toggleConfirmation} callback={() => this.handleApplicationSubmit(confimationParam)} confirmationText="Application will be created for this role and you will move onto creating the next role. Are you sure?" />

          </ModalBody>
        </Modal>
      </Aux>
    )
  }
}
