import React, { Component } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Select } from '../../global/Forms/Select';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import { getErrorMessage } from '../../../actions';
import Button from '../../global/Button';
import { Message } from '../../../constants/messages';
import Link from 'react-router-dom/Link';
export class JobCampusModal extends Component {
  state = {
    jobTemplates: []
  }

  getJobTemplates = async () => {
    let { jobTemplates } = this.state
    store.dispatch(setLoading(true))
    try {
      let jobTemplateResp = await Auth.JobTemplate.fetch()
      jobTemplates = jobTemplateResp.data.data
      this.setState({ jobTemplates })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  mapJobToCampus = async(e) => {
    e.preventDefault();
    let { campusId, processId, toggleModal, callback } = this.props,
    job_template_id = e.target.job_template_id.value,
    formData = {
      campus_id: campusId,
      job_template_id,
      recruiter_process_id: processId
    }
    if(!formData.job_template_id) {
      store.dispatch(setError({showBar: true, message: Message.MANDATORY.SELECT_JOB}))
      return
    }
    store.dispatch(setLoading(true));
    try {
      await Auth.Process.createCampusJob(formData)
      toggleModal()
      if(callback) await callback()
    } catch(e) {
      console.log(e)
      store.dispatch(setError({showBar: true, message: getErrorMessage(e)}))
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { campusId, toggleModal } = this.props,
      { jobTemplates } = this.state
    return (
      <Modal centered={true} zIndex="99999" onOpened={this.getJobTemplates} isOpen={campusId !== null} toggle={()=>toggleModal()}>
        <ModalBody>
          {jobTemplates.length ? 
          <form onSubmit={this.mapJobToCampus}>
            <h2 className="r-heading-3">Select a Job</h2>
            <Select name="job_template_id">
              <option value="">Select</option>
              {jobTemplates.map(job => {
                return (
                  <option key={job.job_template_id} value={job.job_template_id}>{job.role} - {job.designation}</option>
                )
              })}
            </Select>
            <div className="mt-3 text-right">
              <Button square color="dark">Add Job</Button>
            </div>
          </form>
          : 
          <div className="text-center py-4">
            <p style={{fontWeight: 600, margin: 10}}>You need to create a Job first in Jobs tab, before tagging Job to Institute</p>
            <Link to="/jobs">Go to Jobs</Link>
          </div>
          } 
        </ModalBody>
      </Modal>
    )
  }
}