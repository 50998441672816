import React,{Component} from 'react';
import {Container , Row , Col, Button} from 'reactstrap';
import ManageRecruiter from './manage_recruiter';


class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
     var : "hello"
    }
  }

  render(){
    return(
      <div className="container">
        {/* <div className="top-navigation mt-3"></div> */}
          <Row style={{height : "90vh"}}>
              <Col xs={3} className="">
                  <Button className="r-text--sm r-btn r-btn--blue-3 r-btn--no-radius" style={{ marginTop : "32px"}}>Manage Recruiter</Button>
              </Col>

              <Col xs={9} style={{marginLeft : "-70px"}}>
                  <ManageRecruiter />
              </Col>
          </Row>
      </div>
    )
  }
}

export default Settings ;
