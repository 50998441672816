import React, { Component } from 'react';
import { InputField } from './index'
export class Multifield extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVal: ""
    }
  }

  onChange = (e) => {
    this.setState({
      inputVal: e.target.value
    })
  }

  addValue = (e) => {
    e.preventDefault();
    let { inputVal } = this.state
    if (inputVal !== "") {
      this.props.updateValues([...this.props.values, inputVal])
      this.setState({
        inputVal: ""
      })
    }
  }
  deleteValue = (i) => {
    let { values, updateValues } = this.props;
    values.splice(i, 1);
    updateValues(values)
  }
  render() {
    return (
      <div className="r-multifield">
        <ul className="r-multifield__list">
          {
            this.props.values.map((val, i) => {
              return (
                <li key={i}>
                  <span className="r-tag">
                    <span>{val}</span>
                    <button onClick={() => this.deleteValue(i)} className="r-tag__delete fi flaticon-plus-1"></button>
                  </span>
                </li>
              )
            })
          }
        </ul>
        <form onSubmit={this.addValue}>
          <InputField className="w-100" value={this.state.inputVal} onChange={this.onChange} placeholder="Type here" />
          {this.props.children}
        </form>
      </div>
    )
  }
}