import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { FeedPost } from '../../global/post';

export default class ListPost extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isCardView: false,
    }
  }

  handleCardView = (postId) => {
    this.setState({ isCardView: postId })
  }

  render() {
    let { isCardView } = this.state
    let { posts, files } = this.props
    return (
      <div className="list-post my-5 ">
        <Row>
          {
            posts.map(post => {
              return (
                <Col key={post.post_id} xs={12} md={6} className="mb-3">
                  <FeedPost files={files}
                    data={post}
                    postType="post"
                    handleCardView={this.handleCardView}
                    isCardView={isCardView} />
                </Col>
              )
            })
          }
        </Row>
      </div>

    )
  }
}