import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import { getFullName, getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import Button from '../Button';
import Collapse from 'reactstrap/lib/Collapse';
import ChatBox from './chatbox';
export default class Footer extends Component {
  state = {
    campusList : [],
    isOpenMessageBar : false,
    chatBoxData : null,
    unreadMsgCount : [],
  }

  componentDidMount(){
    this.getUnreadMsg();
    this.getCollegeList();
    this.countinterval = setInterval(this.getUnreadMsg, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.countinterval);
  }

  getUnreadMsg = async () =>{
    try{
      let countResp = await Auth.campusConversation.msgCount();
      this.setState ({unreadMsgCount : countResp.data.data})
    } catch (e) {
      console.log(e)
    }
  }

  getCollegeList = async () => {
    store.dispatch(setLoading(true))
    try {
      let recruiterCampus = await Auth.General.getAllCampus()
      let campusList = recruiterCampus.data.data
       this.setState({
         campusList
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  setChatBox =(data) => {
    this.setState({chatBoxData : data})
  }
  render() {
    let  { isOpenMessageBar, campusList, chatBoxData,unreadMsgCount } = this.state;
     campusList = campusList.filter(campus => campus.connection_status === 1);
     var tCount = 0;
     campusList = campusList.map(connection => {
      var filteredUnread = unreadMsgCount.filter(msg => msg.campus_id == connection.campus_id);
      var count = filteredUnread.length ? filteredUnread[0].recruiter_id_count : 0;
      tCount = tCount+count;
      connection.unread_count = count;
      return connection;
     })
    return (
        <div style={{ position: "fixed", right: 0, bottom: 0, zIndex: 999999}}>
            <Row className="justify-content-end">
              {chatBoxData ? <Col style={{right: "1.3%" , width: 300}}>
              <ChatBox setChatBox={this.setChatBox} campusData={chatBoxData} />
              </Col> 
                : ""}
                <Col className="align-self-end" style={{right: "1.3%" , width: 300}}>
  <Button onClick={() => {this.setState({isOpenMessageBar : !isOpenMessageBar})}} square={true} color="blue-3 w-100 r-subheading-3  py-3 text-left">Messaging {tCount ? <div style={{height : 16, width:18 , borderRadius : 10, fontSize: "smaller", fontWeight: 400}} className="bg-danger text-center text-white float-right mr-4 mt-1">{tCount}</div> : ""}</Button>
                    <Collapse isOpen={isOpenMessageBar}>
                        <Row noGutters className="border bg-light">
                            <Col>
                                {campusList.length ? campusList.map(campus =>
                                    <Row key={campus.campus_id} noGutters className="border pl-3 text-left">
                                        <Col className="py-3">
                                            <span style={{fontWeight : 400, cursor:"pointer"}} className="r-subheading-3" onClick={() => this.setChatBox(campus)} color="link">{campus.name}</span>
                                            { campus.unread_count > 0 ?
                                           <div style={{height : 16, width:18 , borderRadius : 10, fontSize: "smaller", fontWeight: 400}} className="bg-danger text-center text-white float-right mr-5 mt-1">{campus.unread_count}</div> 
                                          : "" }
                                        </Col>
                                    </Row>
                                ) :
                                <p className="m-4 text-primary" style={{fontSize: '13px', fontStyle: "italic"}}>You should be connected with Institutes to start messaging</p>

                              }
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>
       
        </div>
    )
  }
}
