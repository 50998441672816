import React, { Component } from 'react';

const ToggleButton = function(props) {
		let classNames = ["toggle-button", (props.isOn) ? "toggle-button_position-right" : "toggle-button_position-left"];
		classNames = classNames.join(" ");
		return (<div className={classNames} style={props.toggleColor ? {background : props.toggleColor, border:'0.05rem solid '+props.toggleColor}:{}}></div>);
};


export const Switch = (props) => {

	let classNames = ["switch", (props.isOn) ? "switch_is-on" : "switch_is-off", props.className].join(" ");
	return (
		<div className={classNames} onClick={props.handleToggle}>
			<ToggleButton
				isOn={props.isOn}
				toggleColor={props.toggleColor}
			/>
		</div>
	);
}
