import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Sidenav from './sidenav'
import QuickNav from './quick-navs'
import ProfileCard from './profile-card'
import {getErrorMessage, getFullName , filterSmartScoringStudents} from '../../../actions';
import {setApplicants , setScoringApplicants, setError } from '../../../actions/action-creators';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import store from '../../../store';
import { Auth } from '../../../actions/Auth';
import Button from '../../global/Button';

class Application extends Component {

  constructor(props) {
    super(props)
  }

  state = {
    urlParams: this.props.match.params,
    filters : {},
    applicationDetail: null,
    selectedStudents : {},
    isOpenFilters : false
  }

  componentDidMount() {
    this.getApplicationDetails()
  }

  setStudentList = (value)=>{
    let {selectedStudents} = this.state;
    let tempArray = {...selectedStudents};
    if(!tempArray[value]){
      tempArray[value] = true;
    }
    else{
      tempArray[value] = false;
    }
    this.setState({selectedStudents : tempArray});
  }



  getApplicationDetails = async() => {
    let { campus_job_id } = this.state.urlParams;
    if(campus_job_id) {
      try {
        let applicationResp = await Auth.Evaluate.getJobs({campus_job_id})
        this.setState({applicationDetail: applicationResp.data.data[0]})
      } catch(e) {
        console.log(e)
        store.dispatch(setError({showBar: true, message: getErrorMessage(e)}))
      }
    }
  }

  setFilters = async(filters)=>{
    this.setState({filters});
    let whereObject ={};
    if(Object.keys(filters) && Object.keys(filters.simpleFilter).length>0) {whereObject = {...filters.simpleFilter}};
    let {applicants , scoringApplicants} = this.props;
    let fields = ['student_id' , 'confidence'];
    let rangeFilter = Object.keys(filters.rangeFilter).length>0 ? filters.rangeFilter : {};
    let categoryRangeFilter = Object.keys(filters.sectionTypeFilter).length>0 ? filters.sectionTypeFilter : {};
    let smartScrores =  await filterSmartScoringStudents(applicants , fields , whereObject ,rangeFilter , categoryRangeFilter );
    smartScrores = smartScrores.reduce((prev, current)=>{
      prev[current.student_id] = current.confidence
      return prev
    },{})
    applicants =  applicants.filter(st=>st.confidence == smartScrores[st.student_id]);
    this.props.setScoringApplicants(applicants);
  }

  render() {
    let { filters, applicationDetail , selectedStudents, isOpenFilters} = this.state;
    return (
      <div className="container">
          <div className="container-fluid">
          <Sidenav isOpen={isOpenFilters} toggle={() => this.setState({isOpenFilters : !isOpenFilters})} setFilters={this.setFilters} />
          <QuickNav  applicationDetail={applicationDetail} selectedStudents={selectedStudents} />
            
          <ProfileCard toggleFilter={() => this.setState({isOpenFilters : !isOpenFilters})} evalution={this.props.location.state && this.props.location.state.evaluator?true : false} filters={filters} setStudentList={this.setStudentList} selectedStudents={selectedStudents} />
          </div>
      </div>

    )
  }
}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setApplicants: (applicants) => dispatch(setApplicants(applicants)),
  setScoringApplicants : (scoringApplicants)=> dispatch(setScoringApplicants(scoringApplicants))
})
export default connect(mapStateToProps, mapDispatchToProps)(Application)
