import React, { Component } from "react";
import { Auth } from "../../../actions/Auth";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import withRouter from "react-router-dom/withRouter";
import Button from "../../global/Button";
import { Message } from "../../../constants/messages";
import { JobModal } from "./job_modal";
import store from "../../../store";
import { setLoading, setConfirm, setError, setSuccess } from "../../../actions/action-creators";
import { getErrorMessage } from "../../../actions";

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobList: [],
      isOpenJobModal: false,
      editJob: null,
      isView: null,
      campusJobs: null
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    store.dispatch(setLoading(true));
    try{
    var resp = await Auth.JobTemplate.fetch();
    let jobsResp = await Auth.Process.getCampusJob({})
    var jobList = resp.data.data
    await this.setState({ jobList , campusJobs : jobsResp.data.data});
    this.fetchReportOverview();
    }
    catch(e){
      console.log(e);
    }
    store.dispatch(setLoading(false));
  };

  fetchReportOverview = async() =>{
    store.dispatch(setLoading(true))
    let { jobList } = this.state;
    try{
      jobList = await Promise.all(jobList.map(async job => {
        var body = {
            job_template_id : job.job_template_id
        }
        let reportOverviewResp = await Auth.JobTemplate.fetchStatistics(body);
        job.statistics = reportOverviewResp.data.data.length ? reportOverviewResp.data.data[0] : {};
        return job;
      }))
      console.log(jobList)
      this.setState({jobList})
    }
    catch(e){
        console.log(e);
    }
    store.dispatch(setLoading(false))
  }
  toggleJobModal = (e, job, isView) => {
    this.setState({
      isOpenJobModal: !this.state.isOpenJobModal,
      editJob: job || null,
      isView: isView || null
    });
  };
  newJob = () => {
    return {
      job_template_id: "NEW_" + Math.ceil(Math.random() * 10000),
      location: "",
      application_eligibility: [],
      application_docs: [],
      application_question: [],
      ctc_max: "",
      ctc_min: "",
      ctc_type: "strict",
      ctc_currency: "INR",
      description: ""
    };
  };
  inactiveJobTemplate = async (e, job_template_id) => {
    store.dispatch(setLoading(true))
    try{
      await Auth.JobTemplate.inactive({job_template_id})
      await this.init();
      store.dispatch(setSuccess({ showBar: true, message: Message.SUCCESS.JOB.JOB_DELETE}))
    }catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  };

  handleDataChange = (obj, isEdit) => {
    let { jobList } = this.state;
    if (!isEdit) {
      jobList.push(obj);
    } else {
      jobList = jobList.map(job => {
        if (job.job_template_id == obj.job_template_id)
          job = {
            ...job,
            ...obj
          };
        return job;
      });
    }
    this.setState({ jobList });
  };

   render() {

    let {jobList, isOpenJobModal, editJob, isView, campusJobs} = this.state

    return (<div className="recruitment">

      <div className="container">
        <div className="top-navigation border-bottom mt-3">
          <Row className="align-items-center">
            <Col xs="auto">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb pb-0">
                  <li className="breadcrumb-item">
                    <h3 className="r-heading-4">Jobs</h3>
                  </li>
                </ol>
              </nav>
            </Col>
            <Col xs="auto">
              <Button onClick={this.toggleJobModal} color="brandB" className="r-text--sm">
                <i className="fi flaticon-plus r-text--sm mr-2"></i>Add Job</Button>
            </Col>
          </Row>
        </div>

        <div className="pl-5 pr-5 my-5">
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={6}>
                  <input className="w-100 r-inputfield" type="text" name="first_name" placeholder="Search"/>
                  <i className="r-text--grey-4 fi r-input_field_icon flaticon-search"></i>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>
        <div className="pt-5 mt-5">
          {jobList.length ? 
            jobList.map((job, index) => {

              job = {
                ...job,
                application_question: [],
                application_eligibility: [],
                application_docs: []
              };
              let count = campusJobs ? campusJobs.filter(item=>{if(item.job_template_id == job.job_template_id && item.receive_status==2) return item}).length : 0
              
              return (
              <Row className="align-items-center py-5 border-bottom" key={job.job_template_id}>
                <Col xs={3}>
                  <h1 className="r-text--blue-3 r-subheading-2 d-inline-block">{job.role}</h1>
                  <h1 className="r-subheading-4">{job.designation}</h1>
                  <h1 className="r-subheading-4">{job.location}</h1>
                </Col>
                <Col xs={4} style={{fontSize : 12}}>
                  <Row>
                    <Col>
                      Vacancies : {job.statistics ? job.statistics.vacancy : 0}
                    </Col>
                    <Col>
                    Campuses Invited : {job.statistics ? job.statistics.no_of_campus : 0}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    Total Applications : {job.statistics ? job.statistics.applicants_count : 0}
                    </Col>
                    <Col>
                    Total Offers : {job.statistics ? job.statistics.offer_count : 0}
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                <h1 className="r-subheading-3">{job.designation}</h1>

                  <h1 className="r-subheading-4">{job.ctc_currency}{' '}{job.ctc_min}{'-'}{job.ctc_max}</h1>
                </Col>

                {/*<Col xs={2}>
                  <h1 className="r-subheading-4">{count || '0'} Campus</h1>
                </Col>*/}
                <Col xs={2}>
                  <Button onClick={(e) => this.toggleJobModal(e, job, true)} className="r-text--sm mr-2">
                    <i className="fi flaticon-eye"></i>
                  </Button>
                  <Button onClick={(e) => this.toggleJobModal(e, job, false)} className="r-text--sm mr-2">
                    <i className="fi flaticon-edit"></i>
                  </Button>
                  <Button onClick={(e) => store.dispatch(setConfirm({callback : () => this.inactiveJobTemplate(e, job.job_template_id) }))} className="r-text--sm mr-2">
                    <i className="fi flaticon-bin"></i>
                  </Button>
                </Col>
              </Row>)
            }): 
            <Row>
              <Col xs={12}>
                <button 
                  onClick={this.toggleJobModal}
                  className="w-100 p-3 m-4 r-btn r-btn--blue r-btn--no-radius" 
                  style={{
                    border: "1px dashed #6a7b7b",
                    color: "#94a7b9", 
                    borderRadius: 6,
                    fontStyle: "italic",
                    background: "#eaf3f3"
                  }} 
                  >
                  Create your first Job here, move to Campus Drives and create a drive and Tag Institutes to Jobs</button>
              </Col>
            </Row>
          }
        </div>
      </div>
      <JobModal isOpen={isOpenJobModal} isView={isView} job={editJob || this.newJob()} toggleModal={this.toggleJobModal} handleDataChange={this.handleDataChange}/>
    </div>)
  }
}

export default withRouter((Jobs));
