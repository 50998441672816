import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Collapse from 'reactstrap/lib/Collapse';
import withRouter from 'react-router-dom/withRouter';
import Link from 'react-router-dom/Link';
import store from '../../../store';
import Button from '../../global/Button'
import ProcessModal from './process_modal'
import { Message } from '../../../constants/messages'
import { setSuccess, setLoading, setError, setConfirm } from '../../../actions/action-creators';
import { InputField } from '../../global/Forms';
import ProgressBarCircle from '../../global/progressbar-circle';
import { getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import {Card, CardImg, CardText, CardBody,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class College extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campusList: [],
      connectionList : [],
      campusProfileList : {},
      processList: [],
      isOpen: false,
      isOpenProcessModal: false,
      searchCollegeName : '',
      appliedTag : ""
    }
  }

  async componentDidMount() {
   this.getConnectionList()
   await this.getCollegeList()
   await this.fetchCampusLogo()
  }

  getCollegeList = async (tag) => {
    store.dispatch(setLoading(true))
    try {
      let recruiterCampus = await Auth.General.getAllCampusWithUniv({tag})
      let univList = recruiterCampus.data.data;
      let campusList = [];
      univList.map(univ => univ.college_data.map(college => college.campus_data.map(campus => {
        campus.campus_count = college.campus_data.length;
        campus.college_name = college.name;
        campus.university_name = univ.name;
        campusList.push(campus)
      })))
      this.setState({
        campusList
      })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  getConnectionList = async () => {
    store.dispatch(setLoading(true))
    try {
      let recruiterConnection = await Auth.Project.getCampus();
      this.setState({
        connectionList : recruiterConnection.data.data
      })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  fetchCampusLogo = async() => {
    let {campusList} = this.state;
    let campusProfileList = {};
   await Promise.all(campusList.map(async (campus) => {
      var body = {
        campus_id : campus.campus_id
      }
      var profileResp = await Auth.campusProfile.fetch(body)
      var campusProfile = profileResp.data[0] || {};
      // var logo = campusProfile.logoFileObject ? campusProfile.logoFileObject.signed_url : null;
      campusProfileList[campus.campus_id] = campusProfile
      // campus.logo_url = logo;
    }))
    this.setState({campusProfileList})
  }

  filterByTag = (tag) => {
    this.setState({appliedTag : tag || ""})
    this.getCollegeList(tag);
  }

  toggleProcessPanel = async (campus_id) => {
    let { isOpen } = this.state
    store.dispatch(setLoading(true))
    try {
      if (isOpen !== campus_id)
        await this.getProcessList(campus_id)
      this.setState({ isOpen: isOpen == campus_id ? false : campus_id })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  getProcessList = async (campus_id) => {
    try {
      let processResp = await Auth.Process.getCampusProcess({ campus_id })
      this.setState({ processList: processResp.data.data })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
  }

  toggleProcessModal = () => {
    this.setState({ isOpenProcessModal: !this.state.isOpenProcessModal })
  }

  mapCampusProcess = async (recruiter_process_id) => {
    let { isOpen } = this.state;
    store.dispatch(setLoading(true))
    try {
      await Auth.Process.addCampus({ recruiter_process_id, campus_id: isOpen })
      await this.getProcessList(isOpen)
      store.dispatch(setSuccess({ showBar: true, message: Message.SUCCESS.PROCESS_CAMPUS[1] }))
      this.toggleProcessModal()
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  deleteCampusFromProcess = async (e, process_campus_id) => {
    let { isOpen } = this.state;
    store.dispatch(setLoading(true))
    try {
      await Auth.Process.deleteCampus({ process_campus_id })
      await this.getProcessList(isOpen)
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  updateCampusList = (obj, campus_id) => {
    let { campusList } = this.state;
    campusList = campusList.map((campus) => {
      if(campus.campus_id == campus_id){
        campus = {...campus, ...obj}
      }
      return campus;
    })
    this.setState({campusList})
  }

  requestConnection = async (e, campus_id) => {
    e.stopPropagation();
    store.dispatch(setLoading(true))
    try{
      var resp = await Auth.Connection.request({campus_id, requested_by:1})
      await this.updateCampusList(resp.data.data[0], campus_id)
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  inactiveConnetionRequest = async (e, campus) => {
    e.stopPropagation();
    store.dispatch(setLoading(true))
    try{
      var resp = await Auth.Connection.inactive({connection_id : campus.connection_id, requested_by: 1})
      await this.updateCampusList({connection_status : null}, campus.campus_id)
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }


  render() {
    let { campusList, isOpenProcessModal, isOpen, processList , searchCollegeName, connectionList, appliedTag, campusProfileList } = this.state
    if(searchCollegeName!==''||  searchCollegeName==true){
      campusList = campusList.filter(data=>{
        if(data.name.toLowerCase().indexOf(searchCollegeName.toLowerCase())!==-1){
          return data;
        }
      })
    }
    let isAnyCampusConnected = !!connectionList.filter(connection => connection.connection_status !== undefined || connection.connection_status !== undefined).length
    connectionList = connectionList.reduce((prev, curr) => {prev[curr.campus_id] = curr; return prev;}, {})
    return (
      <div className="recruitment">
        <div className="container">
          <div className="top-navigation border-bottom mt-3">
            <Row>
              <Col xs={6}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pb-0">
                    <li className="breadcrumb-item">
                      <h1 className="r-heading-3">Institutes</h1>
                    </li>
                  </ol>
                </nav>
              </Col>
            </Row>
          </div>
         
          <div className="pl-5 pr-5 my-5">
            <Row>
              <Col xs={3}>
                <InputField type="text" placeholder="Search" onChange={(e)=>this.setState({searchCollegeName : e.target.value})} />
              </Col>
              <Col className="text-right">
                <Button onClick={() => this.filterByTag()} color={appliedTag === "" ? "blue-3" : "blue"}>All</Button>
                <Button className="ml-2" onClick={() => this.filterByTag("B-school")} color={appliedTag === "B-school" ? "blue-3" : "blue"}>B-school</Button>
                <Button className="ml-2" onClick={() => this.filterByTag("Humanities/Sciences/Commerce")} color={appliedTag === "Humanities/Sciences/Commerce" ? "blue-3" : "blue"}>Humanities/Sciences/Commerce</Button>
                <Button className="ml-2" onClick={() => this.filterByTag("Engineering")} color={appliedTag === "Engineering" ? "blue-3" : "blue"}>Engineering</Button>
              </Col>

            </Row>
          </div>
          {!isAnyCampusConnected &&
          <Row>
            <Col> 
              <span style={{
                border: "1px dashed #6a7b7b",
                color: "#94a7b9", 
                borderRadius: 6,
                fontStyle: "italic",
                background: "#eaf3f3",
                padding: 5,
                fontSize: 12,
                display: "block",
                textAlign: "center",
              }} className="my-2">
              View Profile and send Connection Requests to Institutions. Once connected, you can invite applications, chat with the placement team and post feed for students
              </span>
            </Col>
          </Row>
          }
          <Row>
            {
              campusList.map((campus, index) => {
                let profile = campusProfileList[campus.campus_id] || {};
                let logo = profile.logoFileObject ? profile.logoFileObject.signed_url : null;
                let banner = profile.bannnerFileObject ? profile.bannnerFileObject.signed_url : null;

                return (
                  <Col xs={3} key={index} >

                      <Card className="my-3 shadow-sm overflow-hidden" style={{borderRadius : 10}}>
                          <CardBody className="p-0 text-center">
                            <Row>
                              <Col>
                                <div className="w-100 bg-secondary" style={{height: 100}}>
                                  {banner && <img src={banner} style={{width : "100%", maxHeight: 100}} />}
                                </div>
                                <img src={logo ||  "/images/campus-dummy.png"} className="img-fluid" style={{height : 75 , width : 75, marginTop: "-40px"}}/>
                              </Col>
                            </Row>
                             
                            <span className="r-subheading-3 mt-2 d-block">
                              {campus.name}
                            </span>
                            <em style={{fontSize : 10}} className="text-muted">{campus.university_name}</em>
                                
                              <Row noGutters className="align-items-center">
                                  <Col className="py-3 text-center">
                                    <a className="r-subheading-3" target="_blank" href={process.env.REACT_APP_CAMPUS_URL+"/profile/"+campus.slug_name}>View Profile</a>
                                  </Col>
                                  <Col className="py-3 text-center r-text--blue-3 ">
                                    <ConnectionStatus campus={campus} connection={connectionList[campus.campus_id] || {}}  requestConnection={this.requestConnection} inactiveConnetionRequest={this.inactiveConnetionRequest} />
                                  </Col>
                              </Row>
                              <Row noGutters className="align-items-center" >
                                  <Col xs={12}>
                                      <Button className="py-2 w-100" square={true} onClick={(e) => this.toggleProcessPanel(campus.campus_id)} style={{backgroundColor : "#cee2f5" , color : "#06c"}} >Map to Campus Drives</Button>
                                  </Col>
                              </Row>
                            
                          </CardBody>
                        </Card>

                       
                        <Collapse isOpen={isOpen == campus.campus_id} className="col-12 r-bg--blue-1">
                        <Row>
                          <Col xs={12} className="my-2">
                            <Button onClick={this.toggleProcessModal} className="shadow w-100 bg-white p-2 text-center"><span></span> <i className="fi flaticon-plus"></i></Button>
                          </Col>
                          {processList.map((process, index) =>
                            <Col xs={12} className="my-2" key={index}>
                              <div className="shadow w-100 bg-white p-3">
                                <span>{process.process_name}</span>
                                <Button onClick={(e) => store.dispatch(setConfirm({ callback: () => this.deleteCampusFromProcess(e, process.process_campus_id) }))} className="pr-1 float-right">
                                  <i className="fi flaticon-bin"></i>
                                </Button>
                              </div>
                            </Col>
                          )
                          }
                         
                        </Row>
                      </Collapse>
                       
                          {/* <Modal isOpen={isOpen == campus.campus_id} className="col-12 r-bg--blue-1 modal-dialog-centered ">
                            <Row>
                              {processList.map((process, index) =>
                                <Col xs={3} className="my-5" key={index}>
                                  <div className="shadow w-100 bg-white p-3">
                                    <span>{process.process_name}</span>
                                    <Button onClick={(e) => store.dispatch(setConfirm({ callback: () => this.deleteCampusFromProcess(e, process.process_campus_id) }))} className="pr-1 float-right">
                                      <i className="fi flaticon-bin"></i>
                                    </Button>
                                  </div>
                                </Col>
                              )
                              }
                              <Col xs={1} className="my-5">
                                <Button onClick={this.toggleProcessModal} className="shadow w-100 bg-white p-3 text-center"><span></span> <i className="fi flaticon-plus"></i></Button>
                              </Col>
                            </Row>
                          </Modal> */}
                   
                        
                    {/* <Row className="shadow-sm bg-light p-3 my-3 align-items-center">
                      <Col xs={5}>
                        <Row className="align-items-center">
                          <Col xs={2} className="">
                            <img src={campus.logo_url ||  "/images/campus-dummy.png"} className="img-fluid" />
                          </Col>
                          <Col xs={10} className="">
                            <h1 className="r-subheading-3">
                              {campus.name}
                            </h1>
                            <span className="">{campus.location}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2} className=" text-center">
                      <Button color="link"><a className="r-heading-6 r-text--blue-3" target="_blank" href={process.env.REACT_APP_CAMPUS_URL+"/profile/"+campus.slug_name}>View Profile</a></Button>
                      </Col>
                      <Col xs={3} className=" text-center">
                        <ConnectionStatus campus={campus} requestConnection={this.requestConnection} inactiveConnetionRequest={this.inactiveConnetionRequest} />
                      </Col>
                      
                      <Col xs={2} className="d-flex align-items-center"  onClick={(e) => this.toggleProcessPanel(campus.campus_id)}>
                        <div className="">
                          <h3 className="r-heading-6 r-text--blue-3">
                            <Button color="link"><span className="r-heading-6">Map to Campus Drives</span></Button>
                          </h3>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Collapse isOpen={isOpen == campus.campus_id} className="col-12 r-bg--blue-1">
                        <Row>
                          {processList.map((process, index) =>
                            <Col xs={3} className="my-5" key={index}>
                              <div className="shadow w-100 bg-white p-3">
                                <span>{process.process_name}</span>
                                <Button onClick={(e) => store.dispatch(setConfirm({ callback: () => this.deleteCampusFromProcess(e, process.process_campus_id) }))} className="pr-1 float-right">
                                  <i className="fi flaticon-bin"></i>
                                </Button>
                              </div>
                            </Col>
                          )
                          }
                          <Col xs={1} className="my-5">
                            <Button onClick={this.toggleProcessModal} className="shadow w-100 bg-white p-3 text-center"><span></span> <i className="fi flaticon-plus"></i></Button>
                          </Col>
                        </Row>
                      </Collapse>
                    </Row> */}
                  </Col>
                )
              })
            }
          </Row>
          {isOpenProcessModal && <ProcessModal isOpen={isOpenProcessModal} toggle={this.toggleProcessModal} callback={this.mapCampusProcess} />}
        </div>
      </div>
    )
  }
}

export default withRouter((College));


class ConnectionStatus extends Component {
  constructor(props){
    super(props);
  }
  render(){
    let {campus, connection} = this.props;
    switch(connection.connection_status) {
      case 0:
        return <Button  onClick={(e) => {e.stopPropagation(); store.dispatch(setConfirm({callback : () => this.props.inactiveConnetionRequest(e, campus), message: "Are you sure to delete connection request?" }))}} color=" r-text--blue-3 r-subheading-3"  className={(" "+this.props.className)} square={true}>&#10004; Requested</Button>;
      case 1:
        return <Button color=" r-text--blue-3 r-subheading-3" className={(" "+this.props.className)} square={true}>&#10004; Connected</Button>;
      case 2:
        return <Button color=" r-text--blue-3 r-subheading-3"  className={(" "+this.props.className)} square={true}>&#10004; Request Rejected</Button>;
      default:
        return <Button onClick={(e) => this.props.requestConnection(e, campus.campus_id)} color=" r-text--blue-3 r-subheading-3"  className={(" "+this.props.className)} square={true}><i className="fi flaticon-plus-1 mr-2 r-text--sm"></i>Connect</Button>;
    }
  }
}
