import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Row,Col,Container} from 'reactstrap';

export const HorizontalLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            margin: 2
        }}
    />
)
