import React, { Component } from 'react';
import Button from './Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';


export class Confirmation extends Component {



	render() {
			return (
				<Modal zIndex="99999" isOpen={this.props.isOpen} toggle={this.props.toggleConfirmation} className="modal-dialog-centered">
          <ModalBody >
            {this.props.confirmationText || <h1 className="r-subheading-3">Are you sure?</h1>}
          </ModalBody >
          <ModalFooter>
            <Button color="dark-v r-btn--no-radius r-text--sm" onClick={this.props.toggleConfirmation} >Cancel</Button>{' '}
            <Button color="dark r-btn--no-radius r-text--sm" onClick={this.props.callback} >Sure</Button>
          </ModalFooter>
				</Modal>
			)

	}
}
