import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Auth } from '../../actions/Auth';

class AutosuggestComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      min3: false,
      wrongSugges: false,
    };
  }


  autoSearch = async ({ value }) => {
    this.setState({ min3: false })
    this.setState({ wrongSugges: false })
    let { getSuggestions } = this.props
    try {

      let resp = await getSuggestions(value);
      let suggestions = resp.data.data || resp.data;

      this.setState({
        suggestions
      })
    } catch (e) {
      console.log(e)
    }

  }

  clearSuggestionList = () => {
    this.setState({ suggestions: [] })
    this.setState({ min3: false })
  }

  render() {

    const { inputProps, renderSuggestion, setValue } = this.props;

    return (
      <div>
        <Autosuggest
          inputProps={inputProps}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={(data) => this.autoSearch(data)}
          getSuggestionValue={value => value}
          renderSuggestion={renderSuggestion}
          onSuggestionsClearRequested={() => this.clearSuggestionList()}
          onSuggestionSelected={(e,{method})=>{
            if(method==="enter") e.preventDefault()
          }}
        />
      </div>
    );
  }
}
export default AutosuggestComp;