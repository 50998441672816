import React, { Component, PureComponent } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setLoading, setError, setApplicants, setScoringApplicants } from '../../../actions/action-creators';
import { getErrorMessage, getFullName, filterSmartScoringStudents, getFiles } from '../../../actions';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { Select } from '../../global/Forms/Select';
import Button from '../../global/Button';
import { Modal, ModalHeader, ModalBody , ModalFooter} from 'reactstrap';

class ProfileCard extends PureComponent {

  constructor(props) {
    super(props)
  }

  state = {
    roundData: [],
    filterRoundData: [],
    smartScoringStudents: [],
    studentEvaluation : [],
    filters: this.props.filters,
    isOpenModal : false,
    rounds: [],
    params: this.props.match.params,
    applicants: {
      rounds:[],
      applicants:[]
    },
    searchText : '',
    selectedStudents : {},
    evaluatorApplicants : null,
    selectedStudentCV : null,
    readMoreComment : 0
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.round_id !== prevState.params.round_id) {
      prevState = { ...prevState }
      prevState.params.round_id = nextProps.match.params.round_id
    }
    return prevState
  }
  componentDidMount() {
    this.getApplicants()
    if(this.props.evalution){
      this.getEvaluatedMappedApplicants();
    }
  }
  fetchCV = async () => {
    let { isOpenModal } = this.state;
    let { campus_id } = this.state.params;
    store.dispatch(setLoading(true))
    this.fetchEvaluationStatus();
    try{
      let body = {
        cv_id : isOpenModal.resume_id,
        student_id : isOpenModal.student_id,
        campus_id
      }
      let resp = await Auth.Evaluation.fetchCv(body);
      var selectedStudentCV = {
        file_url : URL.createObjectURL(new Blob([resp.data],  {type: 'application/pdf'}))
      }
      this.setState({selectedStudentCV})
    }
    catch(e){
      console.log(e);
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
    store.dispatch(setLoading(false))

  }

  fetchEvaluationStatus = async () => {
    let { isOpenModal } = this.state;
    store.dispatch(setLoading(true))
    let body = {
      application_id : isOpenModal.application_id,
      student_id : isOpenModal.student_id,
    } 
    try{
      let resp = await Auth.Evaluation.commentFetch(body);
      this.setState({studentEvaluation : resp.data.data})
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
    }
    store.dispatch(setLoading(false))
  }

  updateEvaluationStatus = async (e) => {
    let { isOpenModal } = this.state;
    e.preventDefault();
    e.persist();
    store.dispatch(setLoading(true))
    try{
      let body = {
        application_id : isOpenModal.application_id,
        evaluation_comment : e.target.comment.value,
        student_id : isOpenModal.student_id,
        role_id : 1,
      } 
      let resp = await Auth.Evaluation.commentUpdate(body);
      this.fetchEvaluationStatus();
      e.target.reset()
    }
    catch(e){
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
      console.log(e)
    }
    store.dispatch(setLoading(false))

  }
  

  toggleOpenModal = (data) => {
    this.setState({isOpenModal : data, selectedStudentCV : null, studentEvaluation : [], readMoreComment : 0 });
  }

  getEvaluatedMappedApplicants= async()=>{
        try{
          let assignedApplicantsResp = await Auth.Evaluation.fetchEvaluatorApplicants({application_id : this.props.match.params.application_id});
          this.setState({evaluatorApplicants : assignedApplicantsResp.data.data });
        }
        catch(e){
          store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }));
        }
      }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.round_id !== this.state.params.round_id) {
      store.dispatch(setLoading(true))
      await this.getRoundData(this.state.applicants)
      store.dispatch(setLoading(false))
    }
  }

  getRoundData = async application => {
    let roundData = {
      "applied": [],
      "R-0": []
    }

    if (application.rounds) {
      application.rounds.forEach(round => {
        roundData["R-" + round.application_round_id] = []
      })
    }
    if (application.applicants) {
      application.applicants.forEach(a => {
        if (a.student_application_status === 2) {
          roundData.applied.push(a)
          a.shortlisting_data.forEach(shortlist => {
            if (shortlist.type === "S" && shortlist.locked_status === 1) {
              roundData["R-" + shortlist.application_round_id].push(a)
            }
          })
        }
      })
    }
    let { round_id } = this.state.params,
      roundDataIndex = Object.keys(roundData).indexOf("R-" + round_id) - 1,
      filterRoundData = roundData[Object.keys(roundData)[roundDataIndex]];
    // let fields = ['student_id', 'confidence', 'academic_detail.score_ep', "academic_detail.academic_type"];
    // let smartScrores = await filterSmartScoringStudents(filterRoundData, fields, {}, {});
    // smartScrores = smartScrores.reduce((prev, current) => {
    //   prev[current.student_id] = current.confidence
    //   return prev
    // }, {})
    // filterRoundData.forEach(st => st.confidence = smartScrores[st.student_id])
    this.props.setApplicants(filterRoundData)
    this.props.setScoringApplicants(filterRoundData);
    this.setState({ roundData, filterRoundData, rounds: application.rounds })
  }

  getApplicants = async () => {
    let { params } = this.state
    store.dispatch(setLoading(true))
    try {
      let apiResp;
      if (params.campus_job_id) apiResp = await Auth.Recruiter.getSpecificApplicants(params.campus_job_id)
      else {
        apiResp = await Auth.Process.getApplicants({ campus_job_id: params.campus_job_id })
      }
      this.setState({ applicants: apiResp.data.data.applicants || apiResp.data })
      await this.getRoundData(apiResp.data.data || apiResp.data)
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  handleShortlistStudents = (e)=>{
    this.props.setStudentList(e.target.value);

  }

  render() {
    let { filterRoundData, studentEvaluation , smartScoringStudents, rounds, params ,isOpenModal, searchText , evaluatorApplicants, selectedStudentCV, readMoreComment } = this.state,
      { user, scoringApplicants , selectedStudents } = this.props;
    let applicants = scoringApplicants;
    applicants = applicants.filter(data=>{
      let studentName = getFullName(data.student);
      if (studentName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
        return data;
    })
    if(evaluatorApplicants && applicants.length){
      let tempApplicants=[];
      evaluatorApplicants.forEach(item=>{
        let student = applicants.filter(data=>{if(item.student_id == data.student_id) return data});
        if(student.length){
          tempApplicants.push(student[0]);
        }
      })
      applicants = tempApplicants;
    }
    let userEvaluationData = studentEvaluation.filter(evaluation => evaluation.user_id == user.id)[0] || {}
    return (
      <div className="profile">
        <div className="my-5">
          <Row>
            <Col xs={6}>
              <div className="">
                <div className="">
                  <input type="text" className="w-100 r-inputfield pr-5" placeholder="Search here" onChange={(e)=>this.setState({searchText : e.target.value})} />
                  <span className="search-icon">
                    <i className="fi flaticon-search"></i>
                  </span>
                </div>
                {/*<Select value={params.round_id} name="roundId" onChange={({ target }) => this.props.history.push(`/evalutate/applications/${params.campus_job_id}/round/${target.value}`)}>
                  <option value="0">Initial Shortlisting</option>
                  {rounds.map(r => {
                    return <option key={r.application_round_id} value={r.application_round_id}>{r.round_name}</option>
                  })}
                </Select>*/}
                <ul className="nav shortlist_span mt-2">
                  <li className="nav-item">
                    <a className="nav-link r-subheading-3  r-text--grey-4" href="#">Shortlist : </a>
                  </li>
                  {/*<li className="nav-item">
                    <a className="nav-link heading-4" href="#">Select </a>
                  </li>*/}
                  <li className="nav-item">
                    <span className="shortlist_span r-text--blue-3">{ Object.keys(selectedStudents).length ? Object.keys(selectedStudents).filter(item=>{if(selectedStudents[item]) return item}).length : 0}</span>
                  </li>
                </ul>
              </div>
              <Button onClick={this.props.toggleFilter}  color="blue">Filter<img title="Filters" style={{cursor : "pointer"}} alt="Filter" src="/images/filter-512.png" width="15" /></Button>
            </Col>
            <Col xs={6}>
              <div className="">
                <ul className="nav justify-content-end">
                  <li className="nav-item">
                    <h1 className="r-subheading-1">
                      {
                        filterRoundData ?this.props.evalution ?
                                      `
                                        ${applicants.length}

                                        of

                                        ${applicants.length}
                                      ` :
                          `
                                      ${scoringApplicants.length}

                                      of

                                      ${scoringApplicants.length}

                                      `
                          :
                          null
                      }
                    </h1>

                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>

        <div className="profile-card">
          <Row>
            {
              applicants ?
                applicants.map((data, i) => {
                  let confidenceRate = data.confidence || 0
                  return (
                    <Col xs={4} key={i}>
                      <div className="card r-shadow mb-5"  style={{cursor: "pointer"}}>
                        <div className="card-body">
                          <Row noGutters>
                            {/*<Col xs={2}>
                              <img src="/images/dummy-pic.png" alt="" className="img-fluid" />
                            </Col>*/}
                            <Col className="d-flex justify-content-between">
                              <div className="px-3" onClick={() => this.toggleOpenModal(data)}>
                                <p className="r-heading-4 mb-0" >
                                  {getFullName(data.student)}
                                </p>
                                {/* {user !== null && <p><span className="r-text--sm">Score:</span><span className="r-subheading-2 ml-2">{confidenceRate}</span></p>} */}
                              </div>
                            <input type="checkbox" value={data.student_id} onChange={this.handleShortlistStudents} />
                            </Col>
                          </Row>
                          {/*<div className="mt-4 border-top">
                            <div className="text-right pt-2" >
                              <p> Yes | No </p>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                    </Col>

                  )
                })

                :

                null

            }
          </Row>
          <Modal size="lg" onOpened={this.fetchCV} className="modal-dialog-centered pt-4" isOpen={!!this.state.isOpenModal} toggle={() => this.toggleOpenModal(false)}>
                <ModalHeader ><strong>Student CV</strong></ModalHeader>
                <ModalBody>
                  <Row>
                    <Col xs={7} className="text-center">
                      {selectedStudentCV && selectedStudentCV.file_url ? 
                      <object style={{width : "100%", minHeight : 450}} data={selectedStudentCV.file_url}>Not able to fetch CV</object>
                      :
                      <h4 className="mt-5">{selectedStudentCV ? "CV Not Found" : "Loading ..."}</h4>
                      }
                    </Col>
                    <Col xs={5}>
                      <form onSubmit={this.updateEvaluationStatus}> 
                    <textarea required class="form-control" rows="5" name="comment" className="form-control" type="text" placeholder="Type your comment here" onChange={this.setComments} defaultValue={userEvaluationData.evaluation_comment || ""}/>
                        <div>
                          <Button type="submit" className="r-text--sm  mt-3" color="blue">Save Comment</Button>
                        </div> 
                      </form>
                        {
                          studentEvaluation.filter(data => !!data.evaluation_comment).map((data, i) => 
                          <Row key={i} className="mt-3">
                            <Col style={{fontSize : 12}}>
                              <p className="mb-0">{data.evaluation_comment.length > 100 && readMoreComment !== data.evaluation_id ? data.evaluation_comment.substring(0, 100):data.evaluation_comment}{data.evaluation_comment.length > 100 && readMoreComment !== data.evaluation_id && <Button onClick={() => this.setState({readMoreComment : data.evaluation_id})} color="link-blue"> . . . more</Button>}</p>
                              <em className="float-right text-muted">- {getFullName(data.userDetails)}</em>
                            </Col>
                          </Row>
                          )
                        }
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter style={{height: "45px"}}>
                {/* <Button type="button" size="sm"  onClick={() => this.toggleOpenModal(false)}>Cancel</Button> */}
            </ModalFooter>
               
          </Modal>
        </div>
      </div>

    )
  }
}
const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setApplicants: (applicants) => dispatch(setApplicants(applicants)),
  setScoringApplicants: (scoringApplicants) => dispatch(setScoringApplicants(scoringApplicants))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileCard))
