import { combineReducers } from 'redux';
import user from './user';
import loader from './loader';
import role from './role';
import error from './error';
import applicants from './applicants';
import scoringApplicants from './scoring_applicants';
import success from './success';
import confirm from './confirm';
import db from './db';

export default combineReducers({
  user,
  loader,
  role,
  error,
  success,
  confirm,
  db,
  applicants,
  scoringApplicants
});
