import React,{Component} from 'react';
import ReactQuill from 'react-quill';

export class RTE extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modules : {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{ 'color': [] }, { 'background': [] }],
          ['clean']
        ],
      },

      formats : [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent','color','background'
      ]
    }
  }
  render() {
    return (
      <ReactQuill className={"r-rte" + (this.props.hideToolbar?" r-rte-toolbar-hide":"")} {...this.state} {...this.props} ref={this.props.inputRef}/>
    )
  }
}
