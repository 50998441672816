import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from '../../global/Button';
import Institute from './institute-list';
import store from '../../../store';
import { setError, setLoading, setSuccess } from '../../../actions/action-creators';
import { getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import { AddProcessModal } from './add-process';
import AutosuggestComp from '../../global/AutosuggestComp';
import { Message } from '../../../constants/messages';
import { JobCampusModal } from './job-campus-modal';
import { CONSTANTS } from '../../../constants';


class Recruitment extends Component {
  state = {
    selectedProcess: null,
    processList: [],
    isOpenJobDetail: false,
    addProcessModal: false,
    campusList: [],
    allCampusList: [],
    suggestionCampus: "",
    suggestionCampusId: null,
    jobCampusIdModal: null,
    jobs: {},
    applicants: {}
  }
  componentDidMount() {
    this.init()
  }

  handleCampusChange = (e, { newValue, method }) => {
    let { suggestionCampus, suggestionCampusId } = this.state
    if (newValue !== undefined) {
      if (typeof newValue === "string") {
        suggestionCampus = newValue
        suggestionCampusId = null
      }
      else {
        suggestionCampus = newValue.name
        suggestionCampusId = newValue.campus_id
      }
      this.setState({ suggestionCampus, suggestionCampusId })
    }
  };

  init = async () => {
    store.dispatch(setLoading(true))
    try {
      let processResp = await Auth.Process.getProcess()
      this.setState({processList: processResp.data.data})
      store.dispatch(setLoading(false))
      if (processResp.data.data[0]){
         await this.getProcessCampus(processResp.data.data[0])
      }
    } catch (e) {
      store.dispatch(setLoading(false))
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  getProcessCampus = async (process) => {
    store.dispatch(setLoading(true))
    try {
      let campusResp = await Auth.Process.getCampusProcess({ recruiter_process_id: process.recruiter_process_id })
      let campusList = campusResp.data.data
      await this.getCampusJobs(process, campusList)
      this.setState({ campusList, selectedProcess: process })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  getCampusJobs = async (process, campusList) => {
    try {
      let jobsResp = await Auth.Process.getCampusJob({ recruiter_process_id: process.recruiter_process_id })
      let jobs = {}
      campusList.forEach(campus => {
        campus.jobs = []
        jobsResp.data.data.forEach(job => {
          jobs[job.job_template_id] = { job_template_id: job.job_template_id }
          if (job.campus_id === campus.campus_id)
            campus.jobs.push(job)
        })
      })
      let jobIds = Object.keys(jobs)
      if (jobIds.length) {
        let jobTemplateResp = await Auth.JobTemplate.fetch({ job_template_id: jobIds.toString() })
        jobTemplateResp.data.data.forEach(j => {
          jobs[j.job_template_id] = j
        })
      }
      this.setState({ campusList, jobs })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  getAllCampus = async () => {
    store.dispatch(setLoading(true))
    try {
      let campusResp = await Auth.Project.getCampus()
      let allCampusList = campusResp.data.data
      this.setState({ allCampusList })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  toggleJobDetailCollapse = async (job_id) => {
    if (job_id && this.state.isOpenJobDetail !== job_id)
      await this.getApplicants(job_id)
    this.setState({ isOpenJobDetail: this.state.isOpenJobDetail === job_id ? false : job_id })
  }

  getApplicants = async (campus_job_id) => {
    let { applicants, campusList } = this.state;
    let isPublished = false
    campusList.forEach(campus => {
      let campusJob = campus.jobs.filter(job => { return job.campus_job_id === campus_job_id && job.receive_status === 1 })[0]
      if (campusJob) isPublished = true
    })
    if (!isPublished) return
    store.dispatch(setLoading(true))
    try {
      let applicantsResp = await Auth.Process.getApplicants({ campus_job_id })
      applicants[campus_job_id] = {
        eligible: [],
        applied: [],
        rounds: applicantsResp.data.data.rounds,
        applicants: applicantsResp.data.data.applicants
      }
      applicantsResp.data.data.applicants.forEach(student => {
        if (student.eligibility_status === CONSTANTS.ELIGIBILITY_STATUS.ELIGIBLE)
          applicants[campus_job_id].eligible.push(student)
        if (student.student_application_status === CONSTANTS.STUDENT_APPLICATION_STATUS.APPLIED)
          applicants[campus_job_id].applied.push(student)
      })
      this.setState({
        applicants
      })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  campusSearch = (query) => {
    return new Promise((resolve, reject) => {
      let { allCampusList } = this.state;
      let filteredCampus = allCampusList.filter(campus => {
        if (campus.name.toLowerCase().indexOf(query.toLowerCase()) > -1)
          return campus
      })
      resolve({ data: filteredCampus })
    })
  }

  mapCampusToProcess = async (e) => {
    e.preventDefault()
    let { selectedProcess, suggestionCampusId } = this.state;
    if (!suggestionCampusId) {
      store.dispatch(setError({ showBar: true, message: Message.MANDATORY.SELECT_CAMPUS }))
      return
    }
    store.dispatch(setLoading(true))
    try {
      await Auth.Process.addCampus({ recruiter_process_id: selectedProcess.recruiter_process_id, campus_id: suggestionCampusId })
      store.dispatch(setSuccess({ showBar: true, message: Message.SUCCESS.PROCESS_CAMPUS[1] }))
      store.dispatch(setLoading(false))
      await this.getProcessCampus(selectedProcess)
      this.setState({ allCampusList: [], suggestionCampus: "", suggestionCampusId: null })
    } catch (e) {
      console.log(e)
      store.dispatch(setLoading(false))
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  toggleJobCampus = (jobCampusIdModal = null) => {
    this.setState({ jobCampusIdModal })
  }

  render() {
    let { addProcessModal, processList, selectedProcess, campusList, jobCampusIdModal, allCampusList, suggestionCampus, jobs, applicants } = this.state
    return (
      <div className="recruitment">
        <div className="container">
          <div className="top-navigation border-bottom mt-3">
            <Row>
              <Col xs={12}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <div className="d-inline-block mr-3">
                        <Button className="mr-3" onClick={() => this.setState({ addProcessModal: true })} color="blue-3"><i className="fi flaticon-plus-1 r-text--sm mr-2"></i> Drive</Button>
                        {
                          processList.map((p, i) => {
                            return (
                              <Button className="mr-3 my-3" key={i} onClick={() => this.getProcessCampus(p)} color={selectedProcess && (p.recruiter_process_id == selectedProcess.recruiter_process_id) ? "blue-3" : "blue"}>{p.process_name}</Button>
                            )
                          })
                        }
                      </div>
                    </li>
                  </ol>
                </nav>
              </Col>
            </Row>
          </div>
          <AddProcessModal isOpen={addProcessModal} toggle={() => this.setState({ addProcessModal: false })} callback={this.init} />
          <div className="my-5">
            <Row>
            <Col xs={8}>
                <div className="r-text--blue-3">
                  <Button onClick={this.getAllCampus}>
                    <i className="m-2 r-text--sm fi flaticon-plus-1"></i>
                    Add Institute
                    </Button>
                </div>
              </Col>
              <Col xs={4}>
                <input className="w-100 r-inputfield" type="text" name="first_name" placeholder="Search" />
                <i className="r-text--grey-4 fi r-input_field_icon flaticon-search"></i>
              </Col>
            </Row>
          </div>
          <div className="institute-component my-5">
            <Row className="border-bottom pb-2">
              <Col xs={5}>Institute Name</Col>
              {/*<Col xs={2}>Assignee</Col>*/}
              <Col xs={2}>Created On</Col>
              <Col xs={3}>Application Status</Col>
            </Row>
            {!processList.length && 
              <Row>
              <Col xs={12}>
                <button 
                  onClick={() => this.setState({ addProcessModal: true })}
                  className="w-100 p-3 m-4 r-btn r-btn--blue r-btn--no-radius" 
                  style={{border: "1px dashed #6a7b7b", color: "#94a7b9", borderRadius: 6, fontStyle: "italic", background: "#eaf3f3"}} 
                  >
                    Click to create your first Campus Drive here. Ex- Final Placements 2023
                  </button>
              </Col>
            </Row>
            }
            {!campusList.length && !!selectedProcess && 
              <Row>
              <Col xs={12}>
                <button 
                   onClick={this.getAllCampus}
                  className="w-100 p-3 m-4 r-btn r-btn--blue r-btn--no-radius" 
                  style={{border: "1px dashed #6a7b7b", color: "#94a7b9", borderRadius: 6, fontStyle: "italic", background: "#eaf3f3"}} 
                  >
                    Add your first institute here that you want to recruit from to this drive
                  </button>
              </Col>
            </Row>
            }
            {
              (allCampusList.length > 0 && selectedProcess) && <Row className="r-bg--blue-2 py-4">
                <Col xs={4}>
                  <form onSubmit={this.mapCampusToProcess}>
                    <div className="position-relative">
                      <AutosuggestComp
                        inputProps={
                          {
                            className: "w-100 r-inputfield-2",
                            onChange: this.handleCampusChange,
                            value: suggestionCampus,
                            name: 'suggestionCampus',
                            placeholder: 'Type institute name here'
                          }
                        }
                        getSuggestions={this.campusSearch}
                        renderSuggestion={(campus) => <div>{campus.name}</div>} />
                      <i className="fi flaticon-search r-inputfield-2--icon" />
                    </div>
                  </form>
                </Col>
              </Row>
            }
            {campusList.map((data, index) =>
              <Institute getCampusJobs={async () => await this.getCampusJobs(selectedProcess, campusList)} jobList={jobs} toggleJobCampus={this.toggleJobCampus} key={index} instituteData={data} isOpenJobDetail={this.state.isOpenJobDetail} toggleJobDetailCollapse={this.toggleJobDetailCollapse} applicants={applicants} />
            )}
            {selectedProcess !== null && <JobCampusModal processId={selectedProcess.recruiter_process_id} campusId={jobCampusIdModal} toggleModal={this.toggleJobCampus} callback={() => this.getProcessCampus(selectedProcess)} />}
          </div>
        </div>
      </div >

    )
  }
}
export default Recruitment;
