import React from 'react';
import { Row,Col,Container,UncontrolledCollapse,CardBody, Card,Collapse,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import Button from '../../global/Button';
import { Auth } from "../../../actions/Auth";
import { CONSTANTS } from '../../../constants';
import { uploadFile, getFiles } from '../../../actions';
import About from './About.js';
import store from "../../../store";
import {getErrorMessage } from '../../../actions';
import { setLoading, setConfirm, setError, setSuccess } from "../../../actions/action-creators";
import Home from './Home.js';
import { Loader } from '../../global/Loader';
import { HorizontalLine } from '../../global/horizontal_line';
import Aux from '../../global/AuxComp';



class ProfilePage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading : false,
      isLoadingProfile : true,
      sideNavLeft: null,
      sideNavRight: null,
      fileurl: null,
      file: '',
      logoUrl : "",
      bannerUrl : "",
      tab: props.match.params.recruiter_id ? "about":"home",
      companyProfile:{},
      isPTView : false,
      campusList : [],
      isOpenReport : false,
      reportOverview : {},
      jobList: [],
      isLoadingReporOverview : false,

    };
     
  }

  componentDidMount(){
    this.init();
    this.getCollegeList();
    this.fetchJobs();
  }

  init = async () =>{
    store.dispatch(setLoading(true))
      let {companyProfile} = this.state;
        let fileIds = [];
        var body = {};
        try{
          if (this.props.match.params.recruiter_id) {
            body.recruiter_id = this.props.match.params.recruiter_id;
            this.setState({isPTView : true})
            let campusUserResp = await Auth.User.getCampusUser();
            body.campus_id = campusUserResp.data[0].current_role_info.campus_id
          }
          let res =  await Auth.General.getCompanyProfile(body);
          if(res.data.data && res.data.data.length>0){
            if (res.data.data[0].logo_file_id){
                fileIds.push(res.data.data[0].logo_file_id);
            }
            if(res.data.data[0].banner_file_id){
                fileIds.push(res.data.data[0].banner_file_id);
            }
            let files = await getFiles(fileIds);
            companyProfile = res.data.data[0];
            companyProfile.logoUrl = files[res.data.data[0].logo_file_id];
            companyProfile.bannerUrl = files[res.data.data[0].banner_file_id];
            this.setState({companyProfile});
          }
        }
        catch(err){
          console.log(err)
        }
      this.setState({isLoadingProfile : false})
    store.dispatch(setLoading(false))
  }


  changeTab = (e) => {
    this.setState({tab: e.target.dataset.tabid})
    window.scrollTo(0, 0)
  }

  changeLogoImage = async (e) => {
    store.dispatch(setLoading(true))
    try{
      let fileResp = await uploadFile(e.target.files[0], "/recruiter/profile/")
      await this.companyProfileChange("logo_file_id",fileResp.file_id)
      await this.handleSubmit();
    }
    catch(err){
      console.log(err)
    }
    store.dispatch(setLoading(false))
  }

  changeBannerImage = async (e) => {
    store.dispatch(setLoading(true))
    try{
      let fileResp = await uploadFile(e.target.files[0], "/recruiter/profile/")
      await this.companyProfileChange("banner_file_id", fileResp.file_id)
      await this.handleSubmit();
    }
    catch(err){
      console.log(err)
    }
    store.dispatch(setLoading(false))
  }

  companyProfileChange = (key, value) => {
    let { companyProfile } = this.state;
    companyProfile[key] = value;
    this.setState({companyProfile});
  }

  changeView = () => {
    this.setState({isPTView : !this.state.isPTView})
    this.setState({tab: 'about'})   
    window.scrollTo(0, 0)
  }

  getCollegeList = async () => {
    store.dispatch(setLoading(true))
    try {
      var body = {};
      if (this.props.match.params.recruiter_id) {
        body.recruiter_id = this.props.match.params.recruiter_id;
        this.setState({isPTView : true})
       }
      let recruiterCampus = await Auth.General.getAllCampus(body)
      let campusList = recruiterCampus.data.data
       this.setState({
         campusList
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  handleSubmit = async () =>{
    let body = {
      logo_file_id:this.state.companyProfile.logo_file_id,
      banner_file_id:this.state.companyProfile.banner_file_id,

    }
    try{
      let res =  await Auth.General.addCompanyProfile(body);
      this.init();
    }
    catch(err){
      console.log(err.response);
    }
  }

  fetchReportOverview = async() =>{
    this.setState({isLoadingReporOverview : true})
    try{
        var body = {
            job_template_id : this.state.isOpenReport
        }
        let reportOverviewResp = await Auth.JobTemplate.fetchStatistics(body);
        let data = reportOverviewResp.data.data.length ? reportOverviewResp.data.data[0] : {};
        this.setState({reportOverview: data})
    }
    catch(e){
        console.log(e);
    }
    this.setState({isLoadingReporOverview : false})
  }

  fetchJobs = async () => {
    var resp = await Auth.JobTemplate.fetch();
    let jobsResp = await Auth.Process.getCampusJob({})
    var jobList = resp.data.data
    await this.setState({ jobList , campusJobs : jobsResp.data.data, isOpenReport : jobList.length && jobList[0].job_template_id});
    this.fetchReportOverview()
  };

  render() {
    const {companyProfile,  isLoadingReporOverview, tab, isPTView, isLoading, isLoadingProfile, jobList} = this.state;
    var connectedCampusCount = this.state.campusList.filter(campus => campus.connection_status === 1).length;

    return (
     
        <Container>
           <Loader show={isLoading}/>

            {<div className=" p-0" style={{backgroundColor:"#fff" ,  position: "sticky", top : "-190px", zIndex : 99}}>
                {
                  companyProfile.bannerUrl && companyProfile.bannerUrl[0].url ?
                  <div className="student__profile__image__container">
                     <img style={{height:"250px", width:'100%'}} src={companyProfile.bannerUrl && companyProfile.bannerUrl[0].url} alt="Cover Photo" />
                     { !isPTView &&<span className="fi flaticon-edit-2 student__profile__image--edit">
                        <input title="Update College Logo" type="file" accept="image/png, image/jpeg" className="student__profile__image--upload" onChange={this.changeBannerImage} />
                      </span>}
                  </div>
                  :
                  <div className="mt-0" style={{height:"250px", width:'100%', background:'#aec7d8'}}>
                    <Row className="text-center w-100 h-100 align-items-center">
                      <Col>
                      { !isPTView && <input title="Update College Banner" type="file" accept="image/png, image/jpeg"  onChange={this.changeBannerImage} />}
                      </Col>
                    </Row>
                  </div>
                }
              <Row>
                <Col xs={8}>
                  <Row className="ml-3 py-3">
                    <Col style={{maxWidth: '125px'}} className="mx-0 px-0 student__profile__image__container">
                      <img  style={ {marginTop:'-35px',background: "white", objectFit : "fill"}} src={(companyProfile.logoUrl && companyProfile.logoUrl[0].url) || "/images/dummy_college.png"} className="img-fluid student__profile__image w-100" alt="Profile Image" />
                     { !isPTView && <span  style={ {marginTop:'-45px'}} className="fi flaticon-edit-2 student__profile__image--edit" >
                        <input title="Update College Logo" type="file" accept="image/png, image/jpeg" className="student__profile__image--upload" onChange={this.changeLogoImage} />
                      </span>
                      }
                    </Col>
                    <Col xs={9}  className="mx-0 px-0 pl-4">
                        <span className="mb-0 r-subheading-1 text-capitalize"  >{this.state.companyProfile.company_name||""}</span><br></br>
                        <span>connections</span>
                        <span className="pl-3" style={{fontWeight: 700}}>{connectedCampusCount}</span>

                    </Col>
                  </Row>
                </Col>
                
              </Row>
              <HorizontalLine color="#cfcfcf" />

            </div>}

                 <Row className="mt-5 ">
                      <Col xs={2} className="left-sidenav pr-0">
                            <div style={{position : "sticky", top : 165}}>
                              <ul className="r-sidenav__menu">
                                { !isPTView ? 
                              <Aux>{
                                CONSTANTS.COLLEGENAV.map(nav => {
                                  return (
                                    <li
                                      key={nav.id}
                                      onClick={this.changeTab}
                                      data-tabid={nav.id}
                                      className={
                                        "r-heading-6 " +
                                        (tab === nav.id ? "sidenav-active" : "")
                                      }
                                    >
                                      {nav.text}
                                    </li>
                                  )
                                }) }
                                </Aux>
                                  :
                                  <Aux>
                                  <li onClick={()=> {this.setState({tab: "about"})}} data-tabid={"about"} className={"r-heading-6 " + (tab === "about" ? "sidenav-active" : "")}>
                                  About
                                  </li>
                                  </Aux>
                                }
                        
                                {this.props.user ? 
                                  <li onClick={this.changeView} className={"r-heading-6"}>
                                      {!this.state.isPTView ? "View Public Profile":"Back"}
                                   </li> : null
                                   }
                               </ul>
                               {!isPTView ?  <div className="mt-4 ml-4 pl-2" style={{fontSize: "17px"}}>
                                                 <hr />
                                                 No. of views
                                                 <div className="border border-primary p-4 text-center mt-3" style={{fontWeight: 600, fontSize : 20, borderRadius : 15, width : 70}}>{companyProfile.visitor_count || 0}</div>
                                               </div>
                                     : " "}
                          
                            </div>
                          </Col>
                        
                   
                    <Col xs={7}>
                          {tab === "home" && <Home />}
                          {tab === "about" && <About companyProfile={companyProfile} isPTView={isPTView} />}
                    </Col>

                    <Col xs={3}>
                        <div className="on-hover-high-zindex pb-4" style={{position : "sticky", top : 160, background : "#fff"}}>
                          <h2 className="r-heading-4 r-text--dark text-center">Job Statistics</h2>
                          <HorizontalLine  className="mt-2" color="#cfcfcf" />
                          { jobList.map((job, i)=> 
                        <Row key={i}>
                          <Col className="">
                            <Button className="mt-3" style={{width: "80%", marginLeft: "10%"}} onClick={()=>{this.setState({isOpenReport: this.state.isOpenReport == job.job_template_id ? false : job.job_template_id, reportOverview : {}})}} color="blue-3" >
                              {job.role}
                            </Button>
                            <Collapse onEntering={this.fetchReportOverview} className="mt-3"  isOpen={job.job_template_id == this.state.isOpenReport ? true : false}>
                              <Card className="p-1">
                                <CardBody className="pb-0">
                                  {isLoadingReporOverview ? <div className="center-xy"><Spinner animation="border" variant="dark" /></div> :
                                  <div>

                                      <Row className="mb-2 border-bottom">
                                          <Col xs={8}><span className="r-subheading-5">Vacancies</span></Col>
                                          <Col className="text-right" xs={4}>{this.state.reportOverview.vacancy }</Col>
                                      </Row>

                                      <Row className="border-bottom mt-3">
                                          <Col xs={8}><span className="r-subheading-5">Campuses Invited</span></Col>
                                          <Col className="text-right" xs={4}>{this.state.reportOverview.no_of_campus }</Col>
                                      </Row>

                                      <Row className="mt-3 border-bottom">
                                          <Col xs={8}><span className="r-subheading-5">Total Applications</span></Col>
                                          <Col className="text-right" xs={4}>{this.state.reportOverview.applicants_count}</Col>
                                      </Row>

                                      <Row  className="mt-3 ">
                                          <Col xs={8}><span className="r-subheading-5">Total Offers </span></Col>
                                          <Col className="text-right" xs={4}>{this.state.reportOverview.offer_count}</Col>
                                      </Row>
                                     
                                    </div>}
                                  </CardBody>
                                </Card>
                              </Collapse>
                              </Col>
                            </Row>
                          )
                          }
                        </div>
                    </Col>
            </Row>
        </Container>
    );
  }
}

const mapStateToProps = state => ({
  user : state.user,
  role : state.role
})

export default connect(mapStateToProps, null)(ProfilePage)
