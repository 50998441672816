import React, { Component } from 'react'
import Filters from './filters';
import { connect } from 'react-redux';
import Collapse from 'reactstrap/lib/Collapse';
import Button from '../../global/Button';
import Aux from '../../global/AuxComp';
import withRouter from 'react-router-dom/withRouter';

class Sidenav extends Component {
  state = {
    isOpen: ""
  }
  toggleNav = (section) => {
    let { isOpen } = this.state
    if (isOpen === section)
      this.setState({ isOpen: "" })
    else
      this.setState({ isOpen: section })
  }
  render() {
    let { isOpen } = this.state,
      {user} = this.props;
      const style =  {
        height: "100%",
        width: this.props.isOpen ? 300 : 0,
        position: "fixed",
        zIndex: "1",
        top: 0,
        left: 0,
        backgroundColor: "#ebf3fb",
        overflowX: "hidden",
        transition: "0.5s",
        paddingTop: 50
      }
    return (
      <div className="" style={style}> 
      <div className="mb-5">
        <Button onClick={this.props.toggle} className="rotate--45 float-right"><i className="r-text--blue-3 fi flaticon-plus"></i></Button>
      </div>
        {/* <div className="shadow-sm m-3 r-bg--light r-text--blue-3">
          <Button className="w-100 p-3" onClick={() => this.toggleNav("filters")}>Filters</Button>
        </div> */}
        {/* <Collapse isOpen={isOpen === "filters"}> */}
          <Filters section="filters" setFilters={this.props.setFilters} />
        {/* </Collapse> */}
        {/*{user!==null && <Aux>
          <div className="shadow-sm m-3 r-bg--light r-text--blue-3">
            <Button className="w-100 p-3" onClick={() => this.toggleNav("scoring")}>Smart Scoring</Button>
          </div>
          <Collapse isOpen={isOpen === "scoring"}>
            <Filters section="scoring" setFilters={this.props.setFilters} />
          </Collapse>
        </Aux>}*/}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidenav));
