export const ACTIONS = {
  SET_USER: "SET_USER",
  SET_LOADER: "SET_LOADER",
  SET_ROLE: "SET_ROLE",
  SET_ERROR: "SET_ERROR",
  SET_CONFIRM: "SET_CONFIRM",
  SET_SUCCESS: "SET_SUCCESS",
  SET_APPLICANTS: "SET_APPLICANTS",
  SET_SCORING_APPLICANTS : "SET_SCORING_APPLICANTS"
}
