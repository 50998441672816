import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Aux from '../../global/AuxComp';
import JobStatus from './job_status';
import JobDetail from './job_detail_collapse';
import Button from '../../global/Button';
import moment from 'moment';

export default class Institute extends Component {

  render() {
    let { instituteData, toggleJobCampus, jobList, getCampusJobs, applicants , isOpenJobDetail } = this.props
    return (
    <div className="py-4 border-bottom ">
      <Row>
        <Col xs={5}>
          {
            <h3 className="r-heading-4 r-text--brandI">{instituteData.name}</h3>
          }
          <span className="">{instituteData.location}</span>
        </Col>
        {/*<Col xs={2}>
          <div className="r-dropdown-2  ">
            <div className="r-dropdown mr-2">
              <select defaultValue={1} className="r-dropdown__select ">
                <option value="1">Vidyarthi</option>
                <option value="2">Kajal</option>
                <option value="3">Akshay</option>
                <option value="4">Anshul</option>
              </select>
              <i className="fi flaticon-arrow"></i>
            </div>
          </div>
        </Col>*/}
        <Col xs={2}>
          <div className="r-bg--blue-3 text-center p-2">
            <span className="r-text--sm r-text--light">{moment(instituteData.created_on).format("Do MMMM YYYY")}</span>
          </div>
        </Col>
        <Col xs={5}>
          <div className="mb-3">
            <Row className="no-gutters">
              <Col xs={12}>
                <Button onClick={()=>toggleJobCampus(instituteData.campus_id)} square color="blue" className="p-2 w-100">
                  <i className="fi flaticon-plus-1 mr-4" />Click to Add Jobs
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {
        instituteData.jobs.map((j,i) => {
          let jobData = {...jobList[j.job_template_id], ...j}
          return (
            <Aux key={i}>
              <JobStatus getCampusJobs={getCampusJobs} jobData={jobData} onClick={() => this.props.toggleJobDetailCollapse(j.campus_job_id)}/>
              {isOpenJobDetail && <JobDetail campusID={instituteData.campus_id} applicationData={applicants[j.campus_job_id] || {applicants:[],rounds:[],eligible: [],applied: []}} jobData={jobData} isOpen={isOpenJobDetail == j.campus_job_id ? true : false} jobId = {isOpenJobDetail}  />}
            </Aux>
          )
        })
      }
    </div>
    )
  }
}
