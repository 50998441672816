import React, { Component } from 'react'
import Modal from 'reactstrap/lib/Modal';
import { Select } from '../../global/Forms/Select';
import Button from '../../global/Button';
import ModalBody from 'reactstrap/lib/ModalBody';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import { getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import { Message } from '../../../constants/messages';

export default class ProcessModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      processList: [],
      process_id: null
    }
  }

  toggle = () => {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    store.dispatch(setLoading(true))
    try {
      let processResp = await Auth.Process.getProcess()
      this.setState({
        processList: processResp.data.data
      })
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  mapToProcess = (e) => {
    let { process_id } = this.state
    e.preventDefault();
    if (!process_id) {
      store.dispatch(setError({ showBar: true, message: Message.MANDATORY.SELECT_PROCESS }))
      return;
    }
    this.props.callback(process_id)
  }

  render() {
    let { isOpen, toggle } = this.props,
      { processList } = this.state
    return (

      <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">

        <ModalBody>
          <h1 className="r-subheading-3">Select placement drive</h1>
          <form onSubmit={this.mapToProcess}>
            <div className="m-5">
              <Select onChange={(e) => this.setState({ process_id: e.target.value })}>
                <option value="">Select</option>
                {processList.map((process, index) =>
                  <option value={process.recruiter_process_id} key={index}>{process.process_name}</option>
                )}
              </Select>
            </div>
            <Button className="float-right" color="dark">Save</Button>
          </form>
        </ModalBody>

      </Modal>

    )
  }
}
