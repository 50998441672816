import React,{Component} from 'react';
import {Container , Row , Col} from 'reactstrap';
import Button from "../../global/Button";
import { Collapse, CardBody, Card } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Aux from '../../global/AuxComp';
import RButton from '../../global/Button'
import { InputField } from '../../global/Forms';
import { setError, setLoading, setSuccess, setConfirm } from '../../../actions/action-creators';
import { getErrorMessage, getFullName } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
 

class ManageRecruiter extends Component {
  constructor(props) {
    super(props)
    this.state = {
     isOpenAddRecruiter : false,
     isOpenPermission : false,
     isOpenAccess : false,
     processList: [],
     campusList : [],
     addUser : {},
     recruiterList : [],
     selectedCampusId : [],
     selectedProcessId : [],
     selectedPermissionId : [],
     permissionList : []
    }
  }

  componentDidMount() {
    this.fetchProcess()
    this.getCollegeList()
    this.fetchRecruiter()
    this.fetchPermissionList()
  }

  fetchProcess = async () => {
    store.dispatch(setLoading(true))
    try {
      let processResp = await Auth.Process.getProcess()
      this.setState({
        processList: processResp.data.data
      })
      store.dispatch(setLoading(false))
    } catch (e) {
      store.dispatch(setLoading(false))
      console.log(e) 
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  getCollegeList = async () => {
    store.dispatch(setLoading(true))
    try {
      let recruiterCampus = await Auth.General.getAllCampus()
      let campusList = recruiterCampus.data.data
       this.setState({
         campusList
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  fetchPermissionList = async () => {
    store.dispatch(setLoading(true))
    try {
      let resp = await Auth.General.getAllPermission()
      let permissionList = resp.data.data
       this.setState({
        permissionList
       })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  changeAddUser = (e, key) => {
    let { addUser } = this.state;
    addUser[key] = e.target.value;
    this.setState({addUser})
  }

  addUserSubmit = async (e) => {
      e.preventDefault();
    store.dispatch(setLoading(true))
    let { addUser } = this.state;
    try {
      let resp = await Auth.User.signUp({...addUser, user_type : "RECRUITER"})
      var user_id = resp.data[0].id
      let body = {
        user_id ,
        role_id : 2
        }
        await Auth.User.createUserRole(body)
        this.fetchRecruiter();
        this.setState({isOpenAddRecruiter : false, addUser : {} })
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }

  fetchRecruiter = async () => {
    store.dispatch(setLoading(true))
    try {
        let resp = await Auth.User.getUserRole({role_id : 2})
        await Auth.User.getCampusUser();
        this.setState({recruiterList : resp.data.data})
      } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
      }
      store.dispatch(setLoading(false))
      
  }
  
  inactiveRecruiter = async (e, user_role_id) => {
    store.dispatch(setLoading(true))
    try {
        let resp = await Auth.User.inactiveUserRole({user_role_id})
        await this.fetchRecruiter();
    } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
    }
    store.dispatch(setLoading(false))
      
  }
  fetchAccess = async (user_role_id) => {
    store.dispatch(setLoading(true))
    let { recruiterList } = this.state;
    try {
        let resp = await Auth.Recruiter.fetchAccess({user_role_id});
        recruiterList = recruiterList.map(recruiter=> {
            if(recruiter.user_role_id === user_role_id) 
                recruiter.access = resp.data.data;
            return recruiter;
        })
        let selectedCampusId = resp.data.data.filter(o => o.access_type === "CAMPUS").map(o => o.ref_id);
        let selectedProcessId = resp.data.data.filter(o => o.access_type === "RECRUITER_PROCESS").map(o => o.ref_id);
        this.setState({recruiterList, selectedCampusId, selectedProcessId })
    } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
    }
    store.dispatch(setLoading(false))
      
  }
  fetchPermissions = async (user_role_id) => {
    store.dispatch(setLoading(true))
    let { recruiterList } = this.state;
    try {
        let resp = await Auth.Recruiter.getPermission({user_role_id});
        recruiterList = recruiterList.map(recruiter=> {
            if(recruiter.user_role_id === user_role_id) 
                recruiter.permission = resp.data.data;
            return recruiter;
        })
        let selectedPermissionId = resp.data.data.map(o => o.permission_id);
        this.setState({recruiterList, selectedPermissionId })
    } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
    }
    store.dispatch(setLoading(false))
      
  }

  selectCampus = (e, campus_id) => {
    let { selectedCampusId } = this.state;
    if (e.target.checked) {
        selectedCampusId.push(campus_id)
      }
      else {
        var i = selectedCampusId.indexOf(campus_id)
        selectedCampusId.splice(i, 1);
      }
    this.setState({selectedCampusId})
  }
  selectPermission = (e, permission_id) => {
    let { selectedPermissionId } = this.state;
    if (e.target.checked) {
      selectedPermissionId.push(permission_id)
      }
      else {
        var i = selectedPermissionId.indexOf(permission_id)
        selectedPermissionId.splice(i, 1);
      }
    this.setState({selectedPermissionId})
  }

  selectProcess = (e, recruiter_process_id) => {
    let { selectedProcessId } = this.state;
    if (e.target.checked) {
        selectedProcessId.push(recruiter_process_id)
      }
      else {
        var i = selectedProcessId.indexOf(recruiter_process_id)
        selectedProcessId.splice(i, 1);
      }
    this.setState({selectedProcessId})
  }

  submitPermission = async (e) => {
    let { selectedPermissionId, isOpenPermission } = this.state;
    store.dispatch(setLoading(true))
    try {
        let permissionBody = {
            user_role_id : isOpenPermission,
            permission_id : selectedPermissionId,
        }
        await Auth.Recruiter.alterPermission(permissionBody)
        this.togglePermissionModel(false);
    } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
      }
      store.dispatch(setLoading(false))
  }
  submitAccess = async (e) => {
    let { selectedProcessId , selectedCampusId, isOpenAccess, recruiterList } = this.state;
    store.dispatch(setLoading(true))
    try {
        let recruiter = recruiterList.filter(recruiter=> recruiter.user_role_id === isOpenAccess)[0];
        let addedCampusId = recruiter.access.filter(o => o.access_type === "CAMPUS").map(o => o.ref_id);
        let addedProcessId = recruiter.access.filter(o => o.access_type === "RECRUITER_PROCESS").map(o => o.ref_id);
        let addProcessId = selectedProcessId.filter(id => addedProcessId.indexOf(id) === -1);
        let removeProcessId = addedProcessId.filter(id => selectedProcessId.indexOf(id) === -1);
        let addCampusId = selectedCampusId.filter(id => addedCampusId.indexOf(id) === -1);
        let removeCampusId = addedCampusId.filter(id => selectedCampusId.indexOf(id) === -1);
        await Promise.all(addCampusId.map(async campus_id => {
            let campusBody = {
                user_role_id : isOpenAccess,
                ref_id : campus_id,
                access_type : "CAMPUS"
            }
            await Auth.Recruiter.assignAccess(campusBody)
        }))
        await Promise.all(addProcessId.map(async recruiter_process_id => {
            let processBody = {
                user_role_id : isOpenAccess,
                ref_id : recruiter_process_id ,
                access_type : "RECRUITER_PROCESS"
            }
            await Auth.Recruiter.assignAccess(processBody)
        }));
        removeCampusId.length && await Auth.Recruiter.inactiveAccess({user_role_id : isOpenAccess, access_type : "CAMPUS", ref_id : removeCampusId})
        removeProcessId.length && await Auth.Recruiter.inactiveAccess({user_role_id : isOpenAccess, access_type : "RECRUITER_PROCESS", ref_id : removeProcessId})
        this.toggleAccessModel(false);
    } catch (e) {
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
        console.log(e)
      }
      store.dispatch(setLoading(false))
  }
  toggleAccessModel = (user_role_id) => {
      let { isOpenAccess } = this.state;
      if(isOpenAccess === user_role_id){
        isOpenAccess = false;
      }
      else{
        isOpenAccess = user_role_id;
        this.fetchAccess(user_role_id);
      }
    this.setState({isOpenAccess})
  }
  togglePermissionModel = (user_role_id) => {
      let { isOpenPermission } = this.state;
      if(isOpenPermission === user_role_id){
        isOpenPermission = false;
      }
      else{
        isOpenPermission = user_role_id;
        this.fetchPermissions(user_role_id);
      }
    this.setState({isOpenPermission})
  }
  render(){
      let {isOpenAddRecruiter , isOpenPermission , isOpenAccess , processList , campusList , addUser , recruiterList , selectedProcessId , selectedCampusId, permissionList, selectedPermissionId} = this.state ;
      campusList = campusList.filter(campus => campus.connection_status === 1);
    return(
  <Container>
      <Row>
         <Col>
            <Button className=" r-text--sm r-btn r-btn--brandB mt-5" onClick={() => this.setState({isOpenAddRecruiter : !isOpenAddRecruiter}) }>ADD RECRUITER</Button>
                <Collapse isOpen={isOpenAddRecruiter} className="mt-3">
                    <Card>
                    <CardBody>
                        <Row>
                            <Col>
                            <form onSubmit={this.addUserSubmit}>
                                <InputField required className="mr-3" type="text" name="first_name" value={addUser.first_name || ""} onChange={(e) => this.changeAddUser(e, "first_name")}  placeholder="First Name" />
                                <InputField className="mr-3 " type="text" name="last_name"  value={addUser.last_name || ""} onChange={(e) => this.changeAddUser(e, "last_name")}  placeholder="Last Name" />
                                <InputField required type="text" name="email"  value={addUser.email || ""} onChange={(e) => this.changeAddUser(e, "email")} placeholder="Email Address" />
                                <div className="mt-1" style={{marginLeft: "590px"}}>
                                    <RButton type="reset" onClick={()=>{this.setState({isOpenAddRecruiter: false , addUser : {} })}}>Cancel</RButton>
                                    <RButton color="dark" className="r-btn--no-radius">Save</RButton>
                                </div>
                            </form>
                            </Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Collapse>

            <Row className="r-subheading-3 mt-4">
                <Col xs={1}>S.No</Col>
                <Col xs={2}>Name</Col>
                <Col xs={4}>Email Address</Col>
                <Col xs={4}>Access Level</Col>
                <Col xs={1}>Action</Col>
            </Row>

            {
                recruiterList.map((user, index) => 
                <Aux>
                    <Row className="mt-3">
                        <Col xs={1}>{index + 1}</Col>
                        <Col xs={2}>{getFullName(user.userDetails)}</Col>
                        <Col xs={4}>{user.userDetails.email}</Col>
                        <Col xs={4}>
                            <Button color="brandA" className="r-text--sm" onClick={() => this.togglePermissionModel(user.user_role_id) }>Permissions</Button>
                            <Button color="brandA" className="r-text--sm ml-2" onClick={() => this.toggleAccessModel(user.user_role_id)}>Access</Button>
                        </Col>

                        <Col xs={1}>
                            {/* <Button><i class="fi flaticon-edit"></i></Button> */}
                            <Button onClick={(e) => store.dispatch(setConfirm({callback : () => this.inactiveRecruiter(e, user.user_role_id) }))} className="r-text--sm mr-2">
                                <i className="fi flaticon-bin"></i>
                            </Button>
                        </Col>
                    </Row>
                        <Collapse isOpen={user.user_role_id === isOpenPermission}>
                            <Card className="mt-3">
                            <CardBody>
                                <span className="border-bottom r-subheading-2">Permissions</span>
                                <Row className=" mt-3">
                                { permissionList.map(permission => 
                                    <Col xs={6}>
                                        <FormGroup check className="m-4">
                                            <Label check className="">
                                                <Input checked={!!(selectedPermissionId.indexOf(permission.permission_id) >= 0)} onClick={(e) => this.selectPermission(e, permission.permission_id)} type="checkbox" style={{marginLeft : "-20px"}}/>{' '}
                                                    {permission.permission_name}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                )} 
                                </Row>
                                <div className="mt-1" style={{marginLeft: "588px"}}>
                                    <RButton type="reset" onClick={()=>{this.setState({isOpenPermission: false})}}>Cancel</RButton>
                                    <RButton onClick={this.submitPermission} color="dark" className="r-btn--no-radius">Save</RButton>
                                </div>
                            </CardBody>
                            </Card>
                        </Collapse>

                        <Collapse isOpen={user.user_role_id === isOpenAccess}>
                            <Card className="mt-3">
                            <CardBody>
                            <span className="border-bottom r-subheading-2">Access Level</span>
                                <Row className="ml-4" style={{marginTop : "13px"}}>
                                    <Col xs={4}>
                                         <span className="border-bottom r-subheading-3">Campus</span>
                                    </Col>

                                    <Col xs={4} className="ml-4">
                                        <span className="border-bottom r-subheading-3">Placement drive</span>
                                    </Col>
                                </Row>

                                <Row className="ml-4" style={{marginTop : "10px"}}>
                                <Col xs={4} className="shadow mt-2 pt-3" style={{height : "150px" , overflowY : "scroll"}}>
                                        <FormGroup check>
                                            {campusList.map((obj) =>
                                                <Aux>
                                                    <Label check className="mb-3 mt-2">
                                                        <Input checked={!!(selectedCampusId.indexOf(obj.campus_id) >= 0)} onClick={(e) => this.selectCampus(e, obj.campus_id)} type="checkbox" style={{marginLeft : "-20px"}}/>{' '}
                                                            {obj.name}
                                                    </Label> <br></br>
                                                </Aux>
                                             ) }
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col xs={4} className="shadow ml-4 pt-3 mt-2" style={{height : "150px" , overflowY : "scroll"}}>
                                        <FormGroup check>
                                        {processList.map((obj) =>
                                                <Aux>
                                                    <Label check className="mb-3 mt-2">
                                                        <Input  checked={!!(selectedProcessId.indexOf(obj.recruiter_process_id) >= 0)} onClick={(e) => this.selectProcess(e, obj.recruiter_process_id)} type="checkbox" style={{marginLeft : "-20px"}}/>{' '}
                                                            {obj.process_name}
                                                    </Label> <br></br>
                                                </Aux>
                                             ) }
                                        </FormGroup>
                                    </Col>

                                         <div className="mt-1" style={{marginLeft: "588px"}}>
                                            <RButton type="reset" onClick={()=>{this.setState({isOpenAccess: false})}}>Cancel</RButton>
                                            <RButton onClick={this.submitAccess} color="dark" className="r-btn--no-radius">Save</RButton>
                                        </div>
                                </Row>
                               
                            </CardBody>
                            </Card>
                        </Collapse>
                </Aux>
                )
            }
        
           
         </Col>
      </Row>
      </Container>
    )
  }
}

export default ManageRecruiter ;
