import React, { Component } from 'react'
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { InputField } from '../../global/Forms';
import { Select } from '../../global/Forms/Select';
import Button from '../../global/Button';
import { CONSTANTS } from '../../../constants';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import { Message } from '../../../constants/messages';
import { getErrorMessage } from '../../../actions';

export class AddProcessModal extends Component {
  addProcess = async (e) => {
    e.preventDefault();
    let { process_name, process_type } = e.target
    let formData = {
      process_name: process_name.value,
      process_type: process_type.value
    }
    if (!formData.process_name) {
      store.dispatch(setError({ showBar: true, message: Message.MANDATORY.PROCESS_NAME }))
      return
    }
    if (!formData.process_type) {
      store.dispatch(setError({ showBar: true, message: Message.MANDATORY.PROCESS_TYPE }))
      return
    }
    store.dispatch(setLoading(true))
    try {
      await Auth.Process.create(formData)
      store.dispatch(setLoading(false))
      if (this.props.callback) await this.props.callback()
      this.props.toggle()
    } catch (e) {
      store.dispatch(setLoading(false))
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  render() {
    let { isOpen, toggle } = this.props
    return (
      <Modal zIndex="99999" isOpen={isOpen} toggle={toggle}>
        <ModalBody>
          <span className="modal__title r-heading-6">Add Placement Drive</span>
          <form className="mt-3" onSubmit={this.addProcess}>
            <label className="r-subheading-4">Placement Drive Name</label>
            <InputField name="process_name" className="w-100" type="text" placeholder="Enter Placement Drive Name" />
            <label className="mt-2 r-subheading-4">Placement Drive Type</label>
            <Select name="process_type">
              <option value="">Select</option>
              {
                Object.keys(CONSTANTS.PROCESS_TYPES).map(type => <option key={type} value={type}>{CONSTANTS.PROCESS_TYPES[type]}</option>)
              }
            </Select>
            <div className="text-right mt-3">
              <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    )
  }
}