import React from 'react';

export const RTag = (props) => {
  let classes = props.className || "",
    newProps = {
      className: ["r-tag",`r-tag--${props.type||"type-1"}`,!props.delete?"r-tag--no-delete":""].concat(classes.split(" ")).join(" "),
    }
  return (
    <div {...newProps}>{props.children}</div>
  )
}