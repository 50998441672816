import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import AutosuggestComp from '../AutosuggestComp';
import Button from '../Button';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading, setUser , setRole } from '../../../actions/action-creators';
import { getErrorMessage } from '../../../actions';

export class RecruiterModal extends Component {
  state = {
    suggestionValue: {
      company_name: "",
      company_id: null
    }
  }

  handleFieldChange = (e, { newValue, method }) => {
    if (newValue !== undefined) {
      let { suggestionValue } = this.state
      suggestionValue.company_name = (method === "type") ? newValue : newValue.company_name
      suggestionValue.company_id = (method === "type") ? null : newValue.company_id
      this.setState({ suggestionValue })
    }
  };

  setRecruiterCompany = async e => {
    e.preventDefault()
    let { suggestionValue } = this.state,
      reduxState = store.getState();
    if (!suggestionValue.company_name) {
      store.dispatch(setError({ showBar: true, message: "Please enter a company name" }))
      return
    }
    store.dispatch(setLoading(true))
    try {
      if (suggestionValue.company_id === null) {
        let company = await Auth.General.addCompany({ company_name: suggestionValue.company_name })
        suggestionValue.company_id = company.data.insertId
        this.setState({ suggestionValue })
      }
      await Auth.User.createRecruiter({ user_id: reduxState.user.id, company_id: suggestionValue.company_id })
      let user = await Auth.User.getUser()
      store.dispatch(setUser({ ...user.data.data }))
      store.dispatch(setRole({...user.data.data.roles[0]}));
      this.props.toggleRecruiterModal()
    } catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  render() {
    let { recruiterModal, toggleRecruiterModal } = this.props,
      { suggestionValue } = this.state;
    return (
      <Modal className="modal-dialog-centered " zIndex={99999} isOpen={recruiterModal} toggle={toggleRecruiterModal}>
        <ModalBody>
          <form onSubmit={this.setRecruiterCompany}>
            <Row className="mb-3" form>
              <Col>
                <div className="text-center">
                  <h1 className="r-text--blue-3">Select your Company</h1>
                  <p>Please enter a company to proceed</p>
                </div>
              </Col>
            </Row>
            <Row className="mb-3" form>
              <Col xs={12}>
                <AutosuggestComp
                  inputProps={
                    {
                      className: "w-100 r-inputfield",
                      onChange: this.handleFieldChange,
                      value: suggestionValue.company_name,
                      name: 'company',
                      placeholder: 'Type Company here'
                    }
                  }
                  getSuggestions={Auth.General.getCompanies}
                  renderSuggestion={(company) => <div>{company.company_name}</div>}
                />
              </Col>
            </Row>
            <Row form>
              <Col xs={12} className="text-center">
                <Button className="r-btn--no-radius" color="blue-3">Add Company</Button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>

    )
  }

}
