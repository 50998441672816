import React, { Component } from 'react'
import Collapse from 'reactstrap/lib/Collapse';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import withRouter from 'react-router-dom/withRouter';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Aux from '../AuxComp';
import { UserLogin } from './user-login';
import { UserSignup } from './user-signup';

class LoginModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'login'
    }
  }

  render() {
    let { isLogin, toggleLoginModal, toggleRecruiterModal , loader } = this.props
    let { view } = this.state;
    return (
      <Aux>
        {/*<a onClick={toggleLoginModal} id="login-signup" className="r-nav__link" href="#">Login/SignUp</a>*/}
            <div>
              <Row>
                <Col>
                  <div className="p-5" style={{"width" :"35%" }}>
                    <Collapse isOpen={view === 'signup'}>
                      <UserSignup toggleLoginModal={toggleLoginModal} updateView={()=>this.setState({ view: "login"})}/>
                    </Collapse>
                    <Collapse isOpen={view === 'login'}>
                      <UserLogin toggleRecruiterModal={this.props.toggleRecruiterModal} toggleLoginModal={toggleLoginModal} updateView={()=>this.setState({ view: "signup"})}/>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
      </Aux>

    )
  }

}
export default withRouter(LoginModal);
