import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import CreatePost from './create-post';
import PostStatus from './post-status';
import ListPost from './list-post';
import store from '../../../store';
import Link from 'react-router-dom/Link';
import { Auth } from '../../../actions/Auth';
import { Select } from '../../global/Forms/Select';
import { setError, setLoading } from '../../../actions/action-creators';
import { getErrorMessage, getFiles } from '../../../actions';
import Aux from '../../global/AuxComp';

export default class Post extends Component {

  constructor(props) {
    super(props)
    this.state = {
      collegeList: [],
      projectList: [],
      posts: [],
      files: {},
      selectedProject: ""
    }
  }

  componentDidMount() {
    this.getColleges()
  }

  handleStateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  getExitingPost = async () => {
    // let apiRespPost = await Auth.Recruiter.getPost()
    let posts = store.getState().db.post;
    let fileIds = []
    posts.forEach(p => {
      fileIds.push(p.post_banner_id);
      fileIds.push(p.file_id)
    });
    let files = await getFiles(fileIds)
    this.setState({ posts, files })
  }

  getColleges = async () => {
    let collegeList = []
    try {
      store.dispatch(setLoading(true))
      let projectsResp = await Auth.Project.getProjects();
      let processList = store.getState().db.processList;
      processList.map((process) => {
        collegeList = [...collegeList, ...process.institutes]
      })
      this.setState({ collegeList, projectList: projectsResp.data.data, selectedProject: projectsResp.data.data[0] ? projectsResp.data.data[0].project_id : "" })
      await this.getExitingPost()
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      console.log(e)
    }
    store.dispatch(setLoading(false))
  }
  render() {
    let { collegeList, posts, projectList, files, selectedProject } = this.state
    return (
      <div className="post">
        <div className="container">
          <div className="top-navigation border-bottom mt-3">
            <Row>
              <Col xs={6}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Search</a></li>
                    <li className="breadcrumb-item " aria-current="page">List of College</li>
                  </ol>
                </nav>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="align-items-center my-4">
              <Col xs="auto">
                <h2>Create Post</h2>
              </Col>
              <Col xs="auto">
                {projectList.length > 0 ? <Select name="selectedProject" onChange={this.handleStateChange} value={selectedProject}>
                  {
                    projectList.map(({ project_id, profile_name }) =>
                      <option key={project_id} value={project_id}>{profile_name}</option>
                    )
                  }
                </Select> : <p>No Projects added</p>}
              </Col>
            </Row>
          </div>
          {projectList.length > 0 && <Aux>
            <div>
              <Row>
                <Col xs={6}>
                  <CreatePost selectedColleges={collegeList} />
                </Col>
                <Col xs={6}>
                  <PostStatus collegeList={collegeList} />
                </Col>
              </Row>
            </div>
            <div className="exiting-post">
              <ListPost posts={posts} files={files} />
            </div>
          </Aux>}
        </div>
      </div>

    )
  }
}
