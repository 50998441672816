export const Message = {
  SUCCESS: {
    PROCESS_CAMPUS: {
      1: "Campus added to placement drive successfully"
    },
    JOB: {
      JOB_CREATE: "Job created successfully",
      JOB_EDIT: "Job details updated successfully",
      JOB_DELETE: "Job deleted successfully"
    }
  },
  MANDATORY: {
    DESIGNATION: "Please enter a designation",
    PROCESS_NAME: "Please enter a drive name",
    PROCESS_TYPE: "Please select a drive type",
    SELECT_PROCESS: "Please select a placement drive",
    SELECT_CAMPUS: "Please select an institute",
    SELECT_JOB: "Please select a job"
  }
}
