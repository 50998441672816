import React, { Component } from 'react';
export default class ProgressBarCircle extends Component {
    render() {
        let { value } = this.props
        return (
            <div className={`progress--circle progress--${value}`}>
                <div className="progress__number">{value}</div>
            </div>
        )
    }
}
