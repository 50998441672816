import React, { Component } from 'react'
import { Collapse } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { InputField } from '../../global/Forms';
import Button from '../../global/Button';
import Aux from '../../global/AuxComp';
import {CONSTANTS} from '../../../constants';
import {Multifield} from '../../global/Forms/Multifield';

export default class Filters extends Component {
  constructor(props){
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = ()=>{
    return({
      isOpen: [],
      city : [],
      key : "",
      subObject : {},
      sectionType : '',
      subtype : '',
      comparatorObject: {},
      gteComparatorObject : {"X" : '' , "XII" : '' , "GRADUATION" : ""},
      lteComparatorObject : {"X" : '' , "XII" : '' , "GRADUATION" : ""},
      subtypeObj : {"match": {"field" : '', "value" : ''},"range": {"field" : "score_ep" , "value" : {}}},
      filters : {
        simpleFilter : {},
        rangeFilter : {},
        sectionTypeFilter  : {academic_detail : []}
      }
    })
  }

  toggleFilter = async (value) => {

    let { isOpen } = this.state

    let index = isOpen.indexOf(value)

    if (index > -1) {
      isOpen.splice(index, 1)
    } else {
      isOpen.push(value)
    }
    await this.setState({ isOpen })
  }

  handleFilter= async(e)=>{
    let {filters , key} = this.state ;
    let tempFilter = {...filters};
    if(e.target.id =="simpleFilter"){
      tempFilter.simpleFilter[e.target.name] = e.target.value;
    }
    let {subObject} = this.state ;
    let obj = {...subObject};
    if(e.target.id == "rangeFilter" && key){
      if(!obj[e.target.name]){
        obj[e.target.name] = {};
      }
      obj[e.target.name][key] = e.target.value;
      tempFilter.rangeFilter = obj;
    }
    await this.setState({filters : tempFilter , subObject : obj , sectionType : "academic_detail"});
  }

  filterSmarscoringstudents = async()=>{
    await this.formatRangefilter();
    let {filters , city} = this.state;
    let tempFilterCollection = {...filters};
    if(tempFilterCollection.sectionTypeFilter.academic_detail.length > 0){
      tempFilterCollection.sectionTypeFilter.academic_detail.forEach(item=>{
        if(item.range.value && Object.keys(item.range.value).length>0){
          Object.keys(item.range.value).forEach(subItem=>{
            if(!item.range.value[subItem]){
              delete item.range.value[subItem];
            }
          })
        }
      })
    }
    if(city.length>0){
      tempFilterCollection.simpleFilter["location"] = [...city];
    }
    await this.setState({filters : tempFilterCollection});
    // this.setState(this.getInitialState());
    this.props.setFilters({...filters});
  }

  clearSmartScoringFilters = async()=>{
    await this.setState(this.getInitialState());
    await this.setState({sectionType  :"academic_detail"});
    this.filterSmarscoringstudents();
  }

  handleRangeFilter = (e)=>{
    let {comparatorObject , lteComparatorObject , gteComparatorObject} = this.state;
    let key = comparatorObject[e.target.id];
    let lteType = {...lteComparatorObject};
    let gteType = {...gteComparatorObject};
    if(key=="lte"){
      lteType[e.target.id] = e.target.value ;
      this.setState({lteComparatorObject : {...lteType}})
    }
    if(key=="gte"){
      gteType[e.target.id] = e.target.value ;
      this.setState({gteComparatorObject : {...gteType}})
    }
  }

  formatRangefilter = ()=>{
    let {comparatorObject , lteComparatorObject , gteComparatorObject , filters , sectionType , subtypeObj} = this.state;
    let subSectionType = "academic_type";
    let academicTypeFilter  = [...filters.sectionTypeFilter[sectionType]];
    if(Object.keys(comparatorObject).length > 0){
      Object.keys(comparatorObject).forEach(item=>{
        let academicTypeObj = {...subtypeObj};
        academicTypeObj.match = {...subtypeObj.match}
        academicTypeObj.range = {...subtypeObj.range}
        academicTypeObj.match.field = subSectionType;
        academicTypeObj.match.value = item;
        academicTypeObj.range = {field : "score_ep" , value : {}};
        if(lteComparatorObject[item]){
          academicTypeObj.range.value["lte"] = lteComparatorObject[item] ;
        }
        if(gteComparatorObject[item]){
          academicTypeObj.range.value["gte"] = gteComparatorObject[item];
        }
        academicTypeFilter.push({...academicTypeObj});
      })
    }
    let sectionfilter = {...filters};
    sectionfilter.sectionTypeFilter[sectionType] = [...academicTypeFilter];
    this.setState({filters : {...sectionfilter}});
  }

  updateValues = (values)=>{
    this.setState({city : values});
  }

  showSectionInputValues= (subtype , sectionType)=>{
    let {filters , comparatorObject} = this.state;
    let key =  comparatorObject[subtype];
    if(key){
      let temp =   filters.sectionTypeFilter[sectionType].filter(item=>{if(item.match && (item.match.value==subtype) && item.range.value[key] ){ return item}}) || [];
      if(temp.length>0){
        return temp[0].range.value[key];
      }
      return '';
    }
    return '';
  }

  handleComparator = (key , section , subtype)=>{
    this.setState({key : key , sectionType : section , subtype : subtype })
    let {comparatorObject } = this.state;
    let comparator = {...comparatorObject};
    if(key){
      comparator[subtype] = key;
    }
    this.setState({comparatorObject : {...comparator}});
  }

  render() {
    let { isOpen , subObject , key , filters , city , sectionType , subtype , comparatorObject , gteComparatorObject , lteComparatorObject } = this.state,
      { section } = this.props;
    return (
      <Aux>
        <div className=" filters" >
          <ul className="list-group list-group-flush p-3">
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Gender
                  <span className="badge" onClick={() => this.toggleFilter('gender')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("gender") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("gender")}>
              <Col xs={12}>
                <ul className="nav my-3">
                  <li className="nav-item my-2">
                    <label htmlFor="male" className="mr-2">
                      <input type="radio" name="gender" id="simpleFilter" value="male" checked={filters.simpleFilter["gender"] && filters.simpleFilter["gender"]=="male" ? true : false} onChange={this.handleFilter} /> Male
                                  </label>

                    <label htmlFor="female" className="mr-2">
                      <input type="radio" name="gender" id="simpleFilter" checked={filters.simpleFilter["gender"] && filters.simpleFilter["gender"]=="female" ? true : false} value="female" onChange={this.handleFilter} /> Female
                                  </label>
                    <label htmlFor="both">
                      <input type="radio" name="gender"  id="simpleFilter" value="" onChange={this.handleFilter} /> All
                    </label>
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>
                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Age
                  <span className="badge" onClick={() => this.toggleFilter('age')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("age") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("age")}>
              <Col xs={12}>
                <ul className="nav my-3">

                  <li className="nav-item my-2">
                    <label htmlFor="ageGreator" className="mr-2">
                      <input type="radio" name="age" id="ageGreator" value="ageGreator" onChange={()=>this.setState({key : "gte" , sectionType : "academic_detail"})} /> Greater than
                                  </label>
                    <label htmlFor="ageLess">
                      <input type="radio" name="age" id="ageLess" value="ageLess" onChange={()=>this.setState({key : "lte" ,sectionType : "academic_detail"})} /> Less than
                                  </label>
                  </li>
                  <li className="nav-item my-2">
                    <input type="text" className="w-100 r-inputfield" value={Object.keys(subObject).length>0 && subObject["age"] && subObject["age"][key] ?  subObject["age"][key] : ''} name="age" id="rangeFilter" placeholder="Enter Age" onChange={this.handleFilter} />
                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>
                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Location
                  <span className="badge" onClick={() => this.toggleFilter('language')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("language") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("language")}>
              <Col xs={12}>
                <ul className="nav my-3">
                  <li className="nav-item my-2">
                  <Multifield  values={city} updateValues={(values)=>this.updateValues(values)} placeholder="Type and press enter" />
                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>
                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Xth Class
                  <span className="badge" onClick={() => this.toggleFilter('Xth')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("Xth") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("Xth")}>
              <Col xs={12}>

                <ul className="nav my-5">
                  {/*<li className="nav-item " >
                    <input type="text" className="w-100 r-inputfield" placeholder="Xth Board" />
                  </li>*/}

                  <li className="nav-item my-2">
                    <label htmlFor="xScoreeGreator" className="mr-2">

                      <input type="radio" name="x" id="xScoreeGreator" value="xScoreeGreator" onChange={()=>this.handleComparator("gte" , "academic_detail" , "X") }  /> Greater than
                                  </label>

                    <label htmlFor="xScoreeLess">

                      <input type="radio" name="x" id="xScoreeLess" value="xScoreeLess" onChange={()=>this.handleComparator("lte" , "academic_detail" , "X") } /> Less than
                                  </label>
                  </li>
                  <li>
                    <input type="text" className="w-100 r-inputfield" placeholder="Xth Score" id="X" name="academic_type"  onChange={this.handleRangeFilter}  value={comparatorObject["X"]=="gte"? gteComparatorObject["X"] : lteComparatorObject["X"]} />
                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>

                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              XIIth Class
                  <span className="badge" onClick={() => this.toggleFilter('XIIth')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("XIIth") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("XIIth")}>
              <Col xs={12}>

                <ul className="nav my-5">
                  {/*<li className="nav-item " >
                    <input type="text" className="w-100 r-inputfield" placeholder="XIIth Board" />
                  </li>*/}

                  <li className="nav-item my-2">
                    <label htmlFor="XIIScoreeGreator" className="mr-2">

                      <input type="radio" name="XII" id="XIIScoreeGreator" value="XIIScoreeGreator" onChange={()=>this.handleComparator("gte" , "academic_detail" , "XII")} /> Greater than
                                  </label>

                    <label htmlFor="XIIScoreeLess">

                      <input type="radio" name="XII" id="XIIScoreeLess" value="XIIScoreeLess" onChange={()=>this.handleComparator("lte" , "academic_detail" , "XII")} /> Less than
                                  </label>
                  </li>
                  <li>
                    <input type="text" className="w-100 r-inputfield" placeholder="XIIth Score" name="academic_type" id="XII" onChange={this.handleRangeFilter}  value={comparatorObject["XII"]=="gte"? gteComparatorObject["XII"] : lteComparatorObject["XII"]} />

                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>

                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Graduation
                  <span className="badge" onClick={() => this.toggleFilter('graduation')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("graduation") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("graduation")}>
              <Col xs={12}>

                <ul className="nav my-5">
                  <li className="nav-item my-2">
                    <label htmlFor="graduationScoreGreator" className="mr-2">

                      <input type="radio" name="graduationScore" id="graduationScoreGreator" value="XIIScoreeGreator" onChange={()=>this.handleComparator("gte" , "academic_detail" , "GRADUATION")} /> Greater than
                                  </label>

                    <label htmlFor="graduationScoreLess">
                      <input type="radio" name="graduationScore" id="graduationScoreLess" value="XIIScoreeLess" onChange={()=>this.handleComparator("lte" , "academic_detail" , "GRADUATION")} /> Less than
                                  </label>
                  </li>
                  <li>
                    <input type="text" className="w-100 r-inputfield" name="academic_type" id="GRADUATION"   placeholder="Score" onChange={this.handleRangeFilter} value={comparatorObject["GRADUATION"]=="gte"? gteComparatorObject["GRADUATION"] : lteComparatorObject["GRADUATION"]} />
                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>
                </ul>
              </Col>
            </Collapse>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Work Experience
                  <span className="badge" onClick={() => this.toggleFilter('workExperience')}>
                <a href="#" className=" r-text--grey-4"><i className={` ${isOpen.includes("workExperience") ? "minus-bar" : "fi flaticon-plus"}`}></i></a>
              </span>
            </li>
            <Collapse isOpen={isOpen.includes("workExperience")}>
              <Col xs={12}>

                <ul className="nav my-5">


                  <li className="nav-item mt-2">
                    <label htmlFor="workExperienceGreator" className="mr-2">

                      <input type="radio" name="total_experience" id="workExperienceGreator"  onChange={()=>this.setState({key : "gte" , sectionType : "total_experience"})} value="workExperienceGreator" /> Greater than
                                  </label>

                    <label htmlFor="graduationScoreLess">
                      <input type="radio" name="total_experience" id="workExperienceLess" onChange={()=>this.setState({key : "lte" , sectionType : "total_experience"})} value="workExperienceLess" /> Less than
                                  </label>

                  </li>
                  <li>
                    <input type="text" className="w-100 r-inputfield" value={Object.keys(subObject).length>0 && subObject["total_experience"] && subObject["total_experience"][key] ?  subObject["total_experience"][key] : ''}  id="rangeFilter"   onChange={this.handleFilter} name="total_experience" placeholder="Enter Work Experience In Months" />
                  </li>
                  <li className="nav-item my-2">
                    {section === "scoring" && <InputField type="text" placeholder="Weightage" />}
                  </li>
                </ul>
              </Col>
            </Collapse>
          </ul>
          <ul className="list-group list-group-flush p-3">
          {(filters.simpleFilter && Object.keys(filters.simpleFilter).length>0) || (filters.rangeFilter && Object.keys(filters.rangeFilter).length>0) || (filters.sectionTypeFilter && filters.sectionTypeFilter.academic_detail.length>0) ?<Button color="dark" onClick={()=>this.filterSmarscoringstudents()} >Apply </Button>:null}
          </ul>
          {(filters.simpleFilter && Object.keys(filters.simpleFilter).length>0) || (filters.rangeFilter && Object.keys(filters.rangeFilter).length>0) || (filters.sectionTypeFilter && filters.sectionTypeFilter.academic_detail.length>0) ? <ul className="list-group list-group-flush p-3">
          <Button color="light" className="border" onClick={()=>this.clearSmartScoringFilters()} > Clear </Button>
          </ul> : null}
        </div>
      </Aux>
    )
  }
}
