import axios from "axios";
import { ENDPOINTS } from "../constants/endpoints";
let Axios = axios.create();

const config = {
  withCredentials: true
}
export const Auth = {
  Axios: axios,
  User: {
    login: (data) => Axios.post(ENDPOINTS.USER.LOGIN, data, config),
    getUser: () => Axios.get(ENDPOINTS.USER.GET_USER, config),
    getCampusUser: () => Axios.get(ENDPOINTS.USER.GET_CAMPUS_USER, config),
    logout: () => Axios.post(ENDPOINTS.USER.LOGOUT, {}, config),
    createRecruiter: (data) => Axios.post(ENDPOINTS.USER.CREATE_RECRUITER, data, config),
    signUp: (data) => Axios.post(ENDPOINTS.USER.SIGNUP, data, config),
    forgotPassword: (params) => Axios.post(ENDPOINTS.USER.FORGOTPASSWORD, params, config),
    retrievePassword: (params) => Axios.post(ENDPOINTS.USER.RETRIEVEPASSWORD, params, config),
    generateEmailToken: (params) => Axios.post(ENDPOINTS.USER.EMAIL_TOKEN_GENERATE, params, config),
    verifyEmailToken: (params) => Axios.post(ENDPOINTS.USER.EMAIL_TOKEN_VERIFY, params, config),
    updateProfile: (data) => Axios.post(ENDPOINTS.USER.UPDATE_USER, { ...data }, config),
    getS3UploadURL: (params) => Axios.put(ENDPOINTS.USER.GET_FILE_URL, {}, { ...config, params }, config),
    uploadFileToS3: (signed_url, file) => Axios.put(signed_url, file, {}),
    getFileFromS3: (signed_url) => Axios.get(signed_url, { responseType: "blob" }),
    addFileToManager: (fileData) => Axios.post(ENDPOINTS.USER.ADD_TO_FILE_MANAGER, fileData, config),
    deleteFile: (id) => Axios.post(ENDPOINTS.USER.UPDATE_FILE, { id, status: 0 }, config),
    getFilesbyId: (file_id) => Axios.get(ENDPOINTS.USER.GET_FILES, Object.assign({ params: { file_id } }, config)),
    getFilesList: (fileIds) => {
      let params = new URLSearchParams();
      fileIds.forEach(file_id => {
        if (file_id)
          params.append("file_id", file_id)
      })
      return Axios.get(ENDPOINTS.USER.GET_FILES, Object.assign({ params }, config))
    },
    getFileUrl: (file_url) => Axios.get(ENDPOINTS.USER.GET_FILE_URL, Object.assign({ params: { file_url } }, config)),
    getFileUrlList: (files) => {
      let params = new URLSearchParams();
      files.forEach(({ file_url }) => {
        params.append("file_url", file_url)
      })
      return Axios.get(ENDPOINTS.USER.GET_FILE_URL, Object.assign({ params }, config))
    },
    createUserRole : params =>Axios.post(ENDPOINTS.USER.CREATE_USER_ROLE , params , config),
    inactiveUserRole : params =>Axios.post(ENDPOINTS.USER.INACTIVE_USER_ROLE , params , config),
    getUserRole : params => Axios.get(ENDPOINTS.USER.GET_USER_ROLE , {params, ...config})
  },
  General: {
    getDesignation: params => Axios.get(ENDPOINTS.GENERAL.GET_DESIGNATION(params), config),
    getSectors: params => Axios.get(ENDPOINTS.GENERAL.GET_SECTOR(), config),
    getCompanies: (query) => {
      let params = query ? `?company_name=${query}` : ""
      return Axios.get(ENDPOINTS.GENERAL.GET_COMPANIES + params, config)
    },
    addCompany: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_COMPANY, data, config),
    addCompanyProfile: (data) => Axios.post(ENDPOINTS.GENERAL.ADD_COMPANY_PROFILE, data, config),
    getCompanyProfile: params => Axios.get(ENDPOINTS.GENERAL.GET_COMPANY_PROFILE, {params, ...config}),
    getAllCampus : params => Axios.get(ENDPOINTS.GENERAL.GET_CAMPUS , {params , ...config}),
    getAllCampusWithUniv : params => Axios.get(ENDPOINTS.GENERAL.GET_CAMPUS_WITH_UNIV , {params , ...config}),
    getAllPermission : params => Axios.get(ENDPOINTS.GENERAL.GET_PERMISSION , {params , ...config}),
    getAdjecentCampus :  params => Axios.get(ENDPOINTS.GENERAL.GET_ADJECENT_CAMPUS , {params , ...config}),
    getCampusBatches :  params => Axios.get(ENDPOINTS.GENERAL.FETCH_CAMPUS_BATCHES , {params , ...config}),
    getCampusDegreeDepartment :  params => Axios.get(ENDPOINTS.GENERAL.FETCH_CAMPUS_DEGREE_DEPARTMENT , {params , ...config}),
  },
  Recruiter: {
    getDesignations: data => Axios.post(ENDPOINTS.RECRUITER.GET_DESIGNATION, data, config),
    getApplicants: (params) => Axios.get(ENDPOINTS.RECRUITER.GET_APPLICANTS(params), config),
    getColleges: (data) => Axios.post(ENDPOINTS.RECRUITER.GET_COLLEGES, data, config),
    createPost: (data) => Axios.post(ENDPOINTS.RECRUITER.CREATE_POST, data, config),
    getPost: (params) => Axios.get(ENDPOINTS.RECRUITER.GET_POST, Object.assign({ params }, config)),
    getSpecificApplicants : campus_job_id => Axios.post(ENDPOINTS.RECRUITER.GET_SPECIFIC_APPLICANTS, {campus_job_id} , config),
    assignAccess:  (data) => Axios.post(ENDPOINTS.RECRUITER.ASSIGN_ACCESS, data, config),
    fetchAccess:  (params) => Axios.get(ENDPOINTS.RECRUITER.GET_ACCESS, {params, ...config}),
    inactiveAccess:  (params) => Axios.post(ENDPOINTS.RECRUITER.INACTIVE_ACCESS, params, config),
    alterPermission:  (params) => Axios.post(ENDPOINTS.RECRUITER.ALTER_PERMISSION, params, config),
    getPermission:  (params) => Axios.get(ENDPOINTS.RECRUITER.GET_PERMISSION, {params, ...config}),
  },
  Project: {
    createProject: (data) => Axios.post(ENDPOINTS.PROJECT.CREATE, data, config),
    addCampus: (data) => Axios.post(ENDPOINTS.PROJECT.ADD_CAMPUS, data, config),
    getProjects: (params) => Axios.get(ENDPOINTS.PROJECT.GET_PROJECT, { ...config, params }),
    getCampus: (params) => Axios.get(ENDPOINTS.PROJECT.GET_CAMPUS, { ...config, params })
  },
  Process: {
    getProcess: (params) => Axios.get(ENDPOINTS.PROCESS.GET_PROCESS, { ...config, params }),
    create: (data) => Axios.post(ENDPOINTS.PROCESS.CREATE, data, config),
    addCampus: (data) => Axios.post(ENDPOINTS.PROCESS.ADD_CAMPUS, data, config),
    getCampusProcess: (params) => Axios.get(ENDPOINTS.PROCESS.GET_CAMPUS_PROCESS, { ...config, params }),
    getCampusAllProcess: (params) => Axios.get(ENDPOINTS.PROCESS.GET_CAMPUS_ALL_PROCESS, { ...config, params }),
    deleteCampus: (data) => Axios.post(ENDPOINTS.PROCESS.DELETE_CAMPUS, data, config),
    createCampusJob: (data) => Axios.post(ENDPOINTS.PROCESS.CREATE_CAMPUS_JOB, data, config),
    getCampusJob: (params) => Axios.get(ENDPOINTS.PROCESS.GET_CAMPUS_JOB, {...config, params}),
    updateCampusJob: (data) => Axios.post(ENDPOINTS.PROCESS.UPDATE_CAMPUS_JOB, data, config),
    deleteCampusJob: (data) => Axios.post(ENDPOINTS.PROCESS.DELETE_CAMPUS_JOB, data, config),
    getApplicants: (params) => Axios.post(ENDPOINTS.PROCESS.GET_APPLICANTS, params, config ),
    getCampusProcessStudents: (params) => Axios.get(ENDPOINTS.PROCESS.GET_CAMPUS_PROCESS_STUDENTS, {params, ...config} ),
    addJobInviteReference: (data) => Axios.post(ENDPOINTS.PROCESS.ADD_JOB_INVITE_REFERENCE, data, config),

  },
  JobTemplate: {
    fetch: (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.FETCH, Object.assign({ params }, config)),
    create: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.CREATE, data, config),
    update: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.UPDATE, data, config),
    inactive: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.INACTIVE, data, config),
    fetchDocs: (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.DOCS_FETCH, Object.assign({ params }, config)),
    createDocs: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.DOCS_CREATE, data, config),
    updateDocs: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.DOCS_UPDATE, data, config),
    inactiveDocs: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.DOCS_INACTIVE, data, config),
    fetchRounds: (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.ROUNDS_FETCH, Object.assign({ params }, config)),
    createRounds: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ROUNDS_CREATE, data, config),
    updateRounds: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ROUNDS_UPDATE, data, config),
    inactiveRounds: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ROUNDS_INACTIVE, data, config),
    fetchEligibility: (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.ELIGIBILITY_FETCH, Object.assign({ params }, config)),
    createEligibility: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ELIGIBILITY_CREATE, data, config),
    updateEligibility: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ELIGIBILITY_UPDATE, data, config),
    inactiveEligibility: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.ELIGIBILITY_INACTIVE, data, config),
    fetchQuestion: (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.QUESTION_FETCH, Object.assign({ params }, config)),
    createQuestion: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.QUESTION_CREATE, data, config),
    updateQuestion: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.QUESTION_UPDATE, data, config),
    inactiveQuestion: (data) => Axios.post(ENDPOINTS.JOB_TEMPLATE.QUESTION_INACTIVE, data, config),
    fetchStatistics:  (params) => Axios.get(ENDPOINTS.JOB_TEMPLATE.FETCH_STATISTICS, Object.assign({ params }, config)),
  },
  Connection: {
    request: (data) => Axios.post(ENDPOINTS.CONNECTION.REQUEST, data, config),
    inactive: (data) => Axios.post(ENDPOINTS.CONNECTION.INACTIVE, data, config),
  },

  SmartScoring : {
    getSmartScoring :  params =>Axios.post(ENDPOINTS.SMART_SCORING.GET_SMART_SCORING,params , config)
  },
Evaluation : {
  assignJobToEvaluator : params =>Axios.post(ENDPOINTS.EVALUATION.ASSIGN_JOB_TO_EVALUATOR, params, config),
  getEvaluatorAccessDetails : params=>Axios.get(ENDPOINTS.EVALUATION.GET_EVALUATOR_ACCESS_DETAILS , {params , ...config}),
  removeJobFromEvaluator : params=>Axios.post(ENDPOINTS.EVALUATION.REMOVE_EVALUATOR_ACCESS , params , config),
  assignApplicantsToEvaluator : params=> Axios.post(ENDPOINTS.EVALUATION.ASSIGN_APPLICANTS_TO_EVALUATOR , params,config),
  removeApplicantsFromEvaluator : params =>Axios.post(ENDPOINTS.EVALUATION.REMOVE_APPLICANTS_FROM_EVALUATOR , params , config),
  fetchEvaluatorApplicants : params =>Axios.get(ENDPOINTS.EVALUATION.FETCH_EVALUATOR_APPLICANTS , {params , ...config}),
  fetchCv : params => Axios.get(ENDPOINTS.EVALUATION.FETCH_CV , {params , ...config, responseType: "blob" }),
  commentUpdate : params =>Axios.post(ENDPOINTS.EVALUATION.COMMENT_UPDATE , params , config),
  commentFetch : params => Axios.get(ENDPOINTS.EVALUATION.COMMENT_UPDATE_FETCH , {params , ...config}),

},
  Evaluate: {
    getJobs: params => Axios.get(ENDPOINTS.EVALUATE.GET_JOBS, { ...config, params })
  },

  Download : {
    cv : params => Axios.post(ENDPOINTS.DOWNLOAD.CV , params , config),
    answers : params => Axios.post(ENDPOINTS.DOWNLOAD.ANSWERS , params , config),
    studentData : params => Axios.post(ENDPOINTS.DOWNLOAD.STUDENT_DATA , params , config)
  },
  campusProfile : {
    fetch : params =>Axios.get(ENDPOINTS.CAMPUS_PROFILE.FETCH , {params , ...config})
  },
  campusConversation : {
    fetch : params =>Axios.get(ENDPOINTS.CAMPUS_CONVERSATION.FETCH , {params , ...config}),
    send : params =>Axios.post(ENDPOINTS.CAMPUS_CONVERSATION.SEND , params, config),
    msgCount: (params) => Axios.get(ENDPOINTS.CAMPUS_CONVERSATION.MSG_COUNT, {...config, params}),
  },
  post : {
    sendPost: (data) => Axios.post(ENDPOINTS.POST.SEND_POST, data, config),
    viewPost: (data) => Axios.post(ENDPOINTS.POST.VIEW_POST, data, config),
    inactivePost: (data) => Axios.post(ENDPOINTS.POST.INACTIVE_POST, data, config),
  },
  report : {
    viewReport: (params) => Axios.get(ENDPOINTS.REPORT.VIEW_REPORT, {params, ...config}),
  }
}
