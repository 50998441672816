import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import NavLink from 'react-router-dom/NavLink';
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import Login from '../Login';
import Link from 'react-router-dom/Link';
import Aux from '../AuxComp';
import { RecruiterModal } from '../Login/recruiter';
import { getFullName, getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setUser, setLoading, setRole } from '../../../actions/action-creators';
import { CONSTANTS } from '../../../constants';
import Home from '../../views/home';
export default class Header extends Component {
  state = {
    showLogin: false,
    recruiterModal: false,
    userMenu: false
  }

  toggleLogin = e => {
    let { showLogin } = this.state
    this.setState({ showLogin: !showLogin })
  }

  createRecruiter = e => {
    let { user } = this.props;
    if (!user.recruiter_id) {
      this.toggleRecruiterModal()
    }
  }

  toggleRecruiterModal = () => {
    let { recruiterModal } = this.state
    this.setState({ recruiterModal: !recruiterModal })
  }

  toggleMenu = () => {
    let { userMenu } = this.state
    this.setState({ userMenu: !userMenu })
  }

  logout = async () => {
    store.dispatch(setLoading(true))
    try {
      await Auth.User.logout();
      store.dispatch(setUser(null))
      store.dispatch(setRole(null))
      this.setState({userMenu: false})
    } catch (e) {
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))
  }

  setRole = async (role) => {
    store.dispatch(setLoading(true))
    await store.dispatch(setRole(role));
    store.dispatch(setLoading(false))
    this.toggleMenu();
  }

  render() {
    let { user, role } = this.props,
      { showLogin, recruiterModal, userMenu } = this.state;
    let  permissionList = role.permission ? role.permission.map(o => o.permission_type) : null
    
    return (
      <div className="r-nav sticky-top">
        <Container>
          <Row className="justify-content-between">
            <Col xs={2}>
              <Link className="nav-link active d-inline-block" to="/">
                <img src="/images/reculta_logo.png" className="r-nav__logo" alt="Reculta" />
              </Link>
              {/* role.role_id == 1 || role.role_id == 2 ?
                <Link className="nav-link active d-inline-block ml-3 mt-1" to="/search">
                    <input disabled className="r-bg--grey-2 border p-1 border-light rounded selectable" type="text" name="first_name" placeholder="Search" />
                    <i className="r-text--grey-4 fi r-input_field_icon flaticon-search"></i>
                </Link> : null
              */}
            </Col>
            <Col xs="auto">
              <ul className="nav justify-content-center align-items-center">
                {(user && user.recruiter_id && role && role.role_id) ?
                  <Aux>
                    {CONSTANTS.NAV[role.role_id] && CONSTANTS.NAV[role.role_id].map((menu, i) => {
                      return (
                        permissionList && permissionList.indexOf(menu.permission_type) === -1 ? null : <li key={i}><NavLink to={menu.link} className="r-nav__link r-subheading-3">{menu.name} </NavLink></li>
                      )
                    })}
                    {role.role_id === 1 && <li className="mx-3"><NavLink className="r-nav__link r-subheading-3" to="/settings"><i className="fi flaticon-gear"></i></NavLink></li>}
                  </Aux>
                  : null
                }
                <li className="border-left nav-item">
                  {user ? (user.recruiter_id ? <ButtonDropdown isOpen={userMenu} toggle={this.toggleMenu}>
                    <DropdownToggle className="r-nav__dropdown_btn">
                      <p className="d-inline-block r-heading-6 my-0 px-1">{user.first_name}</p>
                      <i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>
                    </DropdownToggle>
                    <DropdownMenu className="r-nav__dropdown p-0">
                      <Row className="px-3 pt-3 justify-content-center align-self-center">
                        <Col xs={3}><img height="50" width="50" className="rounded-circle border" alt="" /></Col>
                        <Col xs={9} className="justify-content-center align-self-center">
                          <h1 className="r-subheading-3">{getFullName(user)}</h1>
                        </Col>
                      </Row>
                      <Row className="bg-dark r-text--light p-3 text-center w-100 m-0 mt-2" >
                        {user && (role.role_id ===1)&& <Col className="r-pointer"><Link className="text-white text-decoration-none" to={"/profile"} > <i className="d-inline-block fi flaticon-portfolio mr-2 "></i> Home </Link></Col>}
                        <Col className="r-pointer" onClick={this.logout} ><i className="d-inline-block fi flaticon-upload rotate--90 mr-2"></i> Logout</Col>
                      </Row>
                      {
                        user.roles && user.roles.map(r => {
                          return (
                            <Row key={r.user_role_id} className={"p-3 border-bottom  w-100 m-0 r-pointer " + (role.user_role_id == r.user_role_id ? "r-nav__active" : "")} onClick={() => this.setRole(r)}>
                              <Col><h1 className="r-subheading-4 my-1 r-text--grey-4">{CONSTANTS.ROLE_TYPE[r.role_id]}</h1></Col>
                            </Row>
                          )
                        })
                      }
                      <Row className="p-3 r-bg--grey-2 w-100 m-0 text-center justify-content-center align-self-center">
                        <Col className="border-right"><h1 className="r-subheading-4 m-0 r-text--grey-4">Help</h1></Col>
                        <Col><h1 className="r-subheading-4 m-0  r-text--grey-4"><a href="https://reculta.com/privacy.pdf" className="r-text--grey-4" target="_blank" rel="noopener noreferrer">Privacy</a></h1></Col>
                      </Row>

                    </DropdownMenu>
                  </ButtonDropdown> : <a id="create-recruiter" onClick={this.createRecruiter} className="r-nav__link" href="#">{user.first_name}</a>) : null}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
