import React from 'react';
import { Row,Col,Container,UncontrolledCollapse,CardBody, Card,Collapse,Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import Button from '../../global/Button';
import { Auth } from "../../../actions/Auth";
import { HorizontalLine } from '../../global/horizontal_line';
import { CONSTANTS } from '../../../constants';
import { InputField } from '../../global/Forms';
import { RTE } from '../../global/Forms/RTE';
import store from "../../../store";
import { setLoading, setError, setSuccess } from "../../../actions/action-creators";
import { RichTextViewer } from '../../global/Forms/RichTextViewer';

class ProfilePage extends React.Component {
   constructor(props){
    super(props);
    this.state = {
            isEditDesc : "",
            isEditHead: "",
            isEditLink : "",
            isEditYear: "",
            description : props.companyProfile.description,
            headquarters: props.companyProfile.headquarter,
            estYear : props.companyProfile.establish_year,
            linkdinurl: props.companyProfile.linkedin,
    };
     
  }

  componentWillReceiveProps(nextProps) {
         this.setState({description : nextProps.companyProfile.description})
         this.setState({headquarters: nextProps.companyProfile.headquarter})
         this.setState({estYear : nextProps.companyProfile.establish_year})
         this.setState({linkdinurl: nextProps.companyProfile.linkedin})
   
  }
 async componentDidMount(){
  }



  onsubmit = async (e) =>{
    e.preventDefault();
    store.dispatch(setLoading(true));
    let body = {
      description:this.state.description,
      headquarter:this.state.headquarters,
      establish_year:this.state.estYear,
      linkedin:this.state.linkdinurl,

    }
    try{
      await Auth.General.addCompanyProfile(body);
      this.setState({
            isEditDesc :false,
            isEditHead: false,
            isEditLink : false,
            isEditYear: false})
      
    }
    catch(err){
      console.log(err.response);
  }
  store.dispatch(setLoading(false));
}


onChange =(e) =>{
    this.setState({[e.target.name]:e.target.value})
}




  render() {
    const {isEditDesc,isEditHead,isEditLink, isEditYear,} = this.state;
    let {isPTView} = this.props;
   
    return (
        <Container>
        {!isPTView &&
            <Row>
                <Col className="text-left p-0">
                    <span className="r-heading-4 ml-4">Create your public profile</span>
                    <HorizontalLine className="mt-1" color="#cfcfcf" />
                </Col>
            </Row>} 
           <form onSubmit={this.onsubmit}>
           <Row className="">
               <Col>
                <div className="form-group ">
                    <h1 className="r-subheading-3 mt-4 pb-3" style={{fontSize: 17}} htmlFor="about">Description{!isPTView && <Button type="button"  className="fi r-text--sm flaticon-edit-2" onClick= {() => { this.setState({isEditDesc : true}) }}></Button>}</h1>
                     {
                        isEditDesc && !isPTView ? 
                        <RTE onChange={(value) => {this.setState({description : value})}} value={this.state.description || ""} rows="3" placeholder="Description here..."  />

                        :
                        <RichTextViewer>{this.state.description || "No Description to show."}</RichTextViewer>
                        
                     }  
                </div>
               </Col>
           </Row>

           { isEditDesc && !isPTView &&
            <Row className="mt-2">
                <Col className=" text-right">
                    <Button  type="button" onClick= {() => { this.setState({isEditDesc : false}) }} color="light-dark">Cancel</Button>
                    <Button  type="submit" color="dark">Save</Button>
                </Col>
            </Row>
            }
           </form>
        
            <Row>
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row> 
           <form onSubmit={this.onsubmit}>
           <Row className="">
               <Col>
                <div className="form-group ">
                    <h1 className="r-subheading-3 mt-4 pb-3" style={{fontSize: 17}} htmlFor="about">Headquarters{!isPTView && <Button type="button"  className="fi r-text--sm flaticon-edit-2" onClick= {() => { this.setState({isEditHead : true}) }}></Button>}</h1>
                     {
                        isEditHead && !isPTView ? 
                  <textarea className="r-inputfield w-100" rows={4} name="headquarters" onChange={this.onChange} defaultValue={this.state.headquarters} />

                        :
                        <RichTextViewer>{this.state.headquarters || "No Headquarters to show."}</RichTextViewer>
                        
                     }  
                </div>
               </Col>
           </Row>

           { isEditHead && !isPTView &&
            <Row className="mt-2">
                <Col className=" text-right">
                    <Button  type="button" onClick= {() => { this.setState({isEditHead : false}) }} color="light-dark">Cancel</Button>
                    <Button  type="submit" color="dark">Save</Button>
                </Col>
            </Row>
            }
           </form>
        
            <Row>
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row>
           <form onSubmit={this.onsubmit}>
           <Row className="">
               <Col>
                <div className="form-group ">
                    <h1 className="r-subheading-3 mt-4 pb-3" style={{fontSize: 17}} htmlFor="about">LinkedIn Page URL{!isPTView && <Button type="button"  className="fi r-text--sm flaticon-edit-2" onClick= {() => { this.setState({isEditLink : true}) }}></Button>}</h1>
                     {
                        isEditLink && !isPTView ? 
                          <InputField className="w-100" name="linkdinurl" type="text" onChange={this.onChange} defaultValue={this.state.linkdinurl || ""} />

                        :
                        <RichTextViewer>{this.state.linkdinurl || "LinkedIn url"}</RichTextViewer>
                        
                     }  
                </div>
               </Col>
           </Row>

           { isEditLink && !isPTView &&
            <Row className="mt-2">
                <Col className=" text-right">
                    <Button  type="button" onClick= {() => { this.setState({isEditLink : false}) }} color="light-dark">Cancel</Button>
                    <Button  type="submit" color="dark">Save</Button>
                </Col>
            </Row>
            }
           </form>
        
            <Row>
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row>
           <form onSubmit={this.onsubmit}>
           <Row className="">
               <Col>
                <div className="form-group ">
                    <h1 className="r-subheading-3 mt-4 pb-3" style={{fontSize: 17}} htmlFor="about">Established Year{!isPTView && <Button type="button"  className="fi r-text--sm flaticon-edit-2" onClick= {() => { this.setState({isEditYear : true}) }}></Button>}</h1>
                     {
                        isEditYear && !isPTView ? 
                          <InputField className="w-100" name="estYear" type="number"  min="1800" max="2022" onChange={this.onChange} defaultValue={this.state.estYear || ""} />

                        :
                        <RichTextViewer>{this.state.estYear || "Established Year"}</RichTextViewer>
                        
                     }  
                </div>
               </Col>
           </Row>

           { isEditYear && !isPTView &&
            <Row className="mt-2">
                <Col className=" text-right">
                    <Button  type="button" onClick= {() => { this.setState({isEditYear : false}) }} color="light-dark">Cancel</Button>
                    <Button  type="submit" color="dark">Save</Button>
                </Col>
            </Row>
            }
           </form>
        
            <Row>
                <Col className="p-0">
                    <HorizontalLine color="#cfcfcf" />
                </Col>
           </Row>
        </Container>
    );
  }
}

const mapStateToProps = state => ({
  user : state.user,
  role : state.role
})

export default connect(mapStateToProps, null)(ProfilePage)
