import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import AutosuggestComp from '../../global/AutosuggestComp';
import withRouter from 'react-router-dom/withRouter';
import { Auth } from '../../../actions/Auth';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import Button from '../../global/Button';
import store from '../../../store';
import { setError } from '../../../actions/action-creators';
import { Message } from '../../../constants/messages';
class Search extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: '',
      searchParams: {
        profile_name: "",
        job_type: "",
        ctc_min: "",
        ctc_max: "",
        location: ""
      }
    }
  }

  handleFieldChange = (e, { newValue, method }) => {
    let { searchParams } = this.state
    if (newValue !== undefined) {
      searchParams.profile_name = newValue
      this.setState({ searchParams })
    }
  };

  handleInputChange = (e) => {
    let { searchParams } = this.state
    searchParams[e.target.name] = e.target.value
    this.setState({ searchParams })
  }

  toggleFilter = (value) => {
    let { isOpen } = this.state
    if (isOpen == value) {
      value = false;
    }
    this.setState({ isOpen: value })
  }

  getDesignations = async (keyword) => {
    let designationResp = await Auth.Recruiter.getDesignations({
      fields: ['role_name'], search: { field: "role_name", keyword }
    })
    designationResp.data.data = designationResp.data.data.map(d=>d.role_name)
    return designationResp
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
    let { searchParams } = this.state
    searchParams.profile_name = searchParams.profile_name.trim()
    if (searchParams.profile_name === "") {
      store.dispatch(setError({ showBar: true, message: Message.MANDATORY.DESIGNATION }))
      return
    }
    let queryString = new URLSearchParams();
    Object.keys(searchParams).forEach(param => {
      queryString.append(param, searchParams[param])
    })
    this.props.history.push({
      pathname: '/search/show',
      search: `?${queryString}`,
    })
  }

  render() {
    let { isOpen, searchParams } = this.state
    return (
      <form ref="searchForm" onSubmit={this.handleSearchSubmit}>
        <Row className="">
          <Col >
            <div className="m text-left">
              <AutosuggestComp
                inputProps={
                  {
                    className: "w-100 r-inputfield",
                    onChange: this.handleFieldChange,
                    value: searchParams.profile_name,
                    name: 'profile_name',
                    placeholder: 'Type designation here and click enter'
                  }
                }
                getSuggestions={this.getDesignations}
                renderSuggestion={(designations) => <div>{designations}</div>} />
            </div>
          </Col>
          {/*<Col xs={7}>
            <Row className="no-gutters filters">
              <Col xs={4}>
                <ButtonDropdown isOpen={isOpen === "jobType"} toggle={() => this.toggleFilter("jobType")}>
                  <DropdownToggle className="search-filter">
                    Job Type
                  <i className="fi flaticon-arrow"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="location-list text-left" >
                      <ul className="nav flex-column">
                        <li className="nav-item border-bottom my-2">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="fullTime"
                              name="job_type"
                              value="finals"
                              checked={searchParams.job_type === "finals"}
                              onChange={this.handleInputChange}
                            />
                            <label className="custom-control-label" htmlFor="fullTime">Full Time </label>
                          </div>
                        </li>
                        <li className="nav-item border-bottom my-2">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="partTime"
                              name="job_type"
                              value="internship"
                              checked={searchParams.job_type === "internship"}
                              onChange={this.handleInputChange}
                            />
                            <label className="custom-control-label" htmlFor="partTime"> Part Time</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
              <Col xs={4}>
                <ButtonDropdown isOpen={isOpen === "ctc"} toggle={() => this.toggleFilter("ctc")}>
                  <DropdownToggle className="search-filter">
                    CTC
                  <i className="fi flaticon-arrow"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="location-list text-left">
                      <ul className="nav flex-column">
                        <li className="nav-item border-bottom pb-3">
                          <label>Minimum</label>
                          <input
                            type="number"
                            className="w-100 r-inputfield"
                            name="ctc_min"
                            placeholder="Minimum"
                            onChange={this.handleInputChange}
                            value={searchParams.ctc_min}
                          />
                        </li>
                        <li className="nav-item border-bottom pb-3 mb-2">
                          <label>Maximum</label>
                          <input
                            type="number"
                            className="w-100  r-inputfield"
                            name="ctc_max"
                            placeholder="Maximum"
                            value={searchParams.ctc_max}
                            onChange={this.handleInputChange}
                          />
                        </li>
                      </ul>
                    </div>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
              <Col xs={4}>
                <ButtonDropdown isOpen={isOpen === "location"} toggle={() => this.toggleFilter("location")}>
                  <DropdownToggle className="search-filter">
                    Location
                  <i className="fi flaticon-arrow"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="location-list text-left">
                      <ul className="nav flex-column">
                        <li className="nav-item border-bottom pb-3">
                          <label>Location</label>
                          <input
                            type="text"
                            className="w-100 r-radius--2 r-inputfield"
                            name="location"
                            placeholder="Location"
                            value={searchParams.location}
                            onChange={this.handleInputChange}
                          />
                          <i className="fi flaticon-search icon-right"></i>
                        </li>
                      </ul>
                    </div>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
          </Col>*/}
        </Row>
        <Button className="d-none">Search</Button>
      </form>
    )
  }
}

export default withRouter((Search));
